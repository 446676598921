import clsx from 'clsx'
import React from 'react'

const Illustration = () => {
  return (
    <div className={clsx(
      'w-full bg-primary-main absolute top-0 left-0',
      'h-[375px] lg:h-[357px] xl:h-[376px]'
    )}>
      <svg className="absolute bottom-0 right-0" width="225" height="225" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 225C2.58312e-06 195.453 5.8198 166.194 17.1271 138.896C28.4344 111.598 45.0078 86.7941 65.901 65.9009C86.7942 45.0077 111.598 28.4344 138.896 17.1271C166.195 5.81977 195.453 -1.44416e-05 225 0L225 225L0 225Z" fill="#FFDA54"/>
      </svg>
    </div>
  )
}

export default Illustration
