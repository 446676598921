import React from 'react'
import PropTypes from 'prop-types'

import MailIcon from 'assets/job-detail/consultant/mail.svg'
import PhoneIcon from 'assets/job-detail/consultant/phone.svg'

const Consultant = ({ data }) => {
  const { owners } = data

  const arrBiography = [
    {
      name: 'Amy Thomas',
      text: 'Amy is a passionate technical recruiter in the Construction and A&D sector who builds strong relationships with a broad range of stakeholders. She effectively tailors’ solutions to client needs and offers a deeper insight perspective for candidates. Amy is a passionate and knowledgeable recruiter who puts 110% into every relationship and role she recruits.'
    },
    {
      name: 'Lexie Ahern',
      text: 'Lexie is a friendly, compassionate and reliable consultant. She is very thorough with her recruitment process and always exceeds her clients and candidates expectations. Lexie is known for always achieving a win/win outcome for clients and candidates!'
    },
    {
      name: 'Megan Warry',
      text: 'Megan is fantastic at learning the strengths, weaknesses, and goals of job seekers in order to find them the perfect new career path. Megan is a responsive and trustworthy recruiter who has transitioned from a highly regarded customer service background and always has the best interests of her candidates in mind.'
    },
    {
      name: 'Blake Thompson',
      text: 'Blake, often referred to as BT, is a strategic recruitment leader and passionate about the HR industry. Blake has strong relationships across many industries and consistently thinks outside the box to achieve great outcomes for employers and job seekers.'
    },
    {
      name: 'Cowen Liong',
      text: 'Cowen is considered the backbone of the Vendito Family. Cowen’s has an extremely strong work ethic and an incredible ability to learn and solve complex problems. He’s extremely proactive and always looking out for the best interests of our clients, candidates, and the team.'
    },
    {
      name: 'Bryan Chung',
      text: 'Bryan is a compassionate consultant who always exceeds the expectations of candidates and employers. He seeks fulfilment in making the recruitment process an enjoyable experience and boasts an incredible track record inside his network.'
    },
    {
      name: 'Josh Carson',
      text: "If you're looking for an edge through your job seeking journey, Josh is the man to talk to. He provides real guidance and world class interview strategies that will almost always land you your dream job."
    }
  ]

  const { avatar_original_url: avatar, name, phone, email } = (owners !== undefined && owners[0]) || {}

  return (
    <div className="bg-neutral-10 rounded-2xl p-6 overflow-hidden">
      {name === 'Josh Carson'
        ? <div className="mb-2 sm:mb-0 scale-[1.02] sm:scale-100 xl:scale-[.99] w-[280px] h-[320px] md:w-[235px] md:h-[320px] xl:w-full xl:h-full">
          <img src={avatar} alt="" className="scale-[1.02] sm:scale-100 xl:scale-[.99] w-[280px] h-[320px] md:w-[235px] md:h-[320px] xl:w-full xl:h-full object-cover object-center bg-red-200"/>
        </div>
        : <img src={avatar} alt=""/>
      }
      <div className="py-6 border-b border-b-neutral-30">
        <h1 className="text-neutral-100 font-medium text-[22px] leading-[150%]">{name || '-'}</h1>
        <p className="mt-2 text-m-medium text-neutral-60">{
          name ? arrBiography.filter((item) => item.name === name)[0]?.text || '-' : '-'
        }</p>
      </div>

      <div className="mt-6">
        <div className="flex gap-x-3 items-start xl:items-center">
          <img src={MailIcon} alt=""/>
          <p className="text-m-medium text-neutral-100 break-all">{email || '-'}</p>
        </div>

        <div className="flex gap-x-3 items-center mt-4">
          <img src={PhoneIcon} alt=""/>
          <p className="text-m-medium text-neutral-100">{phone || '-'}</p>
        </div>
      </div>
    </div>
  )
}

Consultant.propTypes = {
  data: PropTypes.object
}

export default Consultant
