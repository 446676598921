import React, { useRef } from 'react'

// import HeroImageMobile from 'assets/start-hiring/hero/hero-image-mobile.png'
import ListItem from './ListItem'

import { TitleAnimation } from 'animation/start-hiring/hero/LeftAnimation'
import Illustration from 'assets/start-hiring/hero/Illustration'

const HeroLeft = () => {
  const titleRef = useRef()
  const illustrationRef = useRef([])

  TitleAnimation(titleRef)

  return (
    <div className="lg:pt-[180px] xl:pt-[201px] lg:w-[439px] xl:w-[429px]">
      <h1 ref={titleRef} className="relative h2-medium lg:display2-medium text-neutral-100">Hiring Without the Headache.</h1>

      <div className="mt-12 block md:hidden">
        <Illustration childRef={illustrationRef}/>
        {/* <img src={HeroImageMobile} alt=""/> */}
      </div>

      <div className="flex mt-12 lg:mt-10 text-s-medium text-neutral-100 gap-x-8 lg:gap-x-14">
        <ul className="flex flex-col gap-y-4 w-[148px] lg:w-[164px]">
          <ListItem listRef={useRef()} delayAnimation={2} text="Recruitment Experts" />
          <ListItem listRef={useRef()} delayAnimation={2.2} text="Qualified Candidates" />
          <ListItem listRef={useRef()} delayAnimation={2.4} text="Interview Quickly" />
          <ListItem listRef={useRef()} delayAnimation={2.6} text="Track Activity" />
          <ListItem listRef={useRef()} delayAnimation={2.8} text="Reduce Time to Hire" />
        </ul>

        <ul className="flex flex-col gap-y-4 w-[148px] lg:w-[194px]">
          <ListItem listRef={useRef()} delayAnimation={2.1} text="Increase Staff Longevity" />
          <ListItem listRef={useRef()} delayAnimation={2.3} text="Boost Company Culture" />
          <ListItem listRef={useRef()} delayAnimation={2.5} text="Streamline Hiring Process" />
          <ListItem listRef={useRef()} delayAnimation={2.7} text="Be Represented by Industry Leaders" />
        </ul>
      </div>
    </div>
  )
}

export default HeroLeft
