import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const TitleAnimation = (textRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(textRef.current,
      { top: '25px', opacity: 0 },
      { top: '0px', opacity: 1, delay: 1.25, duration: 1.25, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

const ListAnimation = (listRef, delayAnimation) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(listRef.current,
      { top: '25px', opacity: 0 },
      { top: '0px', opacity: 1, delay: delayAnimation, duration: 1.25, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

export { TitleAnimation, ListAnimation }
