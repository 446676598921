import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { ListAnimation } from 'animation/start-hiring/form/StepAnimation'

const ListStep = ({ listRef, delayAnimation, text, logo: Logo, bg }) => {
  // const refTest = useRef()
  ListAnimation(listRef, delayAnimation)

  return (
    <li ref={listRef} className={clsx(
      'relative',
      'flex items-center gap-x-3 p-2 rounded-full',
      bg
    )}>
      <Logo/>
      {text}
    </li>
  )
}

ListStep.propTypes = {
  listRef: PropTypes.node,
  text: PropTypes.string,
  logo: PropTypes.node,
  bg: PropTypes.string,
  delayAnimation: PropTypes.number
}

export default ListStep
