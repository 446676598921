import React from 'react'
import PropTypes from 'prop-types'

const SubmitBrief = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#FFAD42" />
      <path opacity="0.4" d="M21.96 22.6667H9.95996C9.68663 22.6667 9.45996 22.4401 9.45996 22.1667C9.45996 21.8934 9.68663 21.6667 9.95996 21.6667H21.96C22.2333 21.6667 22.46 21.8934 22.46 22.1667C22.46 22.4401 22.2333 22.6667 21.96 22.6667Z" fill="white" />
      <path opacity="0.4" d="M20.6394 10.3202C19.346 9.02685 18.0794 8.99352 16.7527 10.3202L15.946 11.1269C15.8794 11.1935 15.8527 11.3002 15.8794 11.3935C16.386 13.1602 17.7994 14.5735 19.566 15.0802C19.5927 15.0869 19.6194 15.0935 19.646 15.0935C19.7194 15.0935 19.786 15.0669 19.8394 15.0135L20.6394 14.2069C21.2994 13.5535 21.6194 12.9202 21.6194 12.2802C21.626 11.6202 21.306 10.9802 20.6394 10.3202Z" fill="white" />
      <path d="M18.3675 15.6865C18.1742 15.5932 17.9875 15.4999 17.8075 15.3932C17.6608 15.3065 17.5208 15.2132 17.3808 15.1132C17.2675 15.0399 17.1342 14.9332 17.0075 14.8265C16.9942 14.8199 16.9475 14.7799 16.8942 14.7265C16.6742 14.5399 16.4275 14.2999 16.2075 14.0332C16.1875 14.0199 16.1542 13.9732 16.1075 13.9132C16.0408 13.8332 15.9275 13.6999 15.8275 13.5465C15.7475 13.4465 15.6542 13.2999 15.5675 13.1532C15.4608 12.9732 15.3675 12.7932 15.2742 12.6065C15.1808 12.4065 15.1075 12.2132 15.0408 12.0332L10.8542 16.2199C10.7675 16.3065 10.6875 16.4732 10.6675 16.5865L10.3075 19.1399C10.2408 19.5932 10.3675 20.0199 10.6475 20.3065C10.8875 20.5399 11.2208 20.6665 11.5808 20.6665C11.6608 20.6665 11.7408 20.6599 11.8208 20.6465L14.3808 20.2865C14.5008 20.2665 14.6675 20.1865 14.7475 20.0999L18.9342 15.9132C18.7475 15.8465 18.5675 15.7732 18.3675 15.6865Z" fill="white" />
    </svg>
  )
}

SubmitBrief.propTypes = {
  className: PropTypes.string
}

export default SubmitBrief
