import TitleAnimation from 'animation/about-us/JoinTeam/TitleAnimation'
import clsx from 'clsx'
import React, { useRef } from 'react'

const Title = () => {
  const textRef = useRef()
  TitleAnimation(textRef)

  return (
    <div
      id="container-title-join-team"
      ref={textRef}
      className={clsx(
        'relative grid gap-x-[173px] text-neutral-100 font-medium text-[72px] leading-[120%]',
        'grid-cols-[691px_691px_691px_691px_691px_691px_691px_691px_691px]'
      // 'left-[calc(50%-2419px/2)] lg:left-[calc(50%-2419px/2+259.5px)] xl:left-[calc(50%-2419px/2+0.5px)]'
      )}
    >
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
      <h1 className="title-join-team">Join the Dream Team</h1>
    </div>
  )
}

export default Title
