import clsx from 'clsx'
import React from 'react'

import HeroLeft from './HeroLeft'
import HeroRight from './HeroRight'

const Hero = () => {
  return (
    <section id="hero" className={clsx(
      'flex',
      'flex-col md:flex-row',
      'px-0 lg:pl-14 xl:pl-[120px]',
      'lg:max-w-[1020px] xl:max-w-[1920px]',
      'm-auto left-0 right-0'
    )}>
      <HeroLeft/>

      <HeroRight/>
    </section>
  )
}

export default Hero
