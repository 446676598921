import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import ElevateIconAnimation from 'animation/start-hiring/advantage/ElevateIconAnimation'

const ElevateBusiness = ({ linkIsHover, setLinkIsHover }) => {
  const isHover = linkIsHover === 'elevate'

  return (
    <Link
      to="/start-hiring"
      onMouseOver={() => setLinkIsHover('elevate')}
      onMouseLeave={() => setLinkIsHover('')}
    >
      <div className="relative">
        {/* Box Shadow */}
        <div className={clsx(
          'absolute  bg-neutral-100 bg-opacity-[0.26] blur-[80px] top-6 left-4',
          'transition-all duration-700',
          isHover ? 'w-[236px] h-[272px]' : 'w-[0px] h-[0px]'
        )}></div>

        {/* Background */}
        <div
          className={clsx(
            'absolute top-0 left-0 w-full h-full bg-neutral-10 overflow-hidden'
          )}
        >
          <div className={clsx(
            'relative bg-primary-main w-[200%] h-[200%]',
            'transform origin-center duration-[350ms] rounded-[50%] left-[-50%]',
            'transition-all ease-out',
            isHover ? 'scale-100 translate-y-[-7.5%]' : 'scale-0 translate-y-0'
          )}>
          </div>
        </div>

        {/* Content */}
        <div className={clsx(
          'relative flex flex-col justify-between py-6 px-6 min-h-[280px]',
          'transform duration-500',
          isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
        )}>
          <div>
            <ElevateIcon/>
            <h1 className={clsx(
              'mt-6 h5-medium',
              isHover ? 'text-neutral-10' : 'text-neutral-100'
            )}>Elevate your business</h1>
            <p className={clsx(
              'mt-1 text-s-reguler text-neutral-10',
              'transform duration-500',
              isHover ? 'opacity-[0.7]' : 'opacity-0'
            )}>Better quality staff equals high retention, higher performance, more market share and a healthier bottom line.</p>
          </div>

          <button
            className={clsx(
              'flex items-center link-medium self-end',
              isHover ? 'text-neutral-10' : 'text-neutral-90'
            )}
          >
            View More
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.30664 15.3206L15.2404 7.38682" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </Link>
  )
}

const ElevateIcon = ({ className }) => {
  const path1Ref = useRef()
  const path2Ref = useRef()

  ElevateIconAnimation(path1Ref, path2Ref)

  return (
    <div
      id="elevate-icon"
      className={clsx(
        className,
        'relative h-10 w-10 overflow-hidden'
      )}
    >
      <svg ref={path1Ref} className="absolute" width="100%" height="100%">
        <path d="M36.6529 25.3688C33.7002 25.3688 30.7764 24.7872 28.0485 23.6573C25.3205 22.5273 22.8419 20.8711 20.754 18.7833C18.6661 16.6954 17.0099 14.2167 15.88 11.4888C14.75 8.76084 14.1685 5.83705 14.1685 2.88435L36.6529 2.88436L36.6529 25.3688Z" fill="#F9B8C4"/>
      </svg>
      <svg ref={path2Ref} className="absolute" width="100%" height="100%">
        <path d="M24.4845 36.8C21.5318 36.8 18.608 36.2184 15.88 35.0884C13.1521 33.9585 10.6734 32.3023 8.58554 30.2144C6.49767 28.1265 4.84148 25.6479 3.71153 22.9199C2.58158 20.192 2 17.2682 2 14.3155L24.4845 14.3155L24.4845 36.8Z" fill="#0099FF"/>
      </svg>
    </div>
  )
}

ElevateIcon.propTypes = {
  className: PropTypes.string
}

ElevateBusiness.propTypes = {
  linkIsHover: PropTypes.bool,
  setLinkIsHover: PropTypes.func
}

export default ElevateBusiness
