import gsap from 'gsap'
import { useEffect } from 'react'

const LeftIllustrationAnimation = (ref) => {
  useEffect(() => {
    gsap.fromTo(ref.current[1],
      { scale: 0, transformOrigin: 'bottom center' },
      { scale: 1, transformOrigin: 'bottom center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[2],
      { scale: 0, transformOrigin: 'bottom left' },
      { scale: 1, transformOrigin: 'bottom left', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[3],
      { yPercent: 100 },
      { yPercent: 0, duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[4],
      { scale: 0, transformOrigin: 'right bottom' },
      { scale: 1, transformOrigin: 'right bottom', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[5],
      { scale: 0, transformOrigin: 'left bottom' },
      { scale: 1, transformOrigin: 'left bottom', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[6],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1 }
    )

    gsap.fromTo(ref.current[7],
      { opacity: 0, xPercent: -100 },
      { opacity: 1, xPercent: 0, duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[8],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1 }
    )

    gsap.fromTo(ref.current[9],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1 }
    )
    gsap.fromTo(ref.current[10],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1.5 }
    )

    gsap.fromTo(ref.current[11],
      { opacity: 0, xPercent: 50 },
      { opacity: 1, xPercent: 0, duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[12],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1 }
    )
    gsap.fromTo(ref.current[13],
      { scale: 0, transformOrigin: 'bottom center' },
      { scale: 1, transformOrigin: 'bottom center', duration: 0.75, delay: 1 }
    )

    gsap.fromTo(ref.current[14],
      { scale: 0, transformOrigin: 'bottom right' },
      { scale: 1, transformOrigin: 'bottom right', duration: 0.75, delay: 0.5 }
    )

    gsap.fromTo(ref.current[15],
      { opacity: 0, yPercent: -50 },
      { opacity: 1, yPercent: 0, duration: 0.75, delay: 0.5 }
    )

    gsap.fromTo(ref.current[16],
      { scale: 0, transformOrigin: 'bottom left' },
      { scale: 1, transformOrigin: 'bottom left', duration: 0.75, delay: 1 }
    )
    gsap.fromTo(ref.current[17],
      { scale: 0, transformOrigin: 'bottom left' },
      { scale: 1, transformOrigin: 'bottom left', duration: 0.75, delay: 1 }
    )

    gsap.fromTo(ref.current[18],
      { opacity: 0, yPercent: 50 },
      { opacity: 1, yPercent: 0, duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[19],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1 }
    )
    gsap.fromTo(ref.current[20],
      { opacity: 0, yPercent: 50 },
      { opacity: 1, yPercent: 0, duration: 0.75, delay: 0.75 }
    )

    gsap.fromTo(ref.current[21],
      { opacity: 0, rotate: -225, transformOrigin: 'center' },
      { opacity: 1, rotate: 0, transformOrigin: 'center', duration: 0.75, delay: 0.75 }
    )
  }, [])
}

export default LeftIllustrationAnimation
