import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core'

const InputResume = ({ setSelectedFile }) => {
  const [url, setUrl] = useState('')

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0])

    const files = event.target.files
    files.length > 0 && setUrl(URL.createObjectURL(files[0]))
  }

  return (
    <div>
      <label className="block text-xs-medium text-neutral-80">
        Resume
        <span className="text-danger-main">*</span>
      </label>

      <input type="file" id="selectedFile-resume" className="hidden" accept=".pdf" onChange={handleFileSelect} />

      <div className={clsx(
        'mt-2 w-full h-[240px] bg-[#F7F9FB] border border-dashed border-neutral-30 rounded-2xl',
        'flex flex-col items-center justify-center'
      )}>

        {url
          ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
              <div
                className="w-full h-full"
              >
                <Viewer fileUrl={url} />
              </div>
            </Worker>
          )
          : (
            <>
              <div>
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.3999 6.9002C6.3999 5.13289 7.83259 3.7002 9.5999 3.7002H16.9372C17.7859 3.7002 18.5998 4.03734 19.1999 4.63745L24.6626 10.1002C25.2628 10.7003 25.5999 11.5142 25.5999 12.3629V26.1002C25.5999 27.8675 24.1672 29.3002 22.3999 29.3002H9.5999C7.83259 29.3002 6.3999 27.8675 6.3999 26.1002V6.9002Z" fill="#6B7C94"/>
                </svg>
              </div>
              <p className="mt-4 h6-bold text-neutral-60">Upload Your Resume</p>
              <p className="mt-1 text-xs-reguler text-neutral-50"> Drop your file or click <span onClick={() => document.getElementById('selectedFile-resume').click()}className="text-primary-main font-bold cursor-pointer">here</span> to browse file </p>
            </>
          )
        }
      </div>
    </div>
  )
}

InputResume.propTypes = {
  setSelectedFile: PropTypes.func
}

export default InputResume
