import React from 'react'
import PropTypes from 'prop-types'

const PlanningAhead = ({ childRef, className }) => {
  return (
    <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <mask id="mask0_420_5032" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <rect width="64" height="64" fill="white"/>
      </mask> */}
      <g mask="url(#mask0_420_5032)">
        <path ref={element => { childRef.current.planning1 = element }} d="M0 0H12.2361C26.0432 0 37.2361 11.1929 37.2361 25V37.2361H25C11.1929 37.2361 0 26.0432 0 12.2361V0Z" fill="#37C99E"/>
        <path ref={element => { childRef.current.planning2 = element }} d="M62.0156 15.2764H55.0559C46.7716 15.2764 40.0559 21.9921 40.0559 30.2764V37.2361H47.0156C55.2999 37.2361 62.0156 30.5204 62.0156 22.2361V15.2764Z" fill="#37C99E"/>
        <rect ref={element => { childRef.current.planning3 = element }} x="23.7793" y="41.0552" width="26.7336" height="22.9145" rx="2" fill="#1A49BA"/>
      </g>
    </svg>
  )
}

PlanningAhead.propTypes = {
  className: PropTypes.string,
  childRef: PropTypes.node
}

export default PlanningAhead
