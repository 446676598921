import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const ImageAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        opacity: 0,
        scale: 0
      },
      {
        opacity: 1,
        scale: 1,
        duration: 1.5,
        ease: 'Power3.easeOut',
        scrollTrigger: {
          trigger: ref.current
        }
      }
    )
  }, [])
}

export default ImageAnimation
