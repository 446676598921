import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import Swiper from './Swiper'
import Sticker from './Sticker'
import { ContainerAnimation } from 'animation/home/HeroRight'

import { arrTeam } from './data'

const HeroRight = () => {
  const containerRef = useRef()

  const [activeIndex, setActiveIndex] = useState(0)

  ContainerAnimation(containerRef)

  return (
    <div
      ref={containerRef}
      className={clsx(
        'relative',
        'bg-secondary-blue-main',
        'h-[559px] lg:h-[642px] xl:h-screen',
        'w-full',
        'max-h-[1080px]',
        'md:max-w-[418px] xl:max-w-[1000px]'
      )}
    >
      <Sticker
        arrTeam={arrTeam}
        activeIndex={activeIndex}
      />

      <Swiper
        arrTeam={arrTeam}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </div>
  )
}

export default HeroRight
