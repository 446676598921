import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const CardAnimation = (cardRef) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    ScrollTrigger.batch(cardRef.current, {
      onEnter: elements => {
        gsap.from(elements, {
          autoAlpha: 0,
          y: 100,
          stagger: 0.25
        })
      }
    })
  })
}

export default CardAnimation
