import React from 'react'
import PropTypes from 'prop-types'

const RestRelaxation = ({ childRef, className }) => {
  return (
    <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <mask id="mask0_420_5001" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <rect width="64" height="64" fill="white"/>
      </mask> */}
      <g mask="url(#mask0_420_5001)">
        <path ref={element => { childRef.current.rest1 = element }} d="M18.8272 9.18411C17.2658 6.47962 13.7654 5.51876 11.3775 7.53133C7.48269 10.814 4.40593 14.998 2.43586 19.7541C-0.259879 26.2622 -0.732824 33.4779 1.09037 40.2822C2.91357 47.0865 6.93102 53.099 12.5196 57.3873C18.1082 61.6756 24.9557 64 32 64C39.0443 64 45.8917 61.6756 51.4804 57.3873C57.069 53.099 61.0864 47.0865 62.9096 40.2822C64.7328 33.4779 64.2599 26.2622 61.5641 19.7541C59.5941 14.998 56.5173 10.814 52.6225 7.53134C50.2346 5.51877 46.7342 6.47962 45.1728 9.18412C43.6113 11.8886 44.6152 15.3048 46.7979 17.5383C48.6292 19.4122 50.1002 21.6294 51.116 24.0819C52.8591 28.29 53.1649 32.9556 51.986 37.3552C50.8071 41.7548 48.2095 45.6425 44.5959 48.4153C40.9823 51.1881 36.5548 52.691 32 52.691C27.4452 52.691 23.0177 51.1881 19.4041 48.4153C15.7905 45.6425 13.1929 41.7548 12.014 37.3552C10.8351 32.9556 11.1409 28.29 12.884 24.0819C13.8998 21.6294 15.3708 19.4122 17.2021 17.5383C19.3848 15.3048 20.3887 11.8886 18.8272 9.18411Z" fill="#37C99E"/>
        <circle ref={element => { childRef.current.rest2 = element }} cx="32" cy="32" r="14" fill="#FFDA54"/>
      </g>
    </svg>
  )
}

RestRelaxation.propTypes = {
  className: PropTypes.string,
  childRef: PropTypes.node
}

export default RestRelaxation
