import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const BusinessSector = ({ jobs, setFilterBusinessType }) => {
  const [businessType, setBusinessType] = useState([])

  useEffect(() => {
    const uniqueCategory = [...new Set(jobs.map(item => `${item?.category?.name}`))]
    const listJobSectors = uniqueCategory.map((uniqueItem) => {
      const filterCategory = jobs.filter(function (item) {
        if (`${item?.category?.name}` === uniqueItem) {
          return true
        } else {
          return false
        }
      })

      const count = filterCategory.length
      return {
        id: `${filterCategory[0]?.category?.id}`,
        name: uniqueItem === 'undefined' ? 'Other' : uniqueItem,
        count
      }
    })

    const listJobSectorsSort = listJobSectors.sort((a, b) => b.count - a.count)
    setBusinessType(listJobSectorsSort)
  }, [jobs])

  const handleCheckbox = (e) => {
    setFilterBusinessType(state => {
      let temp = [...state]
      if (temp.includes(e.target.value)) {
        temp = temp.filter(item => item !== e.target.value)
      } else {
        temp = [...state, e.target.value]
      }
      return [...temp]
    })
  }

  return (
    <div className="p-6 bg-neutral-10 rounded-xl">
      <div className="pb-4 border-b border-b-neutral-30">
        <h1 className="text-[22px] leading-[150%] font-normal text-neutral-100">Business sectors</h1>
      </div>

      <div className="mt-4 flex flex-col gap-y-1">
        {businessType.map(({ id, name, count }, index) => {
          return (
            <div key={index} className="flex justify-between items-center py-3">
              <div className="flex gap-x-4 items-center">
                <input id={`${name}-${index}`} type="checkbox" value={id} className="hover:cursor-pointer" onClick={handleCheckbox}/>
                <label htmlFor={`${name}-${index}`} className="text-m-medium text-neutral-100 hover:cursor-pointer">{name}</label>
              </div>
              <p className="text-m-medium text-neutral-50">{count}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

BusinessSector.propTypes = {
  jobs: PropTypes.array,
  setFilterBusinessType: PropTypes.func
}

export default BusinessSector
