import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import AccessIconAnimation from 'animation/start-hiring/advantage/AccessIconAnimation'

const AccessTalent = ({ linkIsHover, setLinkIsHover }) => {
  const isHover = linkIsHover === 'access'

  return (
    <Link
      to="/start-hiring"
      onMouseOver={() => setLinkIsHover('access')}
      onMouseLeave={() => setLinkIsHover('')}
    >
      <div className="relative">
        {/* Box Shadow */}
        <div className={clsx(
          'absolute bg-neutral-100 bg-opacity-[0.26] blur-[80px] top-6 left-4',
          'transition-all duration-700',
          isHover ? 'w-[236px] h-[272px]' : 'w-[0px] h-[0px]'
        )}></div>

        {/* Background */}
        <div
          className={clsx(
            'absolute top-0 left-0 w-full h-full bg-neutral-20 overflow-hidden'
          )}
        >
          <div className={clsx(
            'relative bg-primary-main w-[200%] h-[200%]',
            'transform origin-center duration-[350ms] rounded-[50%] left-[-50%]',
            'transition-all ease-out',
            isHover ? 'scale-100 translate-y-[-7.5%]' : 'scale-0 translate-y-0'
          )}>
          </div>
        </div>

        {/* Content */}
        {/* <div className=" bg-neutral-20"> */}
        <div className={clsx(
          'relative flex flex-col justify-between py-6 pl-6 pr-3 min-h-[280px]',
          'transform duration-500',
          isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
        )}>
          <div>
            <AccessIcon/>
            <h1 className={clsx(
              'w-[178px] lg:w-full mt-6 h5-medium',
              isHover ? 'text-neutral-10' : 'text-neutral-100'
            )}>Access & Monitor top talent</h1>
            <p className={clsx(
              'mt-1 text-s-reguler text-neutral-10',
              'transform duration-500',
              isHover ? 'opacity-[0.7]' : 'opacity-0'
            )}>
              Want to know when that unicorn candidate becomes available? Monitor the market 24/7 and always get the first pick.
            </p>
          </div>

          <button
            className={clsx(
              'flex items-center link-medium self-end',
              isHover ? 'text-neutral-10' : 'text-neutral-90'
            )}
          >
            View More
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.30664 15.3206L15.2404 7.38682" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </Link>
  )
}

const AccessIcon = ({ className }) => {
  const circle1Ref = useRef()
  const circle2Ref = useRef()

  AccessIconAnimation(circle1Ref, circle2Ref)

  return (
    <div
      id="access-icon"
      className={clsx(
        className,
        'relative h-10 w-10 overflow-hidden'
      )}
    >
      <svg ref={circle1Ref} className="absolute" width="100%" height="100%">
        <circle cx="14.5833" cy="25.4166" r="14.5833" fill="#FFAD42"/>
      </svg>
      <svg ref={circle2Ref} className="absolute" width="100%" height="100%">
        <circle cx="28.3332" cy="11.6667" r="11.1667" fill="#FFDA54" stroke="white"/>
      </svg>
    </div>
  )
}

AccessIcon.propTypes = {
  className: PropTypes.string
}

AccessTalent.propTypes = {
  linkIsHover: PropTypes.bool,
  setLinkIsHover: PropTypes.func
}

export default AccessTalent
