import clsx from 'clsx'
import React, { useRef } from 'react'

import Illustration from 'assets/start-hiring/hero/Illustration'

// import useResponsive from 'utils/useResponsive'

// import HeroImage from 'assets/start-hiring/hero/hero-image.png'
// import DesktopImage from 'assets/start-hiring/hero/desktop-image.png'
// import HeroImageTablet from 'assets/start-hiring/hero/hero-image-tablet.png'

import {
  //  HeroImageAnimation,
  IllustrationAnimation
} from 'animation/start-hiring/hero/RightAnimation'

const HeroRight = () => {
  // const { xl } = useResponsive()

  const illustrationRef = useRef([])
  // const heroImageRef = useRef()

  // HeroImageAnimation(heroImageRef)
  IllustrationAnimation(illustrationRef)

  return (
    <div className={clsx(
      'flex items-center justify-center',
      'hidden md:block relative',
      'w-auto',
      'md:pt-0 lg:pt-[160px] xl:pt-[103px]'
    )}>
      <Illustration childRef={illustrationRef} />
    </div>
  )
}

export default HeroRight
