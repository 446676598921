import React from 'react'

// import HoneyWell from 'assets/svg/trusted-by/honeywell.svg'
// import Handy from 'assets/svg/trusted-by/handy.svg'
// import Linkedin from 'assets/svg/trusted-by/linkedin.svg'
// import Monday from 'assets/svg/trusted-by/monday.svg'
// import Intel from 'assets/svg/trusted-by/intel.svg'
// import Sotheby from 'assets/svg/trusted-by/sotheby’s.svg'
// import clsx from 'clsx'
import Brand from './Brand'

const Trusted = () => {
  return (
    <section className="pt-12 pb-24 lg:pt-16 lg:pb-[88px] overflow-x-hidden">
      <h1 className="px-[9px] lg:px-0 h3-medium text-neutral-100 text-center">Loved by Innovators</h1>

      <Brand/>
      {/* <div className={clsx(
        'relative flex items-center justify-between w-[1932px] mt-14 h-12 lg:h-[54px]',
        'left-[-454px] xl:left-[-231px]'
      )}>
        <img src={Intel} alt="" />
        <img src={Sotheby} alt=""/>
        <img src={HoneyWell} alt=""/>
        <img src={Handy} alt=""/>
        <img src={Linkedin} alt=""/>
        <img src={Monday} alt=""/>
        <img src={Intel} alt="" />
        <img src={Sotheby} alt=""/>
        <img src={HoneyWell} alt=""/>
        <img src={Handy} alt=""/>
      </div> */}
    </section>
  )
}

export default Trusted
