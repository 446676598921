import React from 'react'

import clsx from 'clsx'
import Step from './Step'
import FormGroup from './FormGroup'

const Form = () => {
  return (
    <section className={clsx(
      'flex',
      'flex-col sm:flex-row',
      'md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1440px]',
      'm-auto left-0 right-0',
      'overflow-x-hidden'
    )}>
      <FormGroup/>

      <Step/>
    </section>
  )
}

export default Form
