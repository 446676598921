import gsap from 'gsap'
import { useEffect } from 'react'

const IllustrationAnimation = (
  pathEclipse, circle1Ref, circle2Ref,
  brisbaneRef, sydneyRef, melbourneRef,
  markerRef
) => {
  useEffect(() => {
    gsap.fromTo(pathEclipse.current,
      { opacity: 0, scale: 0.5, transform: 'translate(20px,20px)' },
      { opacity: 1, scale: 1, transform: 'translate(0px,0px)', delay: 1 }
    )

    // 0 : Outer
    gsap.fromTo(circle1Ref.current[0],
      { opacity: 0, scale: 0, transformOrigin: '50% 50%' },
      { opacity: 1, scale: 1, duration: 2.25, transformOrigin: '50% 50%', delay: 0.5 }
    )

    // 1 : middleInnger
    gsap.fromTo(circle1Ref.current[1],
      { opacity: 0, scale: 1, rotate: 75, transformOrigin: '80% 80%' },
      { opacity: 1, scale: 1, duration: 2, rotate: 0, transformOrigin: '80% 80%', delay: 0.5, ease: 'back.inOut(10)' }
    )

    // 2 : Inner
    gsap.fromTo(circle1Ref.current[2],
      { opacity: 0, scale: 1, rotate: 720, transformOrigin: '50% 50%' },
      { opacity: 1, scale: 1, duration: 2, rotate: 0, transformOrigin: '50% 50%', delay: 0.5 }
    )

    gsap.fromTo(markerRef.current,
      { opacity: 0, scale: 1, rotate: -30, transformOrigin: 'bottom left' },
      { opacity: 1, scale: 1, duration: 1.5, rotate: 0, transformOrigin: 'bottom left', delay: 2.2, ease: 'elastic.out(1, 0.3)' }
    )

    gsap.fromTo(circle2Ref.current,
      { opacity: 0, scale: 0, transformOrigin: 'center' },
      { opacity: 1, scale: 1, ease: 'Power3.easeOut', delay: 2.3 }
    )

    gsap.fromTo(brisbaneRef.current,
      { opacity: 0, y: 25, rotate: -20, transformOrigin: 'center' },
      { opacity: 1, y: 0, rotate: 0, transformOrigin: 'center', duration: 2, delay: 2.3, ease: 'elastic.out(1.25, 0.175)' }
    )
    gsap.fromTo(sydneyRef.current,
      { opacity: 0, y: 25, rotate: -20, transformOrigin: 'center' },
      { opacity: 1, y: 0, rotate: 0, transformOrigin: 'center', duration: 2, delay: 2.4, ease: 'elastic.out(1.25, 0.175)' }
    )
    gsap.fromTo(melbourneRef.current,
      { opacity: 0, y: 25, rotate: -20, transformOrigin: 'center' },
      { opacity: 1, y: 0, rotate: 0, transformOrigin: 'center', duration: 2, delay: 2.5, ease: 'elastic.out(1.25, 0.175)' }
    )
  }, [])
}

export default IllustrationAnimation
