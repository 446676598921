import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const ScaleIconAnimation = (pathRef, rect1Ref, rect2Ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(pathRef.current,
      {
        left: '-25px',
        bottom: '-25px',
        opacity: 1
      },
      {
        left: '0px',
        bottom: '0px',
        opacity: 1,
        delay: 0.5,
        duration: 1,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#advantage-start-hiring',
          start: 'top center'
        }
      }
    )
    gsap.fromTo(rect1Ref.current,
      {
        left: '-25px',
        bottom: '-25px',
        opacity: 1
      },
      {
        left: '0px',
        bottom: '0px',
        opacity: 1,
        delay: 1,
        duration: 1,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#advantage-start-hiring',
          start: 'top center'
        }
      }
    )
    gsap.fromTo(rect2Ref.current,
      {
        right: '-25px',
        top: '-25px',
        opacity: 1
      },
      {
        right: '0px',
        top: '0px',
        opacity: 1,
        delay: 1.5,
        duration: 1,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#advantage-start-hiring',
          start: 'top center'
        }
      }
    )
  }, [])
}

export default ScaleIconAnimation
