import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import useResponsive from "utils/useResponsive";
import { ImageSwiperAnimation } from "animation/home/HeroRight";

const ImageSwiper = ({ swiperDirection, activeIndex, arrTeam }) => {
  const len = arrTeam.length - 1;
  const { lg, xl } = useResponsive();

  const imageContainerRef = useRef();

  ImageSwiperAnimation(imageContainerRef);

  console.log(swiperDirection);

  return (
    <div
      ref={imageContainerRef}
      className="absolute w-full h-full overflow-hidden"
    >
      {arrTeam.map(({ photo }, index) => {
        return (
          <img
            key={index}
            className={clsx(
              "h-[559px] lg:h-[642px] xl:h-full m-auto left-0 right-0",
              "transform transition-all duration-1000",
              "absolute top-0 left-0",
              index < 1 && "",
              index === len && "",

              swiperDirection === "left" && {
                "translate-x-[-100%] duration-[0ms]":
                  index > activeIndex + 1 || index < activeIndex,
                "translate-x-[100%]":
                  index === activeIndex + 1 ||
                  (activeIndex === len && index === 0),
              },

              swiperDirection === "right" && {
                "translate-x-[100%] duration-[0ms]":
                  index < activeIndex - 1 || index > activeIndex,
                "translate-x-[-100%]":
                  index === activeIndex - 1 ||
                  (activeIndex === 0 && index === len),
              }
            )}
            src={xl ? photo.large : lg ? photo.medium : photo.small}
            loading="lazy"
            alt="image"
          />
        );
      })}
    </div>
  );
};

ImageSwiper.propTypes = {
  swiperDirection: PropTypes.string,
  activeIndex: PropTypes.number,
  arrTeam: PropTypes.array,
};

export default ImageSwiper;
