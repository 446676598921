import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

import Image from 'assets/about-us/hero/image-left.png'

const IllustrationLeft = ({ childRef }) => {
  return (
    <svg
      className={clsx(
        'absolute z-[2] left-0 bottom-0',
        'w-auto',
        'h-[110px] lg:h-[300px] xl:h-[420px]'
      )}
      width="601" height="420" viewBox="0 0 601 420" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"
    >
      <path ref={element => { childRef.current[1] = element }} d="M191 420C191 392.386 213.386 370 241 370C268.614 370 291 392.386 291 420C291 447.614 268.614 470 241 470H191V420Z" fill="#6E52FF" />
      <path ref={element => { childRef.current[2] = element }} d="M102 229C102 201.386 124.386 179 152 179C179.614 179 202 201.386 202 229C202 256.614 179.614 279 152 279H102V229Z" fill="#0099FF" />
      <rect ref={element => { childRef.current[3] = element }} width="100" height="100" transform="matrix(-1 0 0 1 391 370)" fill="#0099FF" />
      <path ref={element => { childRef.current[4] = element }} d="M102 370C102 356.868 104.638 343.864 109.764 331.732C114.89 319.599 122.404 308.575 131.875 299.289C141.347 290.003 152.591 282.637 164.966 277.612C177.342 272.587 190.605 270 204 270L204 370L102 370Z" fill="#37C99E" />
      <path ref={element => { childRef.current[5] = element }} d="M0 270C13.3949 270 26.6585 272.587 39.0338 277.612C51.409 282.638 62.6534 290.004 72.125 299.289C81.5965 308.575 89.1098 319.599 94.2358 331.732C99.3617 343.864 102 356.868 102 370L0 370V270Z" fill="#FFDA54" />
      <circle ref={element => { childRef.current[6] = element }} cx="102" cy="300" r="16" fill="#F9B8C4" />
      <path ref={element => { childRef.current[7] = element }} d="M0 370H141C168.614 370 191 392.386 191 420C191 447.614 168.614 470 141 470H0V370Z" fill="#0099FF" />
      <circle ref={element => { childRef.current[8] = element }} cx="140" cy="420" r="27" fill="#1A49BA" />
      <circle ref={element => { childRef.current[9] = element }} cx="448" cy="350" r="20" fill="#F9B8C4" />
      <circle ref={element => { childRef.current[10] = element }} cx="448" cy="350" r="12" fill="#E0A6B0" />
      <rect ref={element => { childRef.current[11] = element }} width="225" height="100" transform="matrix(-1 0 0 1 428 270)" fill="#FFDA54" />
      <circle ref={element => { childRef.current[12] = element }} cx="378" cy="320" r="50" fill="#FFAD42" />
      <path ref={element => { childRef.current[13] = element }} d="M216 370C216 363.434 217.293 356.932 219.806 350.866C222.319 344.8 226.002 339.288 230.645 334.645C235.288 330.002 240.8 326.319 246.866 323.806C252.932 321.293 259.434 320 266 320C272.566 320 279.068 321.293 285.134 323.806C291.2 326.319 296.712 330.002 301.355 334.645C305.998 339.288 309.681 344.8 312.194 350.866C314.707 356.932 316 363.434 316 370L266 370L216 370Z" fill="#FFAD42" />
      <path ref={element => { childRef.current[14] = element }} d="M0 0C56.333 0 102 45.667 102 102V279C45.667 279 0 233.333 0 177V0Z" fill="#6E52FF" />
      <path ref={element => { childRef.current[15] = element }} d="M102 109C102 83.5949 122.595 63 148 63C173.405 63 194 83.5949 194 109V179H102V109Z" fill="#37C99E" />
      {/* <mask id="mask0_420_4864" maskType="alpha" maskUnits="userSpaceOnUse" x="194" y="70" width="200" height="200">
        <circle cx="294" cy="170" r="100" fill="#E54F52" />
      </mask>
      <g mask="url(#mask0_420_4864)">
        <circle cx="294" cy="170" r="100" fill="#6E52FF" />
        <rect x="205" y="78" width="173" height="260" fill="url(#pattern0)" />
      </g> */}
      <path ref={element => { childRef.current[16] = element }} d="M423.604 176C432.034 194.006 424.271 215.438 406.265 223.868C397.834 205.862 405.597 184.43 423.604 176Z" fill="#37C99E" />
      <path ref={element => { childRef.current[17] = element }} d="M449.868 229.265C441.438 247.271 420.006 255.034 402 246.604C410.43 228.597 431.862 220.834 449.868 229.265Z" fill="#37C99E" />
      <path ref={element => { childRef.current[18] = element }} d="M391 420C391 392.386 413.386 370 441 370H551C578.614 370 601 392.386 601 420C601 447.614 578.614 470 551 470H441C413.386 470 391 447.614 391 420Z" fill="#37C99E" />
      <circle ref={element => { childRef.current[19] = element }} cx="553" cy="420" r="27" fill="#1A49BA" />
      <rect ref={element => { childRef.current[20] = element }} width="54" height="23" rx="11.5" transform="matrix(-1 0 0 1 553 408)" fill="#FFDA54" />
      {/* <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_420_4864" transform="translate(-0.001208) scale(0.000366917)" />
        </pattern>
      </defs> */}
      <image ref={element => { childRef.current[21] = element }} href={Image} width="200px" height="200px" x="195" y="70" />
    </svg>
  )
}

IllustrationLeft.propTypes = {
  childRef: PropTypes.node
}

export default IllustrationLeft
