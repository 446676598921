import React from 'react'

import clsx from 'clsx'
import HeroLeft from './HeroLeft'
import HeroRight from './HeroRight'

const Hero = () => {
  return (
    <section id="start-hiring-hero" className={clsx(
      'flex justify-between',
      'flex-col md:flex-row',
      'pt-[120px] lg:pt-0',
      'pb-20 lg:pb-[160px] xl:pb-[86px]',
      'px-6 lg:pl-14 lg:pr-[17px] xl:pl-[120px] xl:pr-14',
      'sm:max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1440px]',
      'm-auto left-0 right-0',
      'overflow-x-hidden'
    )}>
      <HeroLeft/>
      <HeroRight/>
    </section>
  )
}

export default Hero
