import gsap from 'gsap'
import { useEffect } from 'react'

const SearchFormAnimation = (ref) => {
  useEffect(() => {
    gsap.fromTo(ref.current.title,
      { yPercent: 100, opacity: 0 },
      { yPercent: 0, opacity: 1, duration: 0.7, delay: 0.6 }
    )
    gsap.fromTo(ref.current.location,
      { yPercent: 100, opacity: 0 },
      { yPercent: 0, opacity: 1, duration: 0.6, delay: 0.8 }
    )
    gsap.fromTo(ref.current.searchButton,
      { yPercent: 100, opacity: 0 },
      { yPercent: 0, opacity: 1, duration: 0.5, delay: 1 }
    )
  }, [])
}

export default SearchFormAnimation
