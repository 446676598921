import React from 'react'
import PropTypes from 'prop-types'

const Location = ({ text }) => {
  return (
    <div className="flex gap-x-1 items-center text-m-medium text-neutral-60">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.91602 8.59821C2.91602 4.76499 6.11925 1.66675 9.99389 1.66675C13.8794 1.66675 17.0827 4.76499 17.0827 8.59821C17.0827 10.5298 16.3802 12.3231 15.2239 13.8431C13.9484 15.5197 12.3761 16.9805 10.6065 18.1271C10.2014 18.3921 9.83591 18.4121 9.39139 18.1271C7.61163 16.9805 6.03942 15.5197 4.77477 13.8431C3.61767 12.3231 2.91602 10.5298 2.91602 8.59821ZM7.66121 8.81404C7.66121 10.0982 8.70906 11.1081 9.99389 11.1081C11.2796 11.1081 12.3375 10.0982 12.3375 8.81404C12.3375 7.53991 11.2796 6.48077 9.99389 6.48077C8.70906 6.48077 7.66121 7.53991 7.66121 8.81404Z" fill="#6B7C94"/>
      </svg>
      {text}
    </div>
  )
}

Location.propTypes = {
  text: PropTypes.string
}

export default Location
