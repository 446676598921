import React from 'react'
import PropTypes from 'prop-types'

const Recruiter = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#0099FF"/>
      <path opacity="0.4" d="M20.2735 19.2199L20.5335 21.3266C20.6002 21.8799 20.0069 22.2666 19.5335 21.9799L16.7402 20.3199C16.4335 20.3199 16.1335 20.2999 15.8402 20.2599C16.3335 19.6799 16.6269 18.9466 16.6269 18.1532C16.6269 16.2599 14.9869 14.7266 12.9602 14.7266C12.1869 14.7266 11.4735 14.9466 10.8802 15.3333C10.8602 15.1666 10.8535 14.9999 10.8535 14.8266C10.8535 11.7932 13.4869 9.33325 16.7402 9.33325C19.9935 9.33325 22.6269 11.7932 22.6269 14.8266C22.6269 16.6266 21.7002 18.2199 20.2735 19.2199Z" fill="white"/>
      <path d="M16.6273 18.1534C16.6273 18.9468 16.334 19.6801 15.8406 20.2601C15.1806 21.0601 14.1339 21.5734 12.9606 21.5734L11.2206 22.6068C10.9273 22.7868 10.5539 22.5401 10.5939 22.2001L10.7606 20.8868C9.86728 20.2668 9.29395 19.2734 9.29395 18.1534C9.29395 16.9801 9.92062 15.9468 10.8806 15.3335C11.4739 14.9468 12.1873 14.7268 12.9606 14.7268C14.9873 14.7268 16.6273 16.2601 16.6273 18.1534Z" fill="white"/>
    </svg>

  )
}

Recruiter.propTypes = {
  className: PropTypes.string
}

export default Recruiter
