import React from 'react'
import PropTypes from 'prop-types'

const HealthWealthness = ({ childRef, className }) => {
  return (
    <svg ref={element => { childRef.current.health = element }} className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <mask id="mask0_420_5010" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <rect width="64" height="64" fill="white"/>
      </mask> */}
      <g mask="url(#mask0_420_5010)">
        <path d="M38.5509 54.4868C43.4524 50.8975 44.5161 44.0144 40.9268 39.1129L20.2483 10.8747C16.659 5.9732 9.77591 4.90947 4.87445 8.49875C-0.0270065 12.088 -1.09074 18.9711 2.49854 23.8726L23.1771 52.1108C26.7663 57.0123 33.6494 58.076 38.5509 54.4868Z" fill="#1A49BA"/>
        <path d="M25.1921 54.6324C20.3506 50.9627 19.4007 44.063 23.0704 39.2214L44.2124 11.3285C47.8822 6.487 54.7819 5.53707 59.6234 9.2068C64.4649 12.8765 65.4149 19.7763 61.7451 24.6178L40.6031 52.5107C36.9334 57.3522 30.0336 58.3022 25.1921 54.6324Z" fill="#F9B8C4"/>
      </g>
    </svg>
  )
}

HealthWealthness.propTypes = {
  className: PropTypes.string,
  childRef: PropTypes.node
}

export default HealthWealthness
