import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

import Image from 'assets/about-us/hero/image-right.png'

const IllustrationRight = ({ childRef }) => {
  return (
    <svg
      className={clsx(
        'absolute z-[2] right-0 bottom-0',
        'w-auto',
        'h-[110px] lg:h-[300px] xl:h-[420px]'
      )}
      width="601" height="420"
      viewBox="0 0 601 420" fill="none" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path ref={element => { childRef.current[1] = element }} d="M410 420C410 392.386 387.614 370 360 370C332.386 370 310 392.386 310 420C310 447.614 332.386 470 360 470H410V420Z" fill="#6E52FF" />
      <path ref={element => { childRef.current[2] = element }} d="M499 229C499 201.386 476.614 179 449 179C421.386 179 399 201.386 399 229C399 256.614 421.386 279 449 279H499V229Z" fill="#0099FF" />
      <rect ref={element => { childRef.current[3] = element }} width="100" height="100" transform="matrix(-1 0 0 1 310 370)" fill="#0099FF" />
      <path ref={element => { childRef.current[4] = element }} d="M397 270C410.395 270 423.659 272.587 436.034 277.612C448.409 282.638 459.653 290.004 469.125 299.289C478.597 308.575 486.11 319.599 491.236 331.732C496.362 343.864 499 356.868 499 370L397 370V270Z" fill="#37C99E" />
      <path ref={element => { childRef.current[5] = element }} d="M499 370C499 356.868 501.638 343.864 506.764 331.732C511.89 319.599 519.404 308.575 528.875 299.289C538.347 290.003 549.591 282.637 561.966 277.612C574.342 272.587 587.605 270 601 270L601 370L499 370Z" fill="#6E52FF" />
      <circle ref={element => { childRef.current[6] = element }} cx="504" cy="299" r="16" fill="#F9B8C4" />
      <path ref={element => { childRef.current[7] = element }} d="M601 370H460C432.386 370 410 392.386 410 420C410 447.614 432.386 470 460 470H601V370Z" fill="#0099FF" />
      <circle ref={element => { childRef.current[8] = element }} r="27" transform="matrix(-1 0 0 1 461 420)" fill="#1A49BA" />
      <circle ref={element => { childRef.current[9] = element }} cx="152" cy="350" r="20" fill="#F9B8C4" />
      <circle ref={element => { childRef.current[10] = element }} cx="152" cy="350" r="12" fill="#E0A6B0" />
      <path ref={element => { childRef.current[11] = element }} d="M172 270H297C352.228 270 397 314.772 397 370H172V270Z" fill="#FFDA54" />
      <path ref={element => { childRef.current[12] = element }} d="M382 370C382 363.434 380.707 356.932 378.194 350.866C375.681 344.8 371.998 339.288 367.355 334.645C362.712 330.002 357.2 326.319 351.134 323.806C345.068 321.293 338.566 320 332 320C325.434 320 318.932 321.293 312.866 323.806C306.8 326.319 301.288 330.002 296.645 334.645C292.002 339.288 288.319 344.8 285.806 350.866C283.293 356.932 282 363.434 282 370L332 370L382 370Z" fill="#FFAD42" />
      <path ref={element => { childRef.current[13] = element }} d="M601 0C544.667 0 499 45.667 499 102V270H601V0Z" fill="#FFDA54" />
      <path ref={element => { childRef.current[14] = element }} d="M407 109C407 83.5949 427.595 63 453 63C478.405 63 499 83.5949 499 109V179H407V109Z" fill="#6E52FF" />
      <path ref={element => { childRef.current[15] = element }} d="M150.001 213.317C155.147 232.522 174.887 243.919 194.092 238.773C188.946 219.569 169.206 208.172 150.001 213.317Z" fill="#37C99E" />
      <path ref={element => { childRef.current[16] = element }} d="M210 420C210 392.386 187.614 370 160 370H50C22.3858 370 0 392.386 0 420C0 447.614 22.3858 470 50 470H160C187.614 470 210 447.614 210 420Z" fill="#37C99E" />
      <circle ref={element => { childRef.current[17] = element }} r="27" transform="matrix(-1 0 0 1 48 420)" fill="#1A49BA" />
      <rect ref={element => { childRef.current[18] = element }} x="48" y="408" width="54" height="23" rx="11.5" fill="#FFDA54" />
      <rect ref={element => { childRef.current[19] = element }} width="49" height="49" transform="matrix(-1 0 0 1 561 207)" fill="#E6C44C" />
      {/* <mask id="mask0_420_4899" maskType="alpha" maskUnits="userSpaceOnUse" x="207" y="70" width="200" height="200">
        <circle cx="307" cy="170" r="100" fill="#E54F52" />
      </mask>
      <g mask="url(#mask0_420_4899)">
        <circle cx="307" cy="170" r="100" fill="#FE585B" />
        <rect x="218" y="70" width="177" height="266" fill="url(#pattern0)" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_420_4899" transform="translate(-0.00118622) scale(0.000366901)" />
        </pattern>
      </defs> */}
      <image ref={element => { childRef.current[20] = element }} href={Image} width="200px" height="200px" x="210" y="70" />
    </svg>
  )
}

IllustrationRight.propTypes = {
  childRef: PropTypes.node
}

export default IllustrationRight
