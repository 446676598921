import React, { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";

const Footer = lazy(() => import("./footer"));

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Suspense fallback={<div>Loading footer...</div>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Layout;
