import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const JobLocation = ({ jobs, setFilterJobLocation }) => {
  const [locationType, setLocationType] = useState([])

  useEffect(() => {
    const uniqueCity = [...new Set(jobs.map(item => `${item?.city}`))]
    const listJobCity = uniqueCity.map((uniqueItem) => {
      const filterCity = jobs.filter(function (item) {
        if (`${item?.city}` === uniqueItem) {
          return true
        } else {
          return false
        }
      })

      const count = filterCity.length
      return {
        name: uniqueItem === 'null' ? 'Other' : uniqueItem,
        count
      }
    })

    const listJobCitySort = listJobCity.sort((a, b) => b.count - a.count)
    const listJobCityPrimary = listJobCitySort.slice(0, 9)

    const amountOther = listJobCitySort.slice(9).reduce((accumulator, object) => {
      return accumulator + object.count
    }, 0)
    listJobCityPrimary.push({
      name: 'Other',
      count: amountOther
    })

    setLocationType(listJobCityPrimary)
  }, [jobs])

  const handleCheckbox = (e) => {
    setFilterJobLocation(state => {
      let temp = [...state]
      if (temp.includes(e.target.value)) {
        temp = temp.filter(item => item !== e.target.value)
      } else {
        temp = [...state, e.target.value]
      }
      return [...temp]
    })
  }

  return (
    <div className="p-6 bg-neutral-10 rounded-xl">
      <div className="pb-4 border-b border-b-neutral-30">
        <h1 className="text-[22px] leading-[150%] font-normal text-neutral-100">Job Locations</h1>
      </div>

      <div className="mt-4 flex flex-col gap-y-1">
        {locationType.map(({ name, count }, index) => {
          return (
            <div key={index} className="flex justify-between items-center py-3">
              <div className="flex gap-x-4 items-center">
                <input id={`${name}-${index}`} type="checkbox" value={name} className="hover:cursor-pointer" onClick={handleCheckbox}/>
                <label htmlFor={`${name}-${index}`} className="text-m-medium text-neutral-100 hover:cursor-pointer">{name}</label>
              </div>
              <p className="text-m-medium text-neutral-50">{count}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

JobLocation.propTypes = {
  setFilterJobLocation: PropTypes.func,
  jobs: PropTypes.array
}

export default JobLocation
