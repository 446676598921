import clsx from "clsx";
import React, { useRef } from "react";

import AmyThomas from "assets/about-us/teams/amy-thomas.png";
import LexieAhern from "assets/about-us/teams/lexie-ahern.png";
import DanielRyan from "assets/about-us/teams/daniel-ryan.png";
import BlakeThompson from "assets/about-us/teams/blake-thompson.png";
// import MeganWarry from "assets/about-us/teams/megan-warry.png";
// import JoshCarson from "assets/about-us/teams/josh-carson.png";
// import BryanChung from "assets/about-us/teams/bryan-chung.png";
// import Josh from "assets/about-us/teams/josh.png";
// import Emma from "assets/about-us/teams/emma.png";
import CowenLion from "assets/about-us/teams/cowen-lion.png";
import ZahraAbug from "assets/about-us/teams/zahra-abug.png";
import CardAnimation from "animation/about-us/Team/CardAnimation";

import LinkedinIcon from "assets/about-us/teams/LinkedinIcon";

const Team = () => {
  const arrTeams = [
    {
      img: BlakeThompson,
      name: "Blake Thompson",
      role: "Partner",
      phone: "0485 866 396",
      urlLinkedIn: "https://www.linkedin.com/in/blakethompson92/",
    },
    {
      img: AmyThomas,
      name: "Amy Thomas",
      role: "Principal Consultant",
      phone: "0481 613 268",
      urlLinkedIn: "https://www.linkedin.com/in/amy-thomas-b9b74b173/",
    },
    {
      img: LexieAhern,
      name: "Lexie Ahern",
      role: "Executive Consultant",
      phone: "0481 611 527",
      urlLinkedIn: "https://www.linkedin.com/in/lexie-ahern/",
    },
    {
      img: DanielRyan,
      name: "Daniel Ryan",
      role: "Partner",
      phone: "0483 961 049",
      urlLinkedIn: "https://www.linkedin.com/in/daniel-ryan-b3562989/",
    },
    {
      img: CowenLion,
      name: "Cowen Liong",
      role: "Candidate Manager",
      phone: "1300 323 491",
      urlLinkedIn: "https://www.linkedin.com/in/cowen-liong-b331981a0/",
    },
    {
      img: ZahraAbug,
      name: "Zahra Abug",
      role: "Candidate Resourcer",
      phone: "1300 323 491",
      urlLinkedIn: "https://www.linkedin.com/in/zahra-a-730a1b235/",
    },
    // {
    //   img: BryanChung,
    //   name: "Bryan Chung",
    //   role: "Executive Consultant",
    //   phone: "0485 873 798",
    //   urlLinkedIn: "https://www.linkedin.com/in/bryan-chung-963524236/",
    // },
    // {
    //   img: MeganWarry,
    //   name: "Megan Warry",
    //   role: "Executive Consultant",
    //   phone: "0488 825 673",
    //   urlLinkedIn: "https://www.linkedin.com/in/megan-warry-892725218/",
    // },
    // {
    //   img: JoshCarson,
    //   name: "Josh Carson",
    //   role: "Executive Consultant",
    //   phone: "0481 515 947",
    //   urlLinkedIn: "https://www.linkedin.com/in/joshua-carson-8b1b0a241/",
    // },
    // {
    //   img: Josh,
    //   name: "Josh Ainslie",
    //   role: "Managing Consultant",
    //   phone: "0481 611 904",
    //   urlLinkedIn: "https://www.linkedin.com/in/joshainslie/",
    // },
    // {
    //   img: Emma,
    //   name: "Emma Lamb",
    //   role: "Candidate Manager",
    //   phone: "0483 925 454",
    //   urlLinkedIn: "https://www.linkedin.com/in/emmavendito/",
    // },
  ];

  const cardRef = useRef([]);
  CardAnimation(cardRef);

  return (
    <section
      className={clsx(
        "bg-neutral-20",
        "py-12 lg:py-20 xl:py-24",
        "overflow-x-hidden"
      )}
    >
      <div
        className={clsx(
          "max-w-[768px] lg:max-w-[1024px] xl:max-w-[1440px]",
          "m-auto left-0 right-0"
        )}
      >
        <h1 className="px-6 lg:px-0 h2-medium text-center lg:display2-medium text-neutral-100 m-auto left-0 right-0 w-fit">
          Meet The Team
        </h1>

        <div
          className={clsx(
            "grid sm:flex",
            "grid-cols-2 sm:flex-wrap",
            "justify-center",
            "gap-4 lg:gap-8 xl:gap-10",
            "px-6 lg:px-14 xl:px-[191px]",
            "mt-12 lg:mt-16 xl:mt-[58px]"
          )}
        >
          {arrTeams.map((team, index) => {
            const { img, name, role, phone, urlLinkedIn } = team;
            return (
              <div
                key={index}
                ref={(element) => {
                  cardRef.current[index] = element;
                }}
                className={clsx(
                  "px-4 pt-4 pb-6 lg:px-6 lg:pt-6 lg:pb-8 bg-neutral-10 rounded-2xl",
                  "max-w-[282px] xl:max-w-[326px]",
                  "w-full",
                  "min-w-0"
                )}
              >
                <div>
                  <img src={img} alt="" />
                </div>
                <div className="flex flex-col gap-y-2 items-center mt-6">
                  <h1 className="text-neutral-100 whitespace-nowrap text-base lg:text-2xl font-bold lg:leading-[36px]">
                    {name}
                  </h1>
                  <p className="text-neutral-60 whitespace-nowrap text-[12px] leading-4 lg:text-xl font-normal lg:leading-[30px]">
                    {role}
                  </p>
                  <div
                    className={clsx(
                      "flex items-center",
                      "space-x-2 lg:space-x-3"
                    )}
                  >
                    <p className="text-neutral-60 whitespace-nowrap text-[12px] leading-4 lg:text-xl font-normal lg:leading-[30px]">
                      {phone}
                    </p>
                    <a href={urlLinkedIn} target="_blank" rel="noreferrer">
                      <LinkedinIcon />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
