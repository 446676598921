import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import Image from 'assets/hero/ilustration/reliability.png'
import { ReliabilityAnimation } from 'animation/home/StickerAnimation'

const Reliability = ({ activeIndex }) => {
  const imgRef = useRef()
  ReliabilityAnimation(imgRef, activeIndex)

  return (
    <img ref={imgRef} className="relative h-12 w-[226px] left-[-115px] xl:left-[-57px] mt-3" src={Image} alt="" style={{
      boxShadow: '0px 16px 50px rgba(30, 36, 50, 0.1)'
    }}/>
  )
}

Reliability.propTypes = {
  activeIndex: PropTypes.number
}

export default Reliability
