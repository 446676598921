import clsx from 'clsx'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import toast, { Toaster } from 'react-hot-toast'

const Form = () => {
  const formRef = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_7pm0m5g', 'template_kuxhyui', formRef.current, 'PBNnz4Wy3i1rCqub9')
      .then((result) => {
        console.log(result.text)
        toast.success('Message sent successfully')
      }, (error) => {
        console.log(error.text)
        toast.error('Message failed to send.')
      })
  }

  return (
    <section className={clsx(
      'p-6 lg:p-14 xl:px-[191px]',
      'xl:py-24',
      'm-auto left-0 right-0',
      'max-w-[1440px]',
      'overflow-x-hidden'
    )}>
      <div className="w-fit text-center m-auto left-0 right-0">
        <h1 className="h2-bold text-neutral-100">Contact Us</h1>
        <p className="text-l-reguler text-neutral-60 mt-4">Any questions? Just write us a message!</p>
      </div>

      <form ref={formRef} onSubmit={sendEmail} className="w-full p-6 lg:p-8 border border-neutral-30 rounded-2xl mt-12 xl:mt-[72px]">
        <h1 className="h4-medium text-neutral-100">Send us message</h1>
        <div className="flex flex-col gap-y-4 mt-8">
          <div className="bg-neutral-20 rounded-2xl">
            <input name="name" type="text" className="text-m-medium bg-neutral-20 w-full h-14 rounded-2xl px-6 py-4" placeholder="Your name" required/>
          </div>

          <div className="bg-neutral-20 rounded-2xl">
            <input name="email" type="email" className="text-m-medium bg-neutral-20 w-full h-14 rounded-2xl px-6 py-4" placeholder="Email address" required/>
          </div>

          <div className="bg-neutral-20 rounded-2xl">
            <textarea name="message" className="text-m-medium bg-neutral-20 w-full rounded-2xl px-6 py-4" placeholder="Message" rows="5" required></textarea>
          </div>
        </div>

        <button type="submit" className="mt-8 h-14 w-full lg:w-[160px] bg-primary-main text-neutral-10 button-l-medium rounded-2xl">Send</button>
      </form>
      <Toaster />
    </section>
  )
}

export default Form
