import gsap from 'gsap'
import { useEffect } from 'react'

const HeroAnimation = (
  imageRef,
  bottomIllustrationRef,
  titleAnimation, descriptionAnimation
) => {
  const tl1 = gsap.timeline()
  useEffect(() => {
    tl1.fromTo(titleAnimation.current,
      { top: '-50px', opacity: 0 },
      { top: '0px', delay: 1.5, opacity: 1, duration: 0.5 }
    )
    tl1.fromTo(descriptionAnimation.current,
      { top: '-50px', opacity: 0 },
      { top: '0px', opacity: 1, duration: 0.65 }
    )
    tl1.fromTo(imageRef.current,
      { scale: 0, rotate: 180 },
      {
        scale: 1,
        rotate: 0,
        duration: 0.5,
        stagger: {
          each: 0.2,
          from: 0
        }
      }
    )
    tl1.fromTo(bottomIllustrationRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 }
    )
  }, [])
}

export { HeroAnimation }
