import React, { useRef } from 'react'

import HoneyWell from 'assets/svg/trusted-by/honeywell.svg'
import Handy from 'assets/svg/trusted-by/handy.svg'
import Linkedin from 'assets/svg/trusted-by/linkedin.svg'
import Monday from 'assets/svg/trusted-by/monday.svg'
import Intel from 'assets/svg/trusted-by/intel.svg'
import Sotheby from 'assets/svg/trusted-by/sotheby’s.svg'
import clsx from 'clsx'
import BrandAnimation from 'animation/about-us/Trusted/BrandAnimation'

const Brand = () => {
  const brandRef = useRef()
  BrandAnimation(brandRef)
  return (
    <div
      id="container-brand-trusted"
      ref={brandRef}
      className={clsx(
        'grid',
        'gap-x-[86px]',
        'grid-cols-[1164px_1164px_1164px]',
        'mt-14'
        // 'left-[-553px] lg:left-[-231px]'
      )}
    >
      <div
        className={clsx(
          'relative flex items-center justify-between w-[1164px] h-12 lg:h-[54px]',
          // 'mt-[50px] lg:mt-12 xl:mt-[72px]',
          'brand-trusted-inner',
          'transform translate-x-[0%]'
        )}
      >
        <img className="transform" src={Intel} alt="" />
        <img className="transform" src={Sotheby} alt=""/>
        <img className="transform" src={HoneyWell} alt=""/>
        <img className="transform" src={Handy} alt=""/>
        <img className="transform" src={Linkedin} alt=""/>
        <img className="transform" src={Monday} alt=""/>
      </div>

      <div
        className={clsx(
          'relative flex items-center justify-between w-[1164px] h-12 lg:h-[54px]',
          // 'mt-[50px] lg:mt-12 xl:mt-[72px]',
          'brand-trusted-inner',
          'transform translate-x-[0%]'
        )}
      >
        <img className="transform" src={Intel} alt="" />
        <img className="transform" src={Sotheby} alt=""/>
        <img className="transform" src={HoneyWell} alt=""/>
        <img className="transform" src={Handy} alt=""/>
        <img className="transform" src={Linkedin} alt=""/>
        <img className="transform" src={Monday} alt=""/>
      </div>

      <div
        className={clsx(
          'relative flex items-center justify-between w-[1164px] h-12 lg:h-[54px]',
          // 'mt-[50px] lg:mt-12 xl:mt-[72px]',
          'brand-trusted-inner',
          'transform translate-x-[0%]'
        )}
      >
        <img className="transform" src={Intel} alt="" />
        <img className="transform" src={Sotheby} alt=""/>
        <img className="transform" src={HoneyWell} alt=""/>
        <img className="transform" src={Handy} alt=""/>
        <img className="transform" src={Linkedin} alt=""/>
        <img className="transform" src={Monday} alt=""/>
      </div>
    </div>
  )
}

export default Brand
