import clsx from 'clsx'
import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'

// Parts
import Detail from 'parts/JobDetails/Detail'
import Description from 'parts/JobDetails/Description'
import Consultant from 'parts/JobDetails/Consultant'
import Share from 'parts/JobDetails/Share'
import BackToJob from 'parts/JobDetails/BackToJob'
import Illustration from 'parts/JobDetails/Illustration'
import JobTitle from 'parts/JobDetails/JobTitle'

import { clientRoot } from 'api'

const JobDetail = () => {
  const { id } = useParams()

  const [job, setJob] = useState({})

  useEffect(() => {
    clientRoot.get(`jobs/${id}`).then((response) => {
      setJob(response.data)
    })
  }, [])

  return (
    <>
      <section id='job-detail' className={clsx(
        'relative bg-neutral-20',
        'pb-6 lg:pb-14 xl:pb-[120px]',
        'pt-[120px] lg:pt-[160px] xl:pt-[192px]',
        'px-6 lg:px-14 xl:px-[120px]'
      )}>
        <Illustration/>
        <BackToJob/>

        <div className={clsx(
          'flex gap-x-8 relative z-[1]',
          'flex-col lg:flex-row',
          'gap-y-6 lg:gap-y-0',
          'mt-6 lg:mt-8',
          'm-auto left-0 right-0',
          'max-w-[1024px] xl:max-w-[1440px]'
        )}>
          <div className={clsx(
            'bg-neutral-10 rounded-2xl',
            'p-6 lg:p-8',
            'w-full lg:w-[597px] xl:w-[789px]'
          )}>
            <JobTitle text={job.title}/>
            <Detail data={job}/>
            <Description jobId={id} data={job}/>
          </div>

          <div className="w-full lg:w-[283px] xl:w-[379px]">
            <Consultant data={job}/>
            <Share/>
          </div>
        </div>
      </section>
    </>
  )
}

export default JobDetail
