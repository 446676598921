import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { NetworkAnimation } from 'animation/home/StickerAnimation'
import clsx from 'clsx'

const Vacancy = ({ value, activeIndex }) => {
  const imgRef = useRef()

  NetworkAnimation(imgRef, activeIndex)

  return (
    <div
      ref={imgRef}
      className={clsx(
        'flex justify-between items-center',
        'bg-neutral-10 p-2 rounded-full',
        'relative h-12 w-[226px] left-[-141px] xl:left-[-83px] mt-3'
      )}
      style={{
        boxShadow: '0px 16px 50px rgba(30, 36, 50, 0.1)'
      }}
    >
      <div className="flex gap-x-3 items-center">
        <Icon/>
        <p className="text-neutral-50 button-s-medium">Network</p>
      </div>
      <p className="text-neutral-100 button-s-bold">{value}/10</p>
    </div>
  )
}

const Icon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#FEF1F3"/>
      <path d="M19.6867 13.1799C19.64 13.1733 19.5934 13.1733 19.5467 13.1799C18.5134 13.1466 17.6934 12.2999 17.6934 11.2599C17.6934 10.1999 18.5534 9.33325 19.62 9.33325C20.68 9.33325 21.5467 10.1933 21.5467 11.2599C21.54 12.2999 20.72 13.1466 19.6867 13.1799Z" fill="#F9B8C4"/>
      <path d="M21.8602 17.8C21.1136 18.3 20.0669 18.4867 19.1002 18.36C19.3536 17.8134 19.4869 17.2067 19.4936 16.5667C19.4936 15.9 19.3469 15.2667 19.0669 14.7134C20.0536 14.58 21.1002 14.7667 21.8536 15.2667C22.9069 15.96 22.9069 17.1 21.8602 17.8Z" fill="#F9B8C4"/>
      <path d="M12.2933 13.1799C12.3399 13.1733 12.3866 13.1733 12.4333 13.1799C13.4666 13.1466 14.2866 12.2999 14.2866 11.2599C14.2866 10.1933 13.4266 9.33325 12.3599 9.33325C11.2999 9.33325 10.4399 10.1933 10.4399 11.2599C10.4399 12.2999 11.2599 13.1466 12.2933 13.1799Z" fill="#F9B8C4"/>
      <path d="M12.3667 16.5666C12.3667 17.2132 12.5067 17.8266 12.7601 18.3799C11.8201 18.4799 10.8401 18.2799 10.1201 17.8066C9.06674 17.1066 9.06674 15.9666 10.1201 15.2666C10.8334 14.7866 11.8401 14.5932 12.7867 14.6999C12.5134 15.2599 12.3667 15.8932 12.3667 16.5666Z" fill="#F9B8C4"/>
      <path d="M16.0802 18.58C16.0269 18.5733 15.9669 18.5733 15.9069 18.58C14.6802 18.54 13.7002 17.5333 13.7002 16.2933C13.7069 15.0267 14.7269 14 16.0002 14C17.2669 14 18.2935 15.0267 18.2935 16.2933C18.2869 17.5333 17.3135 18.54 16.0802 18.58Z" fill="#F9B8C4"/>
      <path d="M13.9132 19.96C12.9065 20.6333 12.9065 21.74 13.9132 22.4067C15.0599 23.1733 16.9399 23.1733 18.0865 22.4067C19.0932 21.7333 19.0932 20.6267 18.0865 19.96C16.9465 19.1933 15.0665 19.1933 13.9132 19.96Z" fill="#F9B8C4"/>
    </svg>
  )
}

Vacancy.propTypes = {
  value: PropTypes.number,
  activeIndex: PropTypes.number
}

export default Vacancy
