import clsx from 'clsx'
import React, { useState, useMemo, useEffect } from 'react'

import { useFilterJob, BusinessSector, ContractType, JobLocation } from './Filter'

import JobCard from './JobCard'
import ShowResult from './ShowResult'
import Pagination from './Pagination'
import RefineSearch from './RefineSearch'
import JobCardSkeleton from './Skeleton/JobCardSkeleton'

import { clientRoot } from 'api'

const Job = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [jobs, setJobs] = useState([])

  const [showResult, setShowResult] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)

  const [filterBusinessType, setFilterBusinessType] = useState([])
  const [filterJobType, setFilterJobType] = useState([])
  const [filterJobLocation, setFilterJobLocation] = useState([])

  const filterJob = useFilterJob(jobs, filterBusinessType, filterJobType, filterJobLocation)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * showResult
    const lastPageIndex = firstPageIndex + showResult

    return filterJob.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, filterJob])

  useEffect(() => {
    clientRoot.get('jobs?per_page=100&published_at_sort=desc&status=active').then((response) => {
      setJobs(response.data.results)
      setIsLoading(false)
    })
  }, [showResult, currentPage])

  return (
    <section className={clsx(
      'relative',
      'pb-12 lg:py-20 xl:p-[120px] bg-neutral-20',
      'pt-6',
      'px-6 lg:px-14',
      'overflow-x-hidden'
    )}>
      <div className={clsx(
        'flex',
        'justify-center',
        'gap-x-8 xl:gap-x-10',
        'm-auto left-0 right-0',
        'max-w-[1440px]'
      )}>
        <div className="hidden lg:block w-[371px]">
          <div className="flex flex-col gap-y-6">
            <ContractType jobs={jobs} setFilterJobType={setFilterJobType}/>
            <BusinessSector jobs={jobs} setFilterBusinessType={setFilterBusinessType}/>
            <JobLocation jobs={jobs} setFilterJobLocation={setFilterJobLocation}/>
          </div>
        </div>

        <div className="lg:w-[789px]">
          <div className="flex flex-col gap-y-6">
            {isLoading
              ? (
                <>
                  <JobCardSkeleton/>
                  <JobCardSkeleton/>
                  <JobCardSkeleton/>
                  <JobCardSkeleton/>
                  <JobCardSkeleton/>
                </>
              )
              : currentTableData.map((job, index) => {
                return (
                  <JobCard key={index} index={index} data={job} />
                )
              })}
          </div>
        </div>
      </div>

      <div className={clsx(
        'flex items-center justify-between',
        'lg:pl-16 xl:pl-0',
        'mt-12 xl:mt-16',
        'm-auto left-0 right-0',
        'max-w-[1440px]'
      )}>
        <ShowResult showResult={showResult} setShowResult={setShowResult}/>
        <Pagination
          currentPage={currentPage}
          totalCount={filterJob.length}
          totalPages={filterJob.length / showResult}
          pageSize={showResult}
          onPageChange={page => setCurrentPage(page)}
        />
      </div>

      <RefineSearch/>
    </section>
  )
}

export default Job
