import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

import Desktop from './Desktop'

import Img1 from './img-1.png'
import Img2 from './img-2.png'

const Illustration = ({ childRef }) => {
  return (
    <svg
      className={
        clsx(
          'w-full',
          '',
          'h-auto lg:h-[402px] xl:h-[648px]',
          'max-w-[500px] lg:max-w-[450px] xl:max-w-[769px]'
        )
      }
      // width="769"
      height="648" viewBox="0 0 769 648" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <path ref={element => { childRef.current[1] = element }} d="M13.8955 155.537H137.425V247.647C137.425 281.758 109.772 309.411 75.6602 309.411V309.411C41.5485 309.411 13.8955 281.758 13.8955 247.647V155.537Z" fill="#1A49BA"/>
      <rect ref={element => { childRef.current[2] = element }} x="13.8955" y="444.498" width="89.5141" height="89.4616" fill="#FFDA54"/>
      <path ref={element => { childRef.current[3] = element }} d="M562.616 155.537C582.365 155.537 601.92 159.425 620.165 166.978C638.411 174.531 654.989 185.602 668.953 199.558C682.918 213.514 693.995 230.083 701.552 248.317C709.11 266.552 713 286.096 713 305.833C713 325.57 709.11 345.114 701.552 363.349C693.995 381.583 682.918 398.152 668.953 412.108C654.989 426.064 638.411 437.135 620.165 444.688C601.92 452.241 582.365 456.128 562.616 456.128L562.616 305.833L562.616 155.537Z" fill="#9F78F8"/>
      <path ref={element => { childRef.current[4] = element }} d="M713 0.768555H617.622C574.884 0.768555 540.238 35.4147 540.238 78.1529V78.1529C540.238 120.891 574.884 155.537 617.622 155.537H713V0.768555Z" fill="#1A49BA"/>
      <path ref={element => { childRef.current[5] = element }} d="M231.414 78.1529C231.414 35.4147 196.768 0.768555 154.03 0.768555H90.384C47.6458 0.768555 12.9997 35.4147 12.9997 78.1529V78.1529C12.9997 120.891 47.6459 155.537 90.384 155.537H154.03C196.768 155.537 231.414 120.891 231.414 78.1529V78.1529Z" fill="#FFDA54"/>
      <path ref={element => { childRef.current[6] = element }} d="M386.273 472.231H273.888C231.15 472.231 196.504 506.877 196.504 549.616V549.616C196.504 592.354 231.15 627 273.888 627H386.273V472.231Z" fill="#0099FF"/>
      <path ref={element => { childRef.current[7] = element }} d="M152.642 13.2932C165.566 13.2932 178.2 17.1234 188.946 24.2994C199.692 31.4755 208.067 41.675 213.013 53.6083C217.959 65.5416 219.253 78.6727 216.732 91.341C214.21 104.009 207.987 115.646 198.848 124.779C189.709 133.913 178.066 140.132 165.39 142.652C152.715 145.172 139.576 143.879 127.636 138.936C115.695 133.993 105.49 125.622 98.3095 114.883C91.1293 104.143 87.2969 91.5166 87.2969 78.6001L152.642 78.6002L152.642 13.2932Z" fill="#FFAD42"/>
      <path ref={element => { childRef.current[8] = element }} d="M541.133 155.537C520.796 155.537 500.659 151.534 481.871 143.756C463.082 135.978 446.011 124.578 431.631 110.207C417.251 95.8349 405.844 78.7734 398.061 59.996C390.279 41.2186 386.273 21.0931 386.273 0.768587L541.133 0.7686L541.133 155.537Z" fill="#0099FF"/>
      <path ref={element => { childRef.current[9] = element }} d="M713 455.681C713 478.179 708.566 500.456 699.951 521.242C691.337 542.027 678.71 560.913 662.792 576.822C646.875 592.73 627.977 605.349 607.18 613.959C586.382 622.569 564.092 627 541.581 627L541.581 455.681H713Z" fill="#FFDA54"/>
      <ellipse ref={element => { childRef.current[10] = element }} cx="81.4786" cy="376.955" rx="67.5831" ry="67.5435" fill="#37C99E"/>
      {/* <ellipse ref={element => { childRef.current[11] = element }} cx="81.4786" cy="376.955" rx="67.5831" ry="67.5435" fill="url(#pattern0)"/> */}
      <path ref={element => { childRef.current[12] = element }} d="M25.532 541.564C25.532 558.462 30.5457 574.98 39.939 589.03C49.3323 603.079 62.6834 614.03 78.3039 620.496C93.9244 626.963 111.113 628.655 127.695 625.358C144.278 622.062 159.51 613.925 171.466 601.976C183.421 590.028 191.563 574.805 194.861 558.232C198.16 541.659 196.467 524.481 189.997 508.869C183.526 493.258 172.57 479.915 158.511 470.527C144.453 461.139 127.925 456.128 111.018 456.128L111.018 541.564H25.532Z" fill="#FFAD42"/>
      <path ref={element => { childRef.current[13] = element }} d="M231.414 78.1529C231.414 35.4147 266.06 0.768555 308.798 0.768555H386.273V78.1075C386.273 120.871 351.584 155.537 308.821 155.537V155.537C266.083 155.537 231.414 120.891 231.414 78.1529V78.1529Z" fill="#0099FF"/>
      {/* <path ref={element => { childRef.current[14] = element }} d="M231.414 78.1529C231.414 35.4147 266.06 0.768555 308.798 0.768555H386.273V78.1075C386.273 120.871 351.584 155.537 308.821 155.537V155.537C266.083 155.537 231.414 120.891 231.414 78.1529V78.1529Z" fill="url(#pattern1)"/> */}

      <image ref={element => { childRef.current[15] = element }} href={Img1} width="155" height="155" x="231" />
      <image ref={element => { childRef.current[16] = element }} href={Img2} width="135" height="135" y="309" x="13" />

      <Desktop childRef={childRef} />

      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_0_1" transform="translate(-0.233515) scale(0.00305631 0.0030581)"/>
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image1_0_1" transform="translate(-0.249561) scale(0.00312317 0.003125)"/>
        </pattern>
        <filter id="filter0_f_0_1" x="0.402626" y="32.4026" width="780.195" height="615.195" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="55.7987" result="effect1_foregroundBlur_0_1"/>
        </filter>
        <filter id="filter1_d_0_1" x="107.46" y="323.719" width="523.728" height="89.145" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="3.18375" dy="3.18375"/>
          <feGaussianBlur stdDeviation="9.94922"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.141177 0 0 0 0 0.196078 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <filter id="filter2_d_0_1" x="107.46" y="415.65" width="523.728" height="89.145" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="3.18375" dy="3.18375"/>
          <feGaussianBlur stdDeviation="9.94922"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.141177 0 0 0 0 0.196078 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <filter id="filter3_d_0_1" x="67.266" y="100.857" width="598.545" height="63.6751" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.59188"/>
          <feGaussianBlur stdDeviation="6.3675"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.141177 0 0 0 0 0.196078 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image2_0_1" transform="scale(0.00113122)"/>
        </pattern>
        <filter id="filter4_d_0_1" x="107.859" y="231.789" width="523.728" height="89.145" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="3.18375" dy="3.18375"/>
          <feGaussianBlur stdDeviation="9.94922"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.141177 0 0 0 0 0.196078 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
        </filter>
        <clipPath id="clip0_0_1">
          <rect x="80" y="112" width="572" height="407" rx="16" fill="white"/>
        </clipPath>

      </defs>
    </svg>

  )
}

Illustration.propTypes = {
  childRef: PropTypes.node
}

export default Illustration
