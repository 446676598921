import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Dots = ({ activeIndex, swiperData }) => {
  return (
    <div className={clsx(
      'absolute flex items-center gap-x-2 ',
      'left-6 lg:left-8 xl:left-10',
      'bottom-[30px] lg:bottom-[38px]'
    )}>
      {
        swiperData.map((item, index) => {
          return activeIndex === index
            ? (
              <svg key={index} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="3" fill="white"/>
                <path opacity="0.5" d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM1.5281 6C1.5281 8.46976 3.53024 10.4719 6 10.4719C8.46976 10.4719 10.4719 8.46976 10.4719 6C10.4719 3.53024 8.46976 1.5281 6 1.5281C3.53024 1.5281 1.5281 3.53024 1.5281 6Z" fill="white"/>
                <path d="M6 0C7.10306 1.31539e-08 8.18478 0.304081 9.12628 0.878828C10.0678 1.45358 10.8326 2.27674 11.3367 3.25787C11.8409 4.23899 12.0648 5.3401 11.9838 6.44019C11.9029 7.54028 11.5203 8.59678 10.878 9.49357C10.2357 10.3904 9.35868 11.0927 8.34321 11.5235C7.32775 11.9543 6.21318 12.0968 5.12199 11.9354C4.0308 11.774 3.00522 11.3149 2.15797 10.6086C1.31071 9.90222 0.674571 8.976 0.319447 7.93166L1.76619 7.4397C2.03087 8.21806 2.505 8.90839 3.13647 9.43484C3.76794 9.96128 4.53232 10.3035 5.3456 10.4238C6.15888 10.5441 6.98959 10.4378 7.74644 10.1168C8.50328 9.79571 9.15697 9.27221 9.63566 8.60382C10.1144 7.93542 10.3995 7.148 10.4599 6.32808C10.5202 5.50817 10.3533 4.68749 9.97756 3.95624C9.60182 3.22499 9.03178 2.61147 8.33007 2.1831C7.62835 1.75474 6.82213 1.5281 6 1.5281V0Z" fill="white"/>
              </svg>
            )
            : (
              <span
                key={index}
                className={clsx(
                  'rounded-full w-1.5 h-1.5 bg-neutral-10/50'
                )}
              ></span>
            )
        })
      }
    </div>
  )
}

Dots.propTypes = {
  activeIndex: PropTypes.number,
  swiperData: PropTypes.array
}

export default Dots
