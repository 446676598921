import React from 'react'
import PropTypes from 'prop-types'

const Previous = ({ onClick }) => {
  return (
    <button className="flex items-center gap-x-1 button-l-medium text-neutral-60" onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 6L9 12L15 18" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <p className="hidden lg:block">Previous</p>
    </button>
  )
}

Previous.propTypes = {
  onClick: PropTypes.func
}

export default Previous
