import clsx from 'clsx'
import React, { useRef } from 'react'

import People from 'assets/contact-us/hero/people.png'

import Illustration from 'assets/contact-us/hero/Illustration'
import TextAnimation from 'animation/contact-us/hero/TextAnimation'
import ImageAnimation from 'animation/contact-us/hero/ImageAnimation'

const Hero = () => {
  const imageRef = useRef()
  const titleRef = useRef()
  const descriptionRef = useRef()

  TextAnimation(titleRef, descriptionRef)
  ImageAnimation(imageRef)

  return (
    <section className={clsx(
      'flex justify-between relative gap-y-12',
      'flex-col lg:flex-row',
      'items-start lg:items-center',
      'px-6 lg:px-14 xl:px-[120px]',
      'pb-20 lg:pb-16 xl:pb-[72px]',
      'pt-[120px] lg:pt-24',
      'max-w-[1440px]',
      'm-auto left-0 right-0',
      'overflow-hidden'
    )}>
      <div>
        <h1 ref={titleRef} className="h2-medium lg:display1-medium underline underline-offset-8 text-neutral-100">Let’s Chat!</h1>
        <p ref={descriptionRef} className="text-l-reguler text-neutral-60 mt-8">So many ways to get in touch.</p>
      </div>

      <img ref={imageRef} className="hidden xl:block absolute bottom-0 w-[293px] left-[535px]" src={People} alt=""/>

      <Illustration/>
    </section>
  )
}

export default Hero
