import clsx from 'clsx'
import React, { useRef } from 'react'

import RestRelaxation from 'assets/about-us/experience/RestRelaxation'
import HealthWealthness from 'assets/about-us/experience/HealthWealthness'
import ProfessionalDevelopment from 'assets/about-us/experience/ProfessionalDevelopment'
import PlanningAhead from 'assets/about-us/experience/PlanningAhead'

import Title from './Title'
import IconAnimation from 'animation/about-us/JoinTeam/IconAnimation'

const Join = () => {
  const benefitArr = [
    {
      icon: RestRelaxation,
      title: 'Rest & Relaxation',
      description: 'Vendito gives you extra time off for holidays, sick leave and other personal leave (paid of course). You’ll also find space in your workplace to relax and take a break.'
    },
    {
      icon: HealthWealthness,
      title: 'Health & Wellness',
      description: 'Vendito members take advantage of our corporate affiliations and have access to health and wellness packages such as organic hair and beauty services, massages, waxes, cleanses and much more.'
    },
    {
      icon: ProfessionalDevelopment,
      title: 'Professional Development',
      description: 'Our commitment to your career growth includes a career succession plan. We support your journey in a variety of ways, including reimbursement for personal and professional development activities.'
    },
    {
      icon: PlanningAhead,
      title: 'Planning Ahead',
      description: 'It’s often uncomfortable to talk about finances, health, wealth, and overall lifestyle choices. At Vendito we’re a family and focus on ensuring both personal and professional aspirations are encouraged and achieved. Whatever your goals – we’re here to help you succeed. '
    }
  ]

  const iconRef = useRef([])
  IconAnimation(iconRef)

  return (
    <section className={clsx(
      'py-12',
      'lg:pt-20 xl:pt-24',
      'lg:pb-0 xl:pb-20',
      'm-auto left-0 right-0',
      'lg:max-w-[1024px] xl:max-w-[1440px]',
      'overflow-x-hidden'
    )}>
      <Title/>

      <div className={clsx(
        'grid gap-x-8',
        `grid-cols-1 
        md:grid-cols-[minmax(0px,440px)_minmax(0px,440px)] 
        lg:grid-cols-[minmax(0px,440px)_minmax(0px,440px)] 
        xl:grid-cols-[minmax(0px,584px)_minmax(0px,584px)]`,
        'px-6 md:px-14 xl:px-[120px]',
        'mt-16 md:mt-20 xl:mt-24',
        'gap-y-12 lg:gap-y-6'
      )}>
        {benefitArr.map((benefit, index) => {
          const { icon: Icon, title, description } = benefit
          return (
            <div key={index} className="lg:h-[357px] xl:p-10">
              <div>
                <Icon childRef={iconRef} />
              </div>
              <div className="mt-6">
                <h1 className="h4-medium text-neutral-100">{title}</h1>
                <p className="text-l-reguler text-neutral-60 mt-3">{description}</p>
              </div>
            </div>
          )
        })}

      </div>
    </section>
  )
}

export default Join
