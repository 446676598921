import PropTypes from "prop-types";
import React, { useRef } from "react";
import { ElipseAnimation, HeroLeftAnimation } from "animation/home/HeroLeft";
import clsx from "clsx";
import { Link } from "react-router-dom";

const HeroLeft = () => {
  const headerRef = useRef();
  const descriptionRef = useRef();
  const formRef = useRef();

  HeroLeftAnimation(headerRef, descriptionRef, formRef);

  return (
    <div
      className={clsx(
        "flex items-center",
        "bg-secondary-blue-main md:bg-transparent",
        "px-6 lg:px-0",
        "w-full lg:w-[607px] xl:w-full",
        "h-screen",
        "max-h-[1080px]",
        "relative"
      )}
    >
      <Elipse className="absolute top-0 right-0 z-[-1]" />

      <div className="w-full pt-[120px] pb-[74px] lg:w-[360px] lg:pt-[160px] lg:pb-[120px] xl:w-[513px] xl:pt-[165px] xl:pb-[162px]">
        <div>
          <h1
            ref={headerRef}
            className="relative text-white md:text-neutral-100 h2-medium lg:h1-medium xl:display1-medium decoration-3 underline-offset-4 underline"
          >
            Recruitment, Made Better.
          </h1>
          <p
            ref={descriptionRef}
            className="relative mt-8 lg:mt-6 text-white md:text-neutral-60 text-l-reguler"
          >
            Transform your business with an industry leading talent agency known
            for identifying, attracting, and securing the top 1% of candidates
            on and off the market.
          </p>
        </div>

        <form
          ref={formRef}
          className="relative mt-10 flex flex-col lg:flex-row gap-x-4 gap-y-4"
        >
          <input
            type="input"
            name=""
            placeholder="Enter your email..."
            className="bg-neutral-20 text-neutral-900 button-l-reguler placeholder:text-neutral-50 placeholder:button-l-reguler px-5 py-4 rounded-[100px] grow"
          />
          <Link to="/start-hiring">
            <div className="flex items-center justify-center bg-primary-main rounded-[100px] w-full lg:w-[147px] h-14 button-l-medium text-white">
              Start Hiring
            </div>
          </Link>
        </form>
      </div>
    </div>
  );
};

const Elipse = ({ className }) => {
  const elipseRef = useRef();

  ElipseAnimation(elipseRef);

  return (
    <svg
      ref={elipseRef}
      className={className}
      width="178"
      height="178"
      viewBox="0 0 178 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178 178C154.625 178 131.478 173.396 109.882 164.451C88.2864 155.505 68.6638 142.394 52.135 125.865C35.6062 109.336 22.4948 89.7136 13.5494 68.1176C4.6041 46.5217 -2.04353e-06 23.3753 0 -1.55613e-05L178 0L178 178Z"
        fill="#FFDA54"
      />
    </svg>
  );
};

Elipse.propTypes = {
  className: PropTypes.string,
};

export default HeroLeft;
