import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'

import useResponsive from 'utils/useResponsive'

import SwiperNavigation from '../SwiperNavigation'
import Dots from './Dots'
import ImageSwiper from './Image'

import { SwiperBottomContainerAnimation } from 'animation/home/HeroRight'

const Swiper = ({ arrTeam, activeIndex, setActiveIndex }) => {
  const { xl } = useResponsive()

  const [swiperDirection, setSwiperDirection] = useState('right')
  const len = arrTeam.length - 1

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
    }, 7500)
    return () => clearInterval(interval)
  }, [activeIndex])

  const containerRef = useRef()

  SwiperBottomContainerAnimation(containerRef)

  return (
    <div className={clsx(
      'overflow-hidden',
      'w-full',
      'h-full flex items-end'
    )}>
      <ImageSwiper swiperDirection={swiperDirection} activeIndex={activeIndex} arrTeam={arrTeam}/>

      <div ref={containerRef} className="relative w-full min-h-[200px] h-[28.6%] bg-primary-main">
        {arrTeam.map(({ name, specialist, width }, index) => {
          return (
            <div
              key={index}
              className={clsx(
                'relative w-fit z-[1]',
                'px-6 lg:px-8 xl:px-10',
                'py-6 lg:py-8',
                index === activeIndex ? 'block' : 'hidden'
              )}
            >
              <h1 className="text-l-bold text-white">{name}</h1>
              <p className={clsx(
                'text-s-reguler text-white opacity-[0.7]',
                xl ? width : 'w-[204px]'
              )}>{specialist}</p>
            </div>
          )
        })}

        <Dots activeIndex={activeIndex} swiperData={arrTeam}/>

        <SwiperNavigation
          prevSwiper={() => {
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
            setSwiperDirection('left')
          }}
          nextSwiper={() => {
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
            setSwiperDirection('right')
          }}
        />
      </div>
    </div>
  )
}

Swiper.propTypes = {
  arrTeam: PropTypes.array,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func
}

export default Swiper
