import React from 'react'
import PropTypes from 'prop-types'

const InputGroup = ({ name, isRequired, placeholder, type, label, value, onChange }) => {
  return (
    <div>
      <label className="block text-xs-medium text-neutral-80">
        {label}
        {isRequired && <span className="text-danger-main">*</span>}
      </label>
      <input type={type} name={name} className="rounded-2xl py-[14.5px] px-5 w-full mt-2 button-m-reguler bg-neutral-20 focus:outline-none" placeholder={placeholder} value={value} onChange={onChange} required={isRequired}/>
    </div>
  )
}

InputGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default InputGroup
