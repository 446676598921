import React from 'react'

const Australia = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_766_7833" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="18">
        <rect y="0.75" width="22" height="16.5" rx="2" fill="white"/>
      </mask>
      <g mask="url(#mask0_766_7833)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0.75V17.25H22V0.75H0Z" fill="#2E42A5"/>
        <mask id="mask1_766_7833" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="18">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0.75V17.25H22V0.75H0Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_766_7833)">
          <rect y="0.75" width="12.375" height="9.625" fill="#2B409A"/>
          <mask id="mask2_766_7833" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="11">
            <rect y="0.75" width="12.375" height="9.625" fill="white"/>
          </mask>
          <g mask="url(#mask2_766_7833)">
            <path d="M-1.37793 9.68702L1.34522 10.8813L12.4368 2.04816L13.8732 0.273354L10.9612 -0.125825L6.43719 3.68065L2.79584 6.24532L-1.37793 9.68702Z" fill="#F7FCFF"/>
            <path d="M-1.00488 10.524L0.382438 11.217L13.3576 0.108612H11.4097L-1.00488 10.524Z" fill="#F50100"/>
            <path d="M13.7529 9.68702L11.0298 10.8813L-0.0617962 2.04816L-1.4982 0.273354L1.41382 -0.125825L5.93781 3.68065L9.57916 6.24532L13.7529 9.68702Z" fill="#F7FCFF"/>
            <path d="M13.6602 10.2876L12.2728 10.9807L6.74786 6.22462L5.10982 5.69323L-1.63627 0.27949H0.311693L7.05399 5.56473L8.8449 6.20192L13.6602 10.2876Z" fill="#F50100"/>
            <mask id="path-10-outside-1_766_7833" maskUnits="userSpaceOnUse" x="-2" y="-1.25" width="16" height="13" fill="black">
              <rect fill="white" x="-2" y="-1.25" width="16" height="13"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M6.86426 0.75H5.48926V4.875H0V6.25H5.48926V10.375H6.86426V6.25H12.375V4.875H6.86426V0.75Z"/>
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.86426 0.75H5.48926V4.875H0V6.25H5.48926V10.375H6.86426V6.25H12.375V4.875H6.86426V0.75Z" fill="#F50100"/>
            <path d="M5.48926 0.75V-0.28125H4.45801V0.75H5.48926ZM6.86426 0.75H7.89551V-0.28125H6.86426V0.75ZM5.48926 4.875V5.90625H6.52051V4.875H5.48926ZM0 4.875V3.84375H-1.03125V4.875H0ZM0 6.25H-1.03125V7.28125H0V6.25ZM5.48926 6.25H6.52051V5.21875H5.48926V6.25ZM5.48926 10.375H4.45801V11.4062H5.48926V10.375ZM6.86426 10.375V11.4062H7.89551V10.375H6.86426ZM6.86426 6.25V5.21875H5.83301V6.25H6.86426ZM12.375 6.25V7.28125H13.4062V6.25H12.375ZM12.375 4.875H13.4062V3.84375H12.375V4.875ZM6.86426 4.875H5.83301V5.90625H6.86426V4.875ZM5.48926 1.78125H6.86426V-0.28125H5.48926V1.78125ZM6.52051 4.875V0.75H4.45801V4.875H6.52051ZM0 5.90625H5.48926V3.84375H0V5.90625ZM1.03125 6.25V4.875H-1.03125V6.25H1.03125ZM5.48926 5.21875H0V7.28125H5.48926V5.21875ZM6.52051 10.375V6.25H4.45801V10.375H6.52051ZM6.86426 9.34375H5.48926V11.4062H6.86426V9.34375ZM5.83301 6.25V10.375H7.89551V6.25H5.83301ZM12.375 5.21875H6.86426V7.28125H12.375V5.21875ZM11.3438 4.875V6.25H13.4062V4.875H11.3438ZM6.86426 5.90625H12.375V3.84375H6.86426V5.90625ZM5.83301 0.75V4.875H7.89551V0.75H5.83301Z" fill="#F7FCFF" mask="url(#path-10-outside-1_766_7833)"/>
          </g>
        </g>
        <g clipPath="url(#clip0_766_7833)">
          <path d="M6.1875 14.8438L5.29261 15.6707L5.38124 14.4555L4.17671 14.2714L5.18211 13.583L4.57497 12.5266L5.74006 12.8834L6.1875 11.75L6.63494 12.8834L7.80003 12.5266L7.19289 13.583L8.19829 14.2714L6.99376 14.4555L7.08239 15.6707L6.1875 14.8438Z" fill="#F7FCFF"/>
          <path d="M15.125 9.6875L14.5284 10.2388L14.5875 9.42865L13.7845 9.30597L14.4547 8.84702L14.05 8.1427L14.8267 8.38058L15.125 7.625L15.4233 8.38058L16.2 8.1427L15.7953 8.84702L16.4655 9.30597L15.6625 9.42865L15.7216 10.2388L15.125 9.6875Z" fill="#F7FCFF"/>
          <path d="M16.5 4.1875L15.9034 4.73883L15.9625 3.92865L15.1595 3.80597L15.8297 3.34702L15.425 2.6427L16.2017 2.88058L16.5 2.125L16.7983 2.88058L17.575 2.6427L17.1703 3.34702L17.8405 3.80597L17.0375 3.92865L17.0966 4.73883L16.5 4.1875Z" fill="#F7FCFF"/>
          <path d="M19.9375 6.9375L19.3409 7.48883L19.4 6.67865L18.597 6.55597L19.2672 6.09702L18.8625 5.3927L19.6392 5.63058L19.9375 4.875L20.2358 5.63058L21.0125 5.3927L20.6078 6.09702L21.278 6.55597L20.475 6.67865L20.5341 7.48883L19.9375 6.9375Z" fill="#F7FCFF"/>
          <path d="M17.1875 15.875L16.5909 16.4263L16.65 15.6161L15.847 15.4935L16.5172 15.0345L16.1125 14.3302L16.8892 14.5681L17.1875 13.8125L17.4858 14.5681L18.2625 14.3302L17.8578 15.0345L18.528 15.4935L17.725 15.6161L17.7841 16.4263L17.1875 15.875Z" fill="#F7FCFF"/>
          <path d="M19.5938 10.5469L18.9876 10.8655L19.1034 10.1906L18.613 9.71258L19.2907 9.6141L19.5938 9L19.8968 9.6141L20.5745 9.71258L20.0841 10.1906L20.1999 10.8655L19.5938 10.5469Z" fill="#F7FCFF"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_766_7833">
          <rect width="17.1875" height="14.4375" fill="white" transform="translate(4.125 2.125)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Australia
