import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LogoBlack from 'assets/logo.svg'
import clsx from 'clsx'

const NavMobile = ({ isOpenNavMobile, setIsOpenNavMobile }) => {
  return (
    <div className={clsx(
      'top-0 left-0 w-full h-screen z-[100] bg-neutral-10',
      isOpenNavMobile ? 'fixed lg:hidden' : 'hidden'
    )}>
      <div className="flex justify-between items-center px-6 py-[31px]">
        <Link to="/">
          <img className="max-w-[149px] h-[34px]" src={LogoBlack} alt=""/>
        </Link>

        <button onClick={() => setIsOpenNavMobile(false)}>
          <CloseIcon/>
        </button>
      </div>

      <div className="relative z-[1] w-full py-4 bg-neutral-20 flex justify-center border-y border-y-neutral-30">
        <a href="https://calendly.com/venditoconsulting" target="_blank" rel="noreferrer">
          <div className="flex items-center justify-center w-[124px] h-12 bg-neutral-100 rounded-full button-l-medium text-neutral-10">
            book a call
          </div>
        </a>
      </div>

      <div className="relative z-[1] px-6 mt-4">
        <Link
          to="/"
          className="flex justify-between items-center py-4 text-neutral-100 button-l-bold"
          onClick={() => setIsOpenNavMobile(false)}
        >
          Home
          <ArrowIcon/>
        </Link>

        <Link
          to="start-hiring"
          className="flex justify-between items-center py-4 text-neutral-100 button-l-bold"
          onClick={() => setIsOpenNavMobile(false)}
        >
          Start Hiring
          <ArrowIcon/>
        </Link>

        <Link
          to="jobs"
          className="flex justify-between items-center py-4 text-neutral-100 button-l-bold"
          onClick={() => setIsOpenNavMobile(false)}
        >
          Current Jobs
          <ArrowIcon/>
        </Link>

        <Link
          to="about-us"
          className="flex justify-between items-center py-4 text-neutral-100 button-l-bold"
          onClick={() => setIsOpenNavMobile(false)}
        >
          About Us
          <ArrowIcon/>
        </Link>

        <Link
          to="contact-us"
          className="flex justify-between items-center py-4 text-neutral-100 button-l-bold"
          onClick={() => setIsOpenNavMobile(false)}
        >
          Contact
          <ArrowIcon/>
        </Link>
      </div>

      <Illustration className="absolute bottom-0 left-0"/>
    </div>
  )
}

const CloseIcon = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 18L6 6" stroke="#172426" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 6L6 18" stroke="#172426" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const ArrowIcon = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 16L16 8" stroke="#718284" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 8H16V14" stroke="#718284" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const Illustration = ({ className }) => {
  return (
    <svg className={className} width="375" height="267" viewBox="0 0 375 267" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M113.72 267.654C113.72 250.056 127.986 235.79 145.583 235.79C163.181 235.79 177.447 250.056 177.447 267.654C177.447 285.251 163.181 299.517 145.583 299.517H113.72V267.654Z" fill="#6E52FF"/>
      <path d="M57.0029 145.935C57.0029 128.337 71.2687 114.071 88.8664 114.071C106.464 114.071 120.73 128.337 120.73 145.935C120.73 163.532 106.464 177.798 88.8664 177.798H57.0029V145.935Z" fill="#0099FF"/>
      <rect width="63.727" height="63.727" transform="matrix(-1 0 0 1 241.174 235.79)" fill="#0099FF"/>
      <path d="M57.0029 235.789C57.0029 227.421 58.6842 219.134 61.9509 211.402C65.2175 203.67 70.0055 196.645 76.0415 190.728C82.0774 184.81 89.2432 180.116 97.1295 176.913C105.016 173.711 113.468 172.062 122.005 172.063L122.004 235.789L57.0029 235.789Z" fill="#37C99E"/>
      <path d="M-7.99849 172.062C0.537643 172.063 8.99019 173.711 16.8765 176.913C24.7629 180.116 31.9286 184.81 37.9646 190.728C44.0005 196.645 48.7885 203.671 52.0551 211.402C55.3217 219.134 57.003 227.421 57.003 235.79L-7.99849 235.789V172.062Z" fill="#FFDA54"/>
      <circle cx="57.003" cy="191.181" r="10.1963" fill="#F9B8C4"/>
      <path d="M-7.99854 235.79H81.8565C99.4542 235.79 113.72 250.056 113.72 267.654C113.72 285.251 99.4542 299.517 81.8565 299.517H-7.99854V235.79Z" fill="#0099FF"/>
      <circle cx="81.219" cy="267.654" r="17.2063" fill="#1A49BA"/>
      <circle cx="277.498" cy="223.045" r="12.7454" fill="#F9B8C4"/>
      <circle cx="277.498" cy="223.045" r="7.64724" fill="#E0A6B0"/>
      <rect width="143.386" height="63.727" transform="matrix(-1 0 0 1 264.753 172.063)" fill="#FFDA54"/>
      <circle cx="232.889" cy="203.927" r="31.8635" fill="#FFAD42"/>
      <path d="M129.651 235.79C129.651 231.606 130.476 227.462 132.077 223.597C133.678 219.731 136.025 216.218 138.984 213.259C141.943 210.301 145.455 207.954 149.321 206.352C153.187 204.751 157.33 203.927 161.515 203.927C165.699 203.927 169.843 204.751 173.708 206.352C177.574 207.954 181.087 210.301 184.046 213.259C187.005 216.218 189.352 219.731 190.953 223.597C192.554 227.462 193.378 231.606 193.378 235.79L161.515 235.79L129.651 235.79Z" fill="#FFAD42"/>
      <path d="M-7.99854 0C27.9008 0 57.003 29.1022 57.003 65.0015V177.798C21.1036 177.798 -7.99854 148.696 -7.99854 112.797V0Z" fill="#6E52FF"/>
      <path d="M57.0029 69.4618C57.0029 53.2719 70.1274 40.1475 86.3173 40.1475C102.507 40.1475 115.632 53.272 115.632 69.4619V114.071H57.0029V69.4618Z" fill="#37C99E"/>
      {/* <mask id="mask0_597_7254" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="115" y="44" width="129" height="129">
        <circle cx="179.359" cy="108.334" r="63.727" fill="#E54F52"/>
      </mask> */}
      <g mask="url(#mask0_597_7254)">
        <circle cx="179.359" cy="108.334" r="63.727" fill="#6E52FF"/>
      </g>
      <path d="M261.951 112.158C267.323 123.633 262.376 137.291 250.901 142.663C245.529 131.188 250.476 117.531 261.951 112.158Z" fill="#37C99E"/>
      <path d="M278.688 146.102C273.315 157.577 259.658 162.524 248.183 157.151C253.555 145.676 267.213 140.729 278.688 146.102Z" fill="#37C99E"/>
      <path d="M241.173 267.654C241.173 250.056 255.439 235.79 273.037 235.79H343.136C360.734 235.79 375 250.056 375 267.654C375 285.251 360.734 299.517 343.136 299.517H273.037C255.439 299.517 241.173 285.251 241.173 267.654Z" fill="#37C99E"/>
      <circle cx="344.411" cy="267.654" r="17.2063" fill="#1A49BA"/>
      <rect width="34.4126" height="14.6572" rx="7.3286" transform="matrix(-1 0 0 1 344.411 260.007)" fill="#FFDA54"/>
    </svg>
  )
}

ArrowIcon.propTypes = {
  className: PropTypes.string
}

CloseIcon.propTypes = {
  className: PropTypes.string
}

Illustration.propTypes = {
  className: PropTypes.string
}

NavMobile.propTypes = {
  isOpenNavMobile: PropTypes.bool,
  setIsOpenNavMobile: PropTypes.func
}

export default NavMobile
