import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import Layout from 'layout'
import Home from 'pages/Home'
import StartHiring from 'pages/StartHiring'
import AboutUs from 'pages/AboutUs'
import ContactUs from 'pages/ContactUs'
import Jobs from 'pages/Jobs'
import JobDetail from 'pages/JobDetail'
import ScrollToTop from 'utils/ScrollToTop'

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route path="/" element={<Home/>} />
          <Route path="start-hiring" element={<StartHiring/>}/>
          <Route path="about-us" element={<AboutUs/>}/>
          <Route path="contact-us" element={<ContactUs/>}/>
          <Route path="jobs" element={<Jobs/>}/>
          <Route path="job/:id" element={<JobDetail/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
