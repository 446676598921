import { useMemo } from 'react'

import JobLocation from './JobLocation'
import ContractType from './ContractType'
import BusinessSector from './BusinessSector'

const useFilterJob = (jobs, filterBusinessType, filterJobType, filterJobLocation) => {
  return useMemo(() => {
    const uniqueCity = [...new Set(jobs.map(item => `${item?.city}`))]
    const listJobCity = uniqueCity.map((uniqueItem) => {
      const filterCity = jobs.filter(function (item) {
        if (`${item?.city}` === uniqueItem) {
          return true
        } else {
          return false
        }
      })

      const count = filterCity.length
      return {
        name: uniqueItem === 'null' ? 'Other' : uniqueItem,
        count
      }
    })

    const listJobCitySort = listJobCity.sort((a, b) => b.count - a.count)
    const listJobCityPrimary = listJobCitySort.slice(0, 9)
    const listCityName = listJobCityPrimary.map(item => item.name)

    return jobs
      .filter(job => {
        return (filterBusinessType.length === 0
          ? true
          : filterBusinessType.includes(`${job?.category?.id}`)) &&
        (filterJobType.length === 0
          ? true
          : filterJobType.includes(`${job?.job_type?.id}`)) &&
          (filterJobLocation.length === 0
            ? true
            : filterJobLocation.includes(listCityName.includes(`${job?.city}`) ? `${job?.city}` : 'Other'))
      })
  }, [jobs, filterBusinessType, filterJobType, filterJobLocation])
}

export {
  useFilterJob,
  JobLocation,
  ContractType,
  BusinessSector
}
