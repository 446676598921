import React, { useEffect } from 'react'

import Hero from 'parts/StartHiring/Hero'
import Form from 'parts/StartHiring/Form'
import Advantage from 'parts/StartHiring/Advantage'
import Helmet from 'react-helmet'

const Second = () => {
  useEffect(() => {
    const metaDescription = document.querySelectorAll('[name="description"]')[0]
    metaDescription.content = 'Hiring without the headache – submit your vacancy and find your dream staff member in 30 days.'

    const linkCanonical = document.querySelectorAll('[rel="canonical"]')[0]
    linkCanonical.href = 'https://venditoconsulting.com.au/start-hiring'
  }, [])

  return (
    <>
      <Helmet>
        <title>Start Hiring</title>
        <meta name='description' content='Hiring without the headache – submit your vacancy and find your dream staff member in 30 days.'/>
        <meta name='keywords' content='top talent agencies australia, talent agency melbourne, talent agents jobs,talent agency near me, jobs in melbourne, talent agency, freelance australia, acting agency, how to become a talent agent australia, jobs in brisbane, talent agency australia, freelance in australia, industrial designers melbourne, industrial designers australia, talent agency sydney, talent agent, modeling agency near me, jobs online,model agency, biggest talent agencies in australia'/>
        <link rel='canonical' href='https://venditoconsulting.com.au/start-hiring'/>
      </Helmet>
      <Hero/>
      <Form/>
      <Advantage/>
    </>
  )
}

export default Second
