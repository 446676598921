import React, { useState } from 'react'
import clsx from 'clsx'

import ScaleTeam from './ScaleTeam'
import AccessTalent from './AccessTalent'
import ElevateBusiness from './ElevateBusiness'

const Advantage = () => {
  const [linkIsHover, setLinkIsHover] = useState('')

  return (
    <section id="advantage-start-hiring" className={clsx(
      'flex justify-between gap-y-12',
      'flex-col xl:flex-row',
      'px-6 lg:px-14 xl:px-[120px]',
      'py-12 lg:py-20 xl:py-[104px]',
      'lg:max-w-[1024px] xl:max-w-[1920px]',
      'm-auto left-0 right-0',
      'gap-x-[47px]',
      'overflow-x-hidden'
    )}>
      <div className="max-w-[436px]">
        <h1 className="h3-medium text-neutral-100">Give Your Business the Competitive Advantage</h1>
        <p className="w-full text-l-reguler text-neutral-60 mt-6">Get access to industry leading talent and push your business to new heights.</p>
      </div>

      <div className={clsx(
        'grid min-h-[280px]',
        `
        grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
        lg:grid-cols-[304px_304px_304px] 
        xl:grid-cols-[minmax(239px,350px)_minmax(239px,350px)_minmax(239px,350px)]
        `
      )}>
        <ScaleTeam linkIsHover={linkIsHover} setLinkIsHover={setLinkIsHover} />
        <AccessTalent linkIsHover={linkIsHover} setLinkIsHover={setLinkIsHover}/>
        <ElevateBusiness linkIsHover={linkIsHover} setLinkIsHover={setLinkIsHover}/>
      </div>
    </section>
  )
}

export default Advantage
