import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const HeroImageAnimation = (ref) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(ref.current,
      { scale: 0.25, opacity: 0 },
      { scale: 1, opacity: 1, delay: 0.25, duration: 1.25, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

const IllustrationAnimation = (ref) => {
  // const location = useLocation()
  // const pathname = location.pathname.slice(1)

  useEffect(() => {
    // gsap.fromTo(ref.current,
    //   { scale: 0, opacity: 0 },
    //   { scale: 1, opacity: 1, delay: 0.5, duration: 1.5, ease: 'Power3.easeInOut' }
    // )

    gsap.fromTo(ref.current[1],
      { scale: 0, transformOrigin: 'bottom center' },
      { scale: 1, transformOrigin: 'bottom center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[2],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[3],
      { scale: 0, transformOrigin: 'right center' },
      { scale: 1, transformOrigin: 'right center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[4],
      { scale: 0, transformOrigin: 'left center' },
      { scale: 1, transformOrigin: 'left center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[5],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[6],
      { scale: 0, transformOrigin: 'left center' },
      { scale: 1, transformOrigin: 'left center', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[7],
      { opacity: 0, scale: 0.5, transformOrigin: 'bottom right' },
      { opacity: 1, scale: 1, transformOrigin: 'bottom right', duration: 0.75, delay: 1.25 }
    )
    gsap.fromTo(ref.current[8],
      { scale: 0, transformOrigin: 'top right' },
      { scale: 1, transformOrigin: 'top right', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[9],
      { scale: 0, transformOrigin: 'top left' },
      { scale: 1, transformOrigin: 'top left', duration: 0.75, delay: 0.5 }
    )
    gsap.fromTo(ref.current[10],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 0.75 }
    )
    // gsap.fromTo(ref.current[11],
    //   { scale: 0, transformOrigin: 'bottom center' },
    //   { scale: 0, transformOrigin: 'bottom center', duration: 0.75, delay: 0.5 }
    // )
    gsap.fromTo(ref.current[12],
      { opacity: 0, scale: 0, transformOrigin: 'bottom right' },
      { opacity: 1, scale: 1, transformOrigin: 'bottom right', duration: 0.75, delay: 1.25 }
    )
    gsap.fromTo(ref.current[13],
      { scale: 0, transformOrigin: 'top right' },
      { scale: 1, transformOrigin: 'top right', duration: 0.75, delay: 1.25 }
    )
    // gsap.fromTo(ref.current[14],
    //   { scale: 0, transformOrigin: 'bottom center' },
    //   { scale: 0, transformOrigin: 'bottom center', duration: 0.75, delay: 0.5 }
    // )
    gsap.fromTo(ref.current[15],
      { scale: 0, transformOrigin: 'top right' },
      { scale: 1, transformOrigin: 'top right', duration: 0.75, delay: 1.25 }
    )
    gsap.fromTo(ref.current[16],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 0.75 }
    )
    gsap.fromTo(ref.current[17],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1.25 }
    )
    gsap.fromTo(ref.current[18],
      { scale: 0, transformOrigin: 'center' },
      { scale: 1, transformOrigin: 'center', duration: 0.75, delay: 1.25 }
    )
  }, [])
}

export { HeroImageAnimation, IllustrationAnimation }
