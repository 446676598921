import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const ContainerAnimation = (containerRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(containerRef.current,
      { left: '600px', opacity: 1 },
      { left: '0px', opacity: 1, delay: 0.25, duration: 1.5, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

const SwiperBottomContainerAnimation = (containerRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(containerRef.current,
      { bottom: '-200px', opacity: 1 },
      { bottom: '0px', opacity: 1, delay: 0.5, duration: 1.5, ease: 'Power3.easeInOut' }
    )
    // gsap.fromTo(containerRef.current,
    //   { right: '-600px', opacity: 1 },
    //   { right: '0px', opacity: 1, delay: 0.25, duration: 1.75, ease: 'Power3.easeInOut' }
    // )
  }, [pathname])
}

const ImageSwiperAnimation = (imageContainerRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(imageContainerRef.current,
      { bottom: '-75px', opacity: 1 },
      { bottom: '0px', opacity: 1, delay: 0.25, duration: 1.75, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

export { ContainerAnimation, SwiperBottomContainerAnimation, ImageSwiperAnimation }
