import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const AccessIconAnimation = (circle1Ref, circle2Ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(circle1Ref.current,
      {
        left: '-25px',
        bottom: '-25px',
        opacity: 1
      },
      {
        left: '0px',
        bottom: '0px',
        opacity: 1,
        delay: 2,
        duration: 1,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#advantage-start-hiring',
          start: 'top center'
        }
      }
    )

    gsap.fromTo(circle2Ref.current,
      {
        right: '-25px',
        top: '-25px',
        opacity: 1
      },
      {
        right: '0px',
        top: '0px',
        opacity: 1,
        delay: 2.5,
        duration: 1,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#advantage-start-hiring',
          start: 'top center'
        }
      }
    )
  }, [])
}

export default AccessIconAnimation
