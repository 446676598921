import React from 'react'
import PropTypes from 'prop-types'

const SerchForm = ({ childRef }) => {
  return (
    <form className="grid grid-cols-1 lg:grid-cols-[214px_214px] xl:grid-cols-[240px_240px_130px] mt-10 gap-3">
      <div ref={element => { childRef.current.title = element }} className="">
        <input type="text" className="bg-neutral-20 w-full h-14 rounded-full px-5" placeholder="Job title or keywords"/>
      </div>
      <div ref={element => { childRef.current.location = element }} className="">
        <input type="text" className="bg-neutral-20 w-full h-14 rounded-full px-5" placeholder="Location"/>
      </div>
      <button ref={element => { childRef.current.searchButton = element }} type="button" className="lg:col-span-2 xl:col-span-1 h-14 bg-primary-main flex items-center justify-center gap-x-2 button-l-medium text-neutral-10 rounded-full">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M22 22L20 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
          Search
      </button>
    </form>
  )
}

SerchForm.propTypes = {
  childRef: PropTypes.node
}

export default SerchForm
