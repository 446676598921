import React from 'react'
import PropTypes from 'prop-types'

import Next from './Next'
import Previous from './Previous'
import { usePagination, DOTS } from 'utils/usePagination'
import clsx from 'clsx'

const Pagination = ({
  onPageChange,
  totalCount,
  totalPages,
  siblingCount = 0,
  currentPage,
  pageSize,
  className
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    currentPage < totalPages && onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    currentPage > 1 && onPageChange(currentPage - 1)
  }

  // const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className="flex items-center">
      <Previous onClick={onPrevious}/>
      <div className="flex gap-x-[18px] lg:gap-x-2 mx-4 lg:mx-6">
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <button className="h-12 w-12 bg-neutral-10 rounded-full button-l-bold text-neutral-60">...</button>
          }

          return (
            <button
              key={index}
              className={clsx(
                'h-12 w-12 rounded-full button-l-bold text-neutral-10',
                {
                  'bg-primary-main text-neutral-10': pageNumber === currentPage,
                  'bg-neutral-10 text-neutral-60': pageNumber !== currentPage
                }
              )}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          )
        })}
      </div>
      <Next onClick={onNext}/>
    </div>
  )
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string
}

export default Pagination
