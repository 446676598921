import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const LogoAnimation = (logoVenditoRef, textRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(logoVenditoRef.current,
      { rotation: '0', duration: 0 },
      { rotation: '360', duration: 0.75 }
    )

    gsap.fromTo(textRef.current,
      { opacity: '0', delay: 0 },
      { opacity: '1', delay: 0.75 }
    )
  }, [pathname])
}

const ButtonAnimation = (buttonRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(buttonRef.current,
      { top: '-15px', opacity: 0, duration: 0 },
      { top: '0px', opacity: 1, delay: 0.75, duration: 1, ease: 'Power3.easeInOut' }
    )
  }, [pathname])
}

const NavigationAnimation = (navRef) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(navRef.current,
      { top: '-20px', opacity: 0 },
      { top: '0px', opacity: 1, delay: 0.8 }
    )
  }, [pathname])
}

export { ButtonAnimation, LogoAnimation, NavigationAnimation }
