import PropTypes from 'prop-types'
import { Toaster } from 'react-hot-toast'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react'

import FormApply from './FormApply'

const ApplyJobModal = forwardRef(({ jobId, childRef }) => {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(childRef, () => ({
    openModal () {
      openModal()
    }
  }))

  function closeModal () {
    setIsOpen(false)
  }

  function openModal () {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md overflow-hidden my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl divide-y-[1px] divide-neutral-30">
                <div className="m-6 flex justify-between items-start">
                  <div>
                    <Dialog.Title
                      as="h5"
                      className="h5-bold text-neutral-100"
                    >
                      Apply Job
                    </Dialog.Title>

                    <Dialog.Description className="text-s-reguler text-neutral-60 mt-1">
                      Please complete the form below for a position with us
                    </Dialog.Description>
                  </div>

                  <button onClick={() => setIsOpen(false)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#111827"/>
                    </svg>
                  </button>
                </div>

                {/* Form */}
                <FormApply jobId={jobId} closeModal={closeModal}/>

              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Toaster />
    </>
  )
})

ApplyJobModal.displayName = 'Apply Job Modal'

ApplyJobModal.propTypes = {
  jobId: PropTypes.number,
  childRef: PropTypes.node
}

export default ApplyJobModal
