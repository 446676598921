import React from 'react'
import { Link } from 'react-router-dom'

const BackToJob = () => {
  return (
    <div className="relative z-[1]">
      <Link to="/jobs" className="flex gap-x-3 items-center text-s-medium text-neutral-10">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.99924 13.78L5.65258 9.4333C5.13924 8.91997 5.13924 8.07997 5.65258 7.56664L9.99924 3.21997" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
            Back to job search
      </Link>
    </div>
  )
}

export default BackToJob
