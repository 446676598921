import React from 'react'
import PropTypes from 'prop-types'

const Description = ({ text }) => {
  return (
    <div className="py-6 border-b border-b-neutral-30">
      <div className="job-description job-card text-m-medium text-neutral-60" dangerouslySetInnerHTML={{ __html: text }}>
        {/* {text} */}
      </div>
    </div>
  )
}

Description.propTypes = {
  text: PropTypes.string
}

export default Description
