import React from 'react'
import PropTypes from 'prop-types'

const Qualified = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#F9B8C4"/>
      <path opacity="0.4" d="M18.7867 10.4331H13.1333C11.4867 10.4331 10.1533 11.7731 10.1533 13.4131V19.6864C10.1533 21.3264 11.4933 22.6664 13.1333 22.6664H18.78C20.4267 22.6664 21.76 21.3264 21.76 19.6864V13.4131C21.7667 11.7664 20.4267 10.4331 18.7867 10.4331Z" fill="white"/>
      <path d="M17.5261 9.33325H14.3928C13.6995 9.33325 13.1328 9.89325 13.1328 10.5866V11.2133C13.1328 11.9066 13.6928 12.4666 14.3861 12.4666H17.5261C18.2195 12.4666 18.7795 11.9066 18.7795 11.2133V10.5866C18.7861 9.89325 18.2195 9.33325 17.5261 9.33325Z" fill="white"/>
      <path d="M15.1673 19.2998C15.0406 19.2998 14.9139 19.2531 14.8139 19.1531L13.8139 18.1531C13.6206 17.9598 13.6206 17.6398 13.8139 17.4464C14.0073 17.2531 14.3273 17.2531 14.5206 17.4464L15.1673 18.0931L17.4806 15.7798C17.6739 15.5864 17.9939 15.5864 18.1873 15.7798C18.3806 15.9731 18.3806 16.2931 18.1873 16.4864L15.5206 19.1531C15.4273 19.2531 15.2939 19.2998 15.1673 19.2998Z" fill="white"/>
    </svg>

  )
}

Qualified.propTypes = {
  className: PropTypes.string
}

export default Qualified
