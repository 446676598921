import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

import '@react-pdf-viewer/core/lib/styles/index.css'

import InputGroup from './InputGroup'
import InputResume from './InputResume'
import InputPhone from './InputPhone'

import { clientRoot } from 'api'

const FormApply = ({ jobId, closeModal }) => {
  const [formApply, setFormAppy] = useState(
    {
      email: '',
      name: '',
      phone: '',
      linkedin: ''
    }
  )

  const handleChangeForm = (event) => {
    setFormAppy({
      ...formApply,
      [event.target.name]: event.target.value
    })
  }

  const [selectedFile, setSelectedFile] = React.useState(null)

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('email', formApply.email)
    formData.append('name', formApply.name)
    formData.append('phone', formApply.phone)
    formData.append('linkedin', formApply.linkedin)
    formData.append('resume', selectedFile)

    clientRoot.post(`jobs/${jobId}/apply`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      toast.success('Success')
    }).catch(() => {
      toast.error('Failed')
    })

    closeModal()
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="p-6 space-y-[14px]">
          <InputGroup
            name="email"
            placeholder="Email Address"
            type="email"
            label="Email"
            value={formApply.email}
            onChange={handleChangeForm}
            isRequired={true}
          />

          <InputGroup
            name="name"
            placeholder="Full name"
            type="text"
            label="Name"
            value={formApply.name}
            onChange={handleChangeForm}
            isRequired={true}
          />

          <InputPhone value={formApply.phone} onChange={handleChangeForm}/>

          <InputGroup
            name="linkedin"
            placeholder="LinkedIn profile URL"
            type="text"
            label="LinkedIn"
            value={formApply.linkedin}
            onChange={handleChangeForm}
          />

          <InputResume setSelectedFile={setSelectedFile}/>
        </div>

        <div className="border-t border-t-neutral-30 p-6">
          <button
            type="submit"
            className={clsx(
              'inline-flex justify-center px-4 py-3 text-neutral-10 bg-primary-main rounded-md hover:bg-primary-hover duration-300',
              'w-full',
              'button-l-medium'
            )}
          >
          Submit
          </button>
        </div>
      </form>
    </>
  )
}

FormApply.propTypes = {
  jobId: PropTypes.number,
  closeModal: PropTypes.func
}

export default FormApply
