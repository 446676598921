import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const TitleAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        top: '25%',
        opacity: 0
      },
      {
        top: '0%',
        opacity: 1,
        delay: 1.5,
        duration: 1.5,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#container-step-form',
          start: '-=650'
          // start: 'top center'
        }
      }
    )
  }, [])
}

const ListAnimation = (ref, delayAnimation) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        left: '25%',
        opacity: 0
      },
      {
        left: '0%',
        opacity: 1,
        delay: delayAnimation,
        duration: 1.5,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#container-step-form',
          start: '-=650'
          // start: 'top center'
        }
      }
    )
  }, [])
}

export { TitleAnimation, ListAnimation }
