import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const RectangleAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        opacity: 0,
        x: -100
      },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        delay: 1,
        ease: 'Power3.easeOut',
        scrollTrigger: {
          trigger: ref.current
        }
      }
    )
  }, [])
}

const EllipseAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        opacity: 0,
        scale: 0
      },
      {
        opacity: 1,
        scale: 1,
        transformOrigin: 'bottom right',
        duration: 1,
        delay: 1.5,
        ease: 'Power3.easeOut',
        scrollTrigger: {
          trigger: ref.current
        }
      }
    )
  }, [])
}

export { RectangleAnimation, EllipseAnimation }
