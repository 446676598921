import React from 'react'

import 'styles/App.scss'
import Router from './Router'

function App () {
  return (
    <div className="App">
      <Router/>
    </div>
  )
}

export default App
