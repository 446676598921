import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import ExperienceImage from 'assets/about-us/experience/experience-image.png'

import clsx from 'clsx'
import { EllipseAnimation, RectangleAnimation } from 'animation/about-us/Experience/IllustrationAnimation'
import ImageAnimation from 'animation/about-us/Experience/ImageAnimation'
import TextAnimation from 'animation/about-us/Experience/TextAnimation'

const Experience = () => {
  const imageRef = useRef()
  ImageAnimation(imageRef)

  const titleRef = useRef()
  const descriptionRef = useRef()
  TextAnimation(titleRef, descriptionRef)

  return (
    <section className={clsx(
      'flex items-center',
      'xl:pt-[120px] xl:pb-[128px]',
      'flex-col-reverse lg:flex-row',
      'px-6 lg:px-14 xl:px-[120px]',
      'py-12 lg:py-20',
      'gap-y-16 gap-x-[111px] xl:gap-[119px]',
      'lg:max-w-[1024px] xl:max-w-[1440px]',
      'm-auto left-0 right-0',
      'overflow-x-hidden'
    )}>
      <div className="relative lg:w-[332px] xl:w-[561px]">
        <img ref={imageRef} className="relative z-[1]" src={ExperienceImage} alt=""/>

        <Rectangle className=""/>
        <Ellipse className=""/>
      </div>

      <div id="container-text-experience" className="lg:w-[412px] xl:-mt-14">
        <h1 ref={titleRef} className="h2-medium text-neutral-100">Experience Awesome at work</h1>
        <p ref={descriptionRef} className="text-l-reguler text-neutral-60 mt-4">As a Vendito team member, you’re part of a close-knit group transforming the world of work.</p>
      </div>
    </section>
  )
}

const Rectangle = ({ className }) => {
  const rectRef = useRef()
  RectangleAnimation(rectRef)

  return (
    <svg
      ref={rectRef}
      className="absolute top-[-24px] left-[-24px]"
      viewBox="0 0 608 421" fill="none" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="false"
    >
      <rect className="" width="527" height="334" rx="8" fill="#FFDA54"/>
    </svg>
  )
}

const Ellipse = ({ className }) => {
  const ellipseRef = useRef()
  EllipseAnimation(ellipseRef)

  return (
    <svg
      ref={ellipseRef}
      className="absolute z-[2] bottom-[-36px] right-[-30px]"
      viewBox="0 0 608 421" fill="none" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="false"
    >
      <path className="" d="M491.356 410.009C492.716 396.088 496.805 382.571 503.389 370.23C509.972 357.888 518.923 346.965 529.728 338.083C540.534 329.2 552.983 322.533 566.365 318.462C579.747 314.391 593.8 312.996 607.721 314.356L597.365 420.365L491.356 410.009Z" fill="#0099FF" />
    </svg>
  )
}

Rectangle.propTypes = {
  className: PropTypes.string
}

Ellipse.propTypes = {
  className: PropTypes.string
}

export default Experience
