import clsx from 'clsx'
import React from 'react'

const JobCardSkeleton = () => {
  return (
    <div className={clsx(
      'animate-pulse',
      'p-6',
      'w-full',
      'h-fit',
      'bg-neutral-10',
      'rounded-2xl'
    )}>
      <div className={clsx(
        'w-full',
        'h-9',
        'max-w-[230px]',
        'bg-[#EDF1F6]',
        'rounded-lg'
      )}/>
      <div className={clsx(
        'flex gap-x-4 mt-3'
      )}>
        <div className={clsx(
          'w-full',
          'h-6',
          'max-w-[186px]',
          'bg-[#EDF1F6]',
          'rounded-lg'
        )}/>
        <div className={clsx(
          'w-full',
          'h-6',
          'max-w-[87px]',
          'bg-[#EDF1F6]',
          'rounded-lg'
        )}/>
      </div>

      <div className={clsx(
        'mt-6',
        'w-full',
        'h-[72px]',
        'bg-[#EDF1F6]',
        'rounded-lg'
      )}/>

      <svg className="my-6" width="741" height="1" viewBox="0 0 741 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="0.5" x2="740.5" y2="0.5" stroke="#EDF1F7" strokeLinecap="round"/>
      </svg>

      <div className={clsx(
        'flex justify-between items-center'
      )}>
        <div className={clsx(
          'flex gap-x-6 w-full'
        )}>
          <div className={clsx(
            'w-full',
            'h-12',
            'max-w-[127px]',
            'bg-[#EDF1F6]',
            'rounded-full'
          )}/>
          <div className={clsx(
            'w-full',
            'h-12',
            'max-w-[127px]',
            'bg-[#EDF1F6]',
            'rounded-full'
          )}/>
        </div>

        <div className={clsx(
          'w-full',
          'h-[22px]',
          'max-w-[104px]',
          'bg-[#EDF1F6]',
          'rounded-lg'
        )}/>
      </div>

    </div>
  )
}

export default JobCardSkeleton
