import Hero from 'parts/Jobs/Hero'
import Job from 'parts/Jobs/Job'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const Jobs = () => {
  useEffect(() => {
    const metaDescription = document.querySelectorAll('[name="description"]')[0]
    metaDescription.content = 'Exclusive career opportunities only accessible through Vendito. Discover where we can take your career.'

    const linkCanonical = document.querySelectorAll('[rel="canonical"]')[0]
    linkCanonical.href = 'https://venditoconsulting.com.au/jobs'
  }, [])

  return (
    <>
      <Helmet>
        <title>Job Search</title>
        <meta name='description' content='Exclusive career opportunities only accessible through Vendito. Discover where we can take your career.'/>
        <meta name='keywords' content='top talent agencies australia, talent agency melbourne, talent agents jobs,talent agency near me, jobs in melbourne, talent agency, freelance australia, acting agency, how to become a talent agent australia, jobs in brisbane, talent agency australia, freelance in australia, industrial designers melbourne, industrial designers australia, talent agency sydney, talent agent, modeling agency near me, jobs online,model agency, biggest talent agencies in australia'/>
        <link rel='canonical' href='https://venditoconsulting.com.au/jobs'/>
      </Helmet>
      <Hero/>
      <Job/>
    </>
  )
}

export default Jobs
