import React, { forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'

import People1 from './people-1.png'
import People2 from './people-2.png'
import IllustrationAnimation from 'animation/job/IllustrationAnimation'

const Illustration = forwardRef(({ className, refChild }) => {
  const ref = useRef([])

  IllustrationAnimation(ref)

  return (
    <svg ref={refChild} className={className} width="601" height="457" viewBox="0 0 601 457" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <path ref={element => { ref.current[1] = element }} d="M499 407C499 393.868 501.638 380.864 506.764 368.732C511.89 356.599 519.404 345.575 528.875 336.289C538.347 327.003 549.591 319.637 561.966 314.612C574.342 309.587 587.605 307 601 307L601 407L499 407Z" fill="#0099FF" />

      <g>
        <defs>
          <mask id="mask-2">
            <rect ref={element => { ref.current[2] = element }} className="translate-x-[310px] translate-y-[457px]" width="100" height="100" fill="#fff" />
          </mask>
        </defs>
        <g mask="url(#mask-2)">
          <path className="" d="M410 457C410 429.386 387.614 407 360 407V407C332.386 407 310 429.386 310 457V457C310 484.614 332.386 507 360 507H410V457Z" fill="#0099FF"/>
        </g>

        <path ref={element => { ref.current[3] = element }} d="M601 407H460C432.386 407 410 429.386 410 457V457C410 484.614 432.386 507 460 507H601V407Z" fill="#FFDA54"/>
        <circle ref={element => { ref.current[4] = element }} r="27" transform="matrix(-1 0 0 1 461 457)" fill="#1A49BA"/>
        <rect ref={element => { ref.current[5] = element }} width="100" height="100" transform="matrix(-1 0 0 1 310 407)" fill="#6E52FF"/>
        <g ref={element => { ref.current[6] = element }}>
          <path d="M210 457C210 429.386 187.614 407 160 407H50C22.3858 407 0 429.386 0 457V457C0 484.614 22.3858 507 50 507H160C187.614 507 210 484.614 210 457V457Z" fill="#37C99E"/>
          <circle r="27" transform="matrix(-1 0 0 1 48 457)" fill="white"/>
          <rect x="48" y="445" width="54" height="23" rx="11.5" fill="#FFDA54"/>
        </g>
      </g>

      <rect ref={element => { ref.current[7] = element }} x="397" y="367" width="102" height="40" fill="#6E52FF"/>
      <g>
        <path ref={element => { ref.current[8] = element }} d="M172 307H297C352.228 307 397 351.772 397 407V407H172V307Z" fill="#FFDA54"/>
        <path ref={element => { ref.current[9] = element }} d="M382 407C382 400.434 380.707 393.932 378.194 387.866C375.681 381.8 371.998 376.288 367.355 371.645C362.712 367.002 357.2 363.319 351.134 360.806C345.068 358.293 338.566 357 332 357C325.434 357 318.932 358.293 312.866 360.806C306.8 363.319 301.288 367.002 296.645 371.645C292.002 376.288 288.319 381.8 285.806 387.866C283.293 393.932 282 400.434 282 407L332 407L382 407Z" fill="#FFAD42"/>
      </g>

      <g>
        <circle ref={element => { ref.current[10] = element }} cx="152" cy="387" r="20" fill="#6E52FF"/>
        <circle ref={element => { ref.current[11] = element }} cx="152" cy="387" r="12" fill="#8870FF"/>
      </g>

      <circle ref={element => { ref.current[12] = element }} cx="499" cy="333" r="16" fill="#F9B8C4"/>

      <path ref={element => { ref.current[13] = element }} d="M416 334.416C417.676 323.141 428.176 315.359 439.451 317.036V317.036V317.036C437.775 328.311 427.275 336.093 416 334.416V334.416V334.416Z" fill="#FFDA54"/>

      <g>
        <path ref={element => { ref.current[14] = element }} d="M499 257C499 229.386 476.614 207 449 207V207C421.386 207 399 229.386 399 257V257C399 284.614 421.386 307 449 307H499V257Z" fill="#FFDA54"/>
        <path ref={element => { ref.current[15] = element }} d="M471 258.5C471 246.626 461.374 237 449.5 237V237C437.626 237 428 246.626 428 258.5V258.5C428 270.374 437.626 280 449.5 280H471V258.5Z" fill="#FFAD42"/>
      </g>

      <g ref={element => { ref.current[16] = element }}>
        <path d="M601 37V37C544.667 37 499 82.667 499 139V307H601V37Z" fill="#37C99E"/>
        <rect width="49" height="49" transform="matrix(-1 0 0 1 561 244)" fill="#32B58E"/>
      </g>

      <rect ref={element => { ref.current[17] = element }} x="407" y="78" width="92" height="129" fill="#6E52FF"/>

      <g>
        <path ref={element => { ref.current[18] = element }} d="M166 203C166 219.569 179.431 233 196 233V233V233C196 216.431 182.569 203 166 203V203V203Z" fill="#32B58E"/>
        <path ref={element => { ref.current[19] = element }} d="M174 267C174 250.431 187.431 237 204 237V237V237C204 253.569 190.569 267 174 267V267V267Z" fill="#32B58E"/>
      </g>

      <image ref={element => { ref.current[20] = element }} href={People1} x="207" y="107" width="200" height="200" />
      <image ref={element => { ref.current[21] = element }} href={People2} x="431" y="0" width="123" height="123" />
    </svg>
  )
})

Illustration.displayName = 'Illustration'

Illustration.propTypes = {
  refChild: PropTypes.node,
  className: PropTypes.string
}

export default Illustration
