import React, { useEffect } from 'react'

import Hero from 'parts/ContactUs/Hero'
import Maps from 'parts/ContactUs/Maps'
import Form from 'parts/ContactUs/Form'
import Helmet from 'react-helmet'

const ContactUs = () => {
  useEffect(() => {
    const metaDescription = document.querySelectorAll('[name="description"]')[0]
    metaDescription.content = 'Exclusive career opportunities only accessible through Vendito. Discover where we can take your career.'

    const linkCanonical = document.querySelectorAll('[rel="canonical"]')[0]
    linkCanonical.href = 'https://venditoconsulting.com.au/contact-us'
  }, [])

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='description' content='Exclusive career opportunities only accessible through Vendito. Discover where we can take your career.'/>
        <meta name='keywords' content='top talent agencies australia, talent agency melbourne, talent agents jobs,talent agency near me, jobs in melbourne, talent agency, freelance australia, acting agency, how to become a talent agent australia, jobs in brisbane, talent agency australia, freelance in australia, industrial designers melbourne, industrial designers australia, talent agency sydney, talent agent, modeling agency near me, jobs online,model agency, biggest talent agencies in australia'/>
        <link rel='canonical' href='https://venditoconsulting.com.au/contact-us'/>
      </Helmet>
      <Hero/>
      <Maps/>
      <Form/>
    </>
  )
}

export default ContactUs
