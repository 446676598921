import gsap from 'gsap'
import { useEffect } from 'react'

const TextAnimation = (titleRef, descriptionRef) => {
  const tl1 = gsap.timeline()
  useEffect(() => {
    tl1.fromTo(titleRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 0.75, ease: 'Power3.easeOut' }
    )
    tl1.fromTo(descriptionRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 0.6, ease: 'Power3.easeOut' }
    )
  })
}

export default TextAnimation
