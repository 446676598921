import gsap from "gsap";
import { useEffect } from "react";
import { useLocation } from "react-router";

const HeroLeftAnimation = (headerRef, descriptionRef, formRef) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  useEffect(() => {
    gsap.fromTo(
      headerRef.current,
      { bottom: "-50px", opacity: 0 },
      {
        bottom: "0px",
        opacity: 1,
        delay: 0.5,
        duration: 1,
        ease: "Power3.easeInOut",
      }
    );

    gsap.fromTo(
      descriptionRef.current,
      { bottom: "-40px", opacity: 0 },
      {
        bottom: "0px",
        opacity: 1,
        delay: 1.25,
        duration: 1.25,
        ease: "Power3.easeInOut",
      }
    );

    gsap.fromTo(
      formRef.current,
      { bottom: "-30px", opacity: 0 },
      {
        bottom: "0px",
        opacity: 1,
        delay: 1.5,
        duration: 1,
        ease: "Power3.easeInOut",
      }
    );
  }, [pathname]);
};

const ElipseAnimation = (elipseRef) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  useEffect(() => {
    gsap.fromTo(
      elipseRef.current,
      { zoom: 0, opacity: 0 },
      {
        zoom: 1,
        opacity: 1,
        delay: 0.75,
        duration: 1.75,
        ease: "Power3.easeInOut",
      }
    );
  }, [pathname]);
};

export { HeroLeftAnimation, ElipseAnimation };
