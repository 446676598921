import React from 'react'
import PropTypes from 'prop-types'

import Vacancy from './Vacancy'
import Network from './Network'
import Reliability from './Reliability'

const Sticker = ({ arrTeam, activeIndex }) => {
  const { rating } = arrTeam[activeIndex]
  const { vacancy, network } = rating

  return (
    <div className="hidden lg:block absolute top-[153px] z-10">
      <Vacancy value={vacancy} activeIndex={activeIndex}/>
      <Network value={network} activeIndex={activeIndex}/>
      <Reliability activeIndex={activeIndex}/>
    </div>
  )
}

Sticker.propTypes = {
  arrTeam: PropTypes.array,
  activeIndex: PropTypes.number
}

export default Sticker
