import { LogoAnimation } from "animation/header";
import { Link, useLocation } from "react-router-dom";
import React, { useRef } from "react";

import useResponsive from "utils/useResponsive";

const CheckLogo = (md, pathname) => {
  if (pathname === "") {
    return md ? "#1E2432" : "white";
  } else if (pathname === "about-us") {
    return "white";
  } else if (pathname.includes("job/")) {
    return "white";
  } else {
    return "#1E2432";
  }
};

const Logo = () => {
  const { md } = useResponsive();
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  // store a reference to the box div
  const logoVenditoRef = useRef();
  const textRef = useRef();

  // wait until DOM has been rendered
  LogoAnimation(logoVenditoRef, textRef);

  return (
    <Link to="/" className="flex items-center gap-x-[6px]" aria-label="Home">
      <svg
        ref={logoVenditoRef}
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9431 0C7.57458 0 0 7.6 0 17C0 19.1 0.398662 21 0.996655 22.8C1.19599 23.3 1.39532 23.8 1.59465 24.3C2.09298 25.3 2.5913 26.2 3.28896 27.1C4.28562 28.4 5.38194 29.6 6.77726 30.6C7.47492 31.1 8.07291 31.5 8.87023 31.9C9.56789 32.3 10.3652 32.6 11.1625 32.9C11.6609 33.1 12.2589 33.3 12.7572 33.4C13.2555 33.5 13.8535 33.7 14.4515 33.7C15.2488 34 16.0462 34 16.9431 34C17.2421 34 17.5411 34 17.8401 34C18.1391 34 18.4381 34 18.7371 33.9C19.8334 33.8 21.0294 33.6 22.0261 33.2C23.1224 32.9 24.1191 32.4 25.1157 31.9C30.398 29 33.986 23.4 33.986 16.9C33.8863 7.6 26.3117 0 16.9431 0ZM27.408 6.5L16.9431 17L6.47826 6.5C9.16923 3.8 12.8569 2.2 16.9431 2.2C21.0294 2.2 24.7171 3.8 27.408 6.5Z"
          fill={CheckLogo(md, pathname)}
        />
      </svg>

      <svg
        className="opacity-0"
        ref={textRef}
        width="109"
        height="16"
        viewBox="0 0 109 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2212 1.1001L7.6399 14.9001H5.84592L0.264648 1.1001H1.95896L6.74291 13.1001L11.5269 1.1001H13.2212Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M20.2978 2.6001V7.2001H27.8724V8.8001H20.2978V13.4001H28.7693V14.9001H18.7031V1.1001H28.7693V2.6001H20.2978Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M46.9085 1.1001V14.9001H45.1146L36.643 3.3001V14.9001H35.0483V1.1001H36.8423L45.4136 12.7001V1.1001H46.9085Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M59.8651 1.1001C60.6624 1.1001 61.4597 1.3001 62.257 1.6001C63.0544 1.9001 63.6524 2.4001 64.2504 3.0001C65.546 4.3001 66.144 6.0001 66.144 8.1001C66.144 10.2001 65.546 11.8001 64.2504 13.2001C63.6524 13.8001 62.9547 14.3001 62.257 14.6001C61.4597 14.9001 60.6624 15.1001 59.8651 15.1001H54.0845V1.1001H59.8651ZM59.7654 13.4001C61.1607 13.4001 62.3567 12.9001 63.2537 11.8001C64.1507 10.8001 64.5494 9.5001 64.5494 8.0001C64.5494 6.5001 64.1507 5.2001 63.2537 4.2001C62.3567 3.1001 61.1607 2.6001 59.7654 2.6001H55.5795V13.4001H59.7654Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M74.0175 1.1001H72.4229V14.9001H74.0175V1.1001Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M90.8608 2.6001H85.9772V14.9001H84.4822V2.6001H79.6982V1.1001H90.8608V2.6001Z"
          fill={CheckLogo(md, pathname)}
        />
        <path
          d="M102.023 0.899902C104.116 0.899902 105.711 1.5999 107.106 2.9999C108.402 4.2999 109 5.9999 109 7.9999C109 9.9999 108.402 11.6999 107.106 12.9999C105.811 14.3999 104.116 15.0999 102.023 15.0999C99.9305 15.0999 98.3358 14.3999 96.9405 12.9999C95.6449 11.6999 95.0469 9.9999 95.0469 7.9999C95.0469 5.9999 95.6449 4.2999 96.9405 2.9999C98.3358 1.5999 100.03 0.899902 102.023 0.899902ZM102.023 13.5999C103.618 13.5999 105.013 13.0999 106.01 11.9999C107.007 10.9999 107.505 9.6999 107.505 8.0999C107.505 6.4999 107.007 5.1999 106.01 4.1999C105.013 3.0999 103.718 2.5999 102.023 2.5999C100.329 2.5999 98.9358 3.0999 97.9388 4.1999C96.9418 5.1999 96.4437 6.4999 96.4437 8.0999C96.4437 9.6999 96.9418 10.9999 97.9388 11.9999C98.9358 13.0999 100.329 13.5999 102.023 13.5999Z"
          fill={CheckLogo(md, pathname)}
        />
      </svg>
    </Link>
  );
};

export default Logo;
