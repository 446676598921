import clsx from 'clsx'
import React, { useRef } from 'react'

import SubmitBrief from 'assets/start-hiring/form/SubmitBrief'
import Recruiter from 'assets/start-hiring/form/Recruiter'
import Qualified from 'assets/start-hiring/form/Qualified'
import Interview from 'assets/start-hiring/form/Interview'
import Hire from 'assets/start-hiring/form/Hire'
import { TitleAnimation } from 'animation/start-hiring/form/StepAnimation'
import ListStep from './ListStep'

const Step = () => {
  const titleRef = useRef()
  TitleAnimation(titleRef)

  return (
    <div id="container-step-form" className={clsx(
      'lg:ml-[64px] xl:ml-[166px]',
      'lg:mt-[176px] xl:mt-[102px]',
      'px-6 lg:px-0',
      'pt-12 lg:pt-0',
      'pb-20 lg:pb-0',
      'sm:w-[380px]'
    )}>
      <h1 ref={titleRef} className="relative h2-medium lg:display2-medium text-neutral-100">Submit Your Job Vacancy</h1>

      <ul className="flex flex-col gap-y-6 mt-10 text-m-medium text-neutral-100">
        <ListStep listRef={useRef()} delayAnimation={2.2} text="Now - Submit your brief" logo={SubmitBrief} bg="bg-secondary-orange-surface" />
        <ListStep listRef={useRef()} delayAnimation={2.4} text="48 Hours - Recruiter calls you" logo={Recruiter} bg="bg-secondary-blue-surface" />
        <ListStep listRef={useRef()} delayAnimation={2.6} text="7 Days - Qualified candidates" logo={Qualified} bg="bg-secondary-pink-surface" />
        <ListStep listRef={useRef()} delayAnimation={2.8} text="14 Days - Interviews Completed" logo={Interview} bg="bg-[#F2F0FF]" />
        <ListStep listRef={useRef()} delayAnimation={3} text="30 Days - Your new hire starts!" logo={Hire} bg="bg-success-surface" />
      </ul>
    </div>
  )
}

export default Step
