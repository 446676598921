import React from 'react'
import PropTypes from 'prop-types'

const Hire = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#37C99E"/>
      <path d="M19.9596 16.6667C19.333 16.6667 18.753 16.8868 18.293 17.2534C17.6796 17.7401 17.293 18.4934 17.293 19.3334C17.293 19.8334 17.433 20.3067 17.6796 20.7067C18.1396 21.4801 18.9863 22.0001 19.9596 22.0001C20.633 22.0001 21.2463 21.7534 21.713 21.3334C21.9196 21.1601 22.0996 20.9467 22.2396 20.7067C22.4863 20.3067 22.6263 19.8334 22.6263 19.3334C22.6263 17.8601 21.433 16.6667 19.9596 16.6667ZM21.3396 19.0468L19.9196 20.3601C19.8263 20.4467 19.6996 20.4934 19.5796 20.4934C19.453 20.4934 19.3263 20.4468 19.2263 20.3468L18.5663 19.6868C18.373 19.4934 18.373 19.1734 18.5663 18.9801C18.7596 18.7867 19.0796 18.7867 19.273 18.9801L19.593 19.3001L20.6596 18.3134C20.8596 18.1267 21.1796 18.1401 21.3663 18.3401C21.553 18.5401 21.5396 18.8534 21.3396 19.0468Z" fill="white"/>
      <path opacity="0.4" d="M22.0204 22.3333C22.0204 22.5199 21.8737 22.6666 21.6871 22.6666H10.2337C10.0471 22.6666 9.90039 22.5199 9.90039 22.3333C9.90039 19.5733 12.6204 17.3333 15.9604 17.3333C16.6471 17.3333 17.3137 17.4266 17.9271 17.6066C17.5337 18.0732 17.2937 18.6799 17.2937 19.3333C17.2937 19.8333 17.4337 20.3066 17.6804 20.7066C17.8137 20.9333 17.9871 21.1399 18.1871 21.3132C18.6537 21.7399 19.2737 21.9999 19.9604 21.9999C20.7071 21.9999 21.3804 21.6932 21.8604 21.1999C21.9671 21.5599 22.0204 21.9399 22.0204 22.3333Z" fill="white"/>
      <path d="M15.9603 15.9999C17.8012 15.9999 19.2936 14.5075 19.2936 12.6666C19.2936 10.8256 17.8012 9.33325 15.9603 9.33325C14.1193 9.33325 12.627 10.8256 12.627 12.6666C12.627 14.5075 14.1193 15.9999 15.9603 15.9999Z" fill="white"/>
    </svg>

  )
}

Hire.propTypes = {
  className: PropTypes.string
}

export default Hire
