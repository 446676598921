import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Maps = () => {
  const [location, setLocation] = useState({
    address: 'Brisbane',
    lat: -27.469735933328217,
    lng: 153.02603155039407
  }) // our location object from earlier

  return (
    <section id="" className={clsx(
      'pb-14 pt-6 px-6 lg:p-14 xl:pt-24 xl:pb-28 xl:px-[191px] relative',
      'max-w-[1024px] xl:max-w-[1440px]',
      'm-auto left-0 right-0',
      'overflow-x-hidden'
    )}>
      <Background className="absolute top-0 left-0" />

      <div className="relative">
        <div className={clsx(
          'w-[912px] h-[723px] xl:w-[985px] xl:h-[781px]',
          'bg-neutral-100 bg-opacity-[0.08] absolute',
          'blur-[138px] xl:blur-[150px]',
          'top-[34px] xl:top-20',
          'xl:right-9'
        )}></div>

        <div className="relative z-[1] bg-neutral-10 rounded-2xl pt-3 px-3 pb-[14px] lg:pt-[43px] lg:px-[40px] lg:pb-[40px]">
          <div className="flex items-center justify-center gap-x-1 lg:gap-x-2 w-fit m-auto left-0 right-0">
            <PhoneIcon className="w-[6px] h-[6px] lg:w-5 lg:h-5"/>
            <p className="text-[4px] font-medium lg:text-m-medium text-neutral-50">Phone number</p>
          </div>
          <div className="mt-[2px] lg:mt-2 w-fit m-auto left-0 right-0">
            <h1 className="text-[20px] font-medium lg:display2-medium text-neutral-100 text-center">1300 323 491</h1>
            <p className="text-[5px] font-medium lg:h5-medium mt-[2px] lg:mt-2 text-center">admin@venditoconsulting.com.au</p>
          </div>

          <div className={clsx(
            'grid mt-3 h-[18px] mb-[10px] lg:mt-10 lg:h-14 lg:mb-6',
            `grid-cols-3
            lg:grid-cols-[minmax(281px)_minmax(281px)_minmax(281px)]
            xl:grid-cols-[minmax(0px,326px)_minmax(0px,326px)_minmax(0px,326px)]`
          )}>
            <div
              className={clsx(
                'flex items-center justify-center gap-x-2 hover:border-b-2 hover:border-b-[#FFAD42] cursor-pointer text-[5px] lg:text-[13px] xl:text-base',
                location.address === 'Brisbane' && 'border-b-2 border-b-[#FFAD42]'
              )}
              onClick={() => setLocation(
                {
                  address: 'Brisbane',
                  lat: -27.469735933328217,
                  lng: 153.02603155039407
                }
              )}
            >
              <svg className="w-[6px] h-[6px] lg:w-6 lg:h-6" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 10.3178C4 5.71789 7.84388 2 12.4934 2C17.1561 2 21 5.71789 21 10.3178C21 12.6357 20.157 14.7876 18.7695 16.6116C17.2388 18.6235 15.3522 20.3765 13.2285 21.7524C12.7425 22.0704 12.3039 22.0944 11.7704 21.7524C9.63473 20.3765 7.74809 18.6235 6.2305 16.6116C4.84198 14.7876 4 12.6357 4 10.3178ZM9.69415 10.5768C9.69415 12.1177 10.9516 13.3297 12.4934 13.3297C14.0362 13.3297 15.3057 12.1177 15.3057 10.5768C15.3057 9.04782 14.0362 7.77685 12.4934 7.77685C10.9516 7.77685 9.69415 9.04782 9.69415 10.5768Z" fill="#FFAD42"/>
              </svg>
              Brisbane
            </div>

            <div
              className={clsx(
                'flex items-center justify-center gap-x-2 hover:border-b-2 hover:border-b-[#1A49BA] cursor-pointer text-[5px] lg:text-[13px] xl:text-base',
                location.address === 'Sydney' && 'border-b-2 border-b-[#1A49BA]'
              )}
              onClick={() => setLocation(
                {
                  address: 'Sydney',
                  lat: -33.87062553653437,
                  lng: 151.214314799561
                }
              )}
            >
              <svg className="w-[6px] h-[6px] lg:w-6 lg:h-6" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M4 10.3178C4 5.71789 7.84388 2 12.4934 2C17.1561 2 21 5.71789 21 10.3178C21 12.6357 20.157 14.7876 18.7695 16.6116C17.2388 18.6235 15.3522 20.3765 13.2285 21.7524C12.7425 22.0704 12.3039 22.0944 11.7704 21.7524C9.63473 20.3765 7.74809 18.6235 6.2305 16.6116C4.84198 14.7876 4 12.6357 4 10.3178ZM9.69415 10.5768C9.69415 12.1177 10.9516 13.3297 12.4934 13.3297C14.0362 13.3297 15.3057 12.1177 15.3057 10.5768C15.3057 9.04782 14.0362 7.77685 12.4934 7.77685C10.9516 7.77685 9.69415 9.04782 9.69415 10.5768Z" fill="#1A49BA"/>
              </svg>
            Sydney
            </div>

            <div
              className={clsx(
                'flex items-center justify-center gap-x-2 hover:border-b-2 hover:border-b-[#F9B8C4] cursor-pointer text-[5px] lg:text-[13px] xl:text-base',
                location.address === 'Melbourne' && 'border-b-2 border-b-[#F9B8C4]'
              )}
              onClick={() => setLocation(
                {
                  address: 'Melbourne',
                  lat: -37.82779294879664,
                  lng: 144.98463470307442
                }
              )}
            >
              <svg className="w-[6px] h-[6px] lg:w-6 lg:h-6" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.5 10.3178C3.5 5.71789 7.34388 2 11.9934 2C16.6561 2 20.5 5.71789 20.5 10.3178C20.5 12.6357 19.657 14.7876 18.2695 16.6116C16.7388 18.6235 14.8522 20.3765 12.7285 21.7524C12.2425 22.0704 11.8039 22.0944 11.2704 21.7524C9.13473 20.3765 7.24809 18.6235 5.7305 16.6116C4.34198 14.7876 3.5 12.6357 3.5 10.3178ZM9.19415 10.5768C9.19415 12.1177 10.4516 13.3297 11.9934 13.3297C13.5362 13.3297 14.8057 12.1177 14.8057 10.5768C14.8057 9.04782 13.5362 7.77685 11.9934 7.77685C10.4516 7.77685 9.19415 9.04782 9.19415 10.5768Z" fill="#F9B8C4"/>
              </svg>
            Melbourne
            </div>
          </div>

          <div>
            <div className="overflow-hidden rounded-2xl">
              <div className="h-[156px] md:h-[300px] lg:h-[435px] xl:h-[505px]">

                {/* Brisbane */}
                <div className={clsx(
                  'mapouter',
                  location.address === 'Brisbane' ? 'block' : 'hidden'
                )}>
                  <div className="gmap_canvas">
                    <iframe className={clsx(
                      'w-full',
                      'h-[156px] md:h-[300px] lg:h-[435px] xl:h-[505px]'
                    )} id="gmap_canvas" src="https://maps.google.com/maps?q=Brisbane,%203/5%20Kyabra%20Street,%20Newstead,%20QLD,%204006&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                  </div>
                </div>

                {/* Sydney */}
                <div className={clsx(
                  'mapouter',
                  location.address === 'Sydney' ? 'block' : 'hidden'
                )}>
                  <div className="gmap_canvas">
                    <iframe className={clsx(
                      'w-full',
                      'h-[156px] md:h-[300px] lg:h-[435px] xl:h-[505px]'
                    )} id="gmap_canvas" src="https://maps.google.com/maps?q=Sydney%20-%2010%20carrington%20street,%20Sydney%20NSW&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                  </div>
                </div>

                {/* Melbourne */}
                <div className={clsx(
                  'mapouter',
                  location.address === 'Melbourne' ? 'block' : 'hidden'
                )}>
                  <div className="gmap_canvas">
                    <iframe className={clsx(
                      'w-full',
                      'h-[156px] md:h-[300px] lg:h-[435px] xl:h-[505px]'
                    )} id="gmap_canvas" src="https://maps.google.com/maps?q=Melbourne%20-%20412%20St%20Kilda%20Road,%20Melbourne%20VIC&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

const LocationPin = ({ text }) => (
  <div className="pin">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.16699 24.0748C8.16699 13.3418 17.136 4.66675 27.985 4.66675C38.8646 4.66675 47.8337 13.3418 47.8337 24.0748C47.8337 29.4834 45.8667 34.5045 42.6292 38.7604C39.0575 43.455 34.6554 47.5452 29.7003 50.7557C28.5662 51.4977 27.5427 51.5537 26.298 50.7557C21.3147 47.5452 16.9125 43.455 13.3715 38.7604C10.1316 34.5045 8.16699 29.4834 8.16699 24.0748ZM21.4533 24.6792C21.4533 28.2748 24.3873 31.1027 27.9848 31.1027C31.5847 31.1027 34.5469 28.2748 34.5469 24.6792C34.5469 21.1117 31.5847 18.1461 27.9848 18.1461C24.3873 18.1461 21.4533 21.1117 21.4533 24.6792Z" fill="#FFAD42"/>
    </svg>
  </div>
)

const Background = ({ className }) => {
  return (
    <>
      <svg className={clsx(
        'hidden xl:block',
        className
      )} width="1440" height="763" viewBox="0 0 1440 763" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <mask id="mask0_359_7502" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="763">
        <rect width="1440" height="763" fill="#1A49BA"/>
      </mask> */}
        <g mask="url(#mask0_359_7502)">
          <rect width="1440" height="763" fill="#1A49BA"/>
          <path d="M-0.5 290C61.6809 290 123.253 302.235 180.701 326.005C238.148 349.776 290.347 384.616 334.315 428.539C378.284 472.461 413.161 524.604 436.957 581.991C460.753 639.378 473 700.885 473 763L-0.5 763V290Z" fill="#FFDA54"/>
          <path d="M1458 445C1387.31 445 1330 502.308 1330 573V715C1400.69 715 1458 657.692 1458 587V445Z" fill="#0099FF"/>
          <path d="M1366 445C1330.1 445 1301 474.101 1301 510V582C1336.9 582 1366 552.899 1366 517V445Z" fill="#37C99E"/>
        </g>
      </svg>
      <svg className={clsx(
        'hidden lg:block xl:hidden',
        className
      )} width="1024" height="543" viewBox="0 0 1024 543" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <mask id="mask0_396_20823" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1024" height="543">
          <rect width="1024" height="542.578" fill="#1A49BA"/>
        </mask> */}
        <g mask="url(#mask0_396_20823)">
          <rect width="1024" height="542.578" fill="#1A49BA"/>
          <path d="M-0.355286 206.223C43.8623 206.223 87.6469 214.923 128.499 231.826C169.35 248.73 206.469 273.505 237.736 304.739C269.002 335.973 293.804 373.052 310.725 413.861C327.647 454.669 336.356 498.408 336.356 542.578L-0.355286 542.578V206.223Z" fill="#FFDA54"/>
          <path d="M1036.8 316.444C986.53 316.444 945.778 357.196 945.778 407.467V508.444C996.048 508.444 1036.8 467.692 1036.8 417.422V316.444Z" fill="#0099FF"/>
          <path d="M971.378 316.444C945.85 316.444 925.156 337.139 925.156 362.667V413.867C950.684 413.867 971.378 393.172 971.378 367.644V316.444Z" fill="#37C99E"/>
        </g>
      </svg>
      <svg className={clsx(
        'block lg:hidden',
        'm-auto left-0 right-0',
        className
      )} width="375" height="230" viewBox="0 0 375 230" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_396_20679)">
          <rect width="375" height="229.98" fill="#1A49BA"/>
          <path d="M-0.183105 56.7627C22.5882 56.7627 45.1366 61.2431 66.1746 69.9481C87.2125 78.6531 106.328 91.4123 122.43 107.497C138.532 123.582 151.304 142.677 160.018 163.693C168.733 184.709 173.218 207.233 173.218 229.981L-0.183105 229.98V56.7627Z" fill="#FFDA54"/>
          <path d="M381.59 113.525C355.701 113.525 334.715 134.512 334.715 160.4V212.402C360.603 212.402 381.59 191.416 381.59 165.527V113.525Z" fill="#0099FF"/>
          <path d="M347.9 113.525C334.754 113.525 324.097 124.183 324.097 137.329V163.696C337.243 163.696 347.9 153.039 347.9 139.893V113.525Z" fill="#37C99E"/>
        </g>
        <defs>
          <clipPath id="clip0_396_20679">
            <rect width="375" height="229.98" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </>
  )
}

const PhoneIcon = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3087 15.2748C18.3087 15.5748 18.242 15.8832 18.1003 16.1832C17.9587 16.4832 17.7753 16.7665 17.5337 17.0332C17.1253 17.4832 16.6753 17.8082 16.167 18.0165C15.667 18.2248 15.1253 18.3332 14.542 18.3332C13.692 18.3332 12.7837 18.1332 11.8253 17.7248C10.867 17.3165 9.90866 16.7665 8.95866 16.0748C8.00033 15.3748 7.09199 14.5998 6.22533 13.7415C5.36699 12.8748 4.59199 11.9665 3.90033 11.0165C3.21699 10.0665 2.66699 9.1165 2.26699 8.17484C1.86699 7.22484 1.66699 6.3165 1.66699 5.44984C1.66699 4.88317 1.76699 4.3415 1.96699 3.8415C2.16699 3.33317 2.48366 2.8665 2.92533 2.44984C3.45866 1.92484 4.04199 1.6665 4.65866 1.6665C4.89199 1.6665 5.12533 1.7165 5.33366 1.8165C5.55033 1.9165 5.74199 2.0665 5.89199 2.28317L7.82533 5.00817C7.97533 5.2165 8.08366 5.40817 8.15866 5.5915C8.23366 5.7665 8.27533 5.9415 8.27533 6.09984C8.27533 6.29984 8.21699 6.49984 8.10033 6.6915C7.99199 6.88317 7.83366 7.08317 7.63366 7.28317L7.00033 7.9415C6.90866 8.03317 6.86699 8.1415 6.86699 8.27484C6.86699 8.3415 6.87533 8.39984 6.89199 8.4665C6.91699 8.53317 6.94199 8.58317 6.95866 8.63317C7.10866 8.90817 7.36699 9.2665 7.73366 9.69984C8.10866 10.1332 8.50866 10.5748 8.94199 11.0165C9.39199 11.4582 9.82533 11.8665 10.267 12.2415C10.7003 12.6082 11.0587 12.8582 11.342 13.0082C11.3837 13.0248 11.4337 13.0498 11.492 13.0748C11.5587 13.0998 11.6253 13.1082 11.7003 13.1082C11.842 13.1082 11.9503 13.0582 12.042 12.9665L12.6753 12.3415C12.8837 12.1332 13.0837 11.9748 13.2753 11.8748C13.467 11.7582 13.6587 11.6998 13.867 11.6998C14.0253 11.6998 14.192 11.7332 14.3753 11.8082C14.5587 11.8832 14.7503 11.9915 14.9587 12.1332L17.717 14.0915C17.9337 14.2415 18.0837 14.4165 18.1753 14.6248C18.2587 14.8332 18.3087 15.0415 18.3087 15.2748Z" stroke="#94A3B8" strokeWidth="1.5" strokeMiterlimit="10"/>
    </svg>
  )
}

LocationPin.propTypes = {
  text: PropTypes.string
}

Background.propTypes = {
  className: PropTypes.string
}

PhoneIcon.propTypes = {
  className: PropTypes.string
}

export default Maps
