import gsap from 'gsap'
import { useEffect } from 'react'

const BoxAnimation = (ref) => {
  useEffect(() => {
    gsap.fromTo(ref.current,
      { opacity: 0, xPercent: 100 },
      { opacity: 1, xPercent: 0, duration: 1.25, ease: 'Power3.easeOut' }
    )
  }, [])
}

export default BoxAnimation
