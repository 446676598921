import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

import SectorIcon from 'assets/job-detail/detail/sector.svg'
import ContactIcon from 'assets/job-detail/detail/contact.svg'
import LocationIcon from 'assets/job-detail/detail/location.svg'

import JobTypeIcon from 'assets/job-detail/detail/job-type.svg'
import EmailIcon from 'assets/job-detail/detail/email.svg'
import SalaryIcon from 'assets/job-detail/detail/salary.svg'
import ReferenceIcon from 'assets/job-detail/detail/reference.svg'

const Detail = ({ data }) => {
  const {
    salary,
    city,
    category,
    owners,
    job_type: jobType
  } = data

  const { phone, email } = (owners !== undefined && owners[0]) || {}

  return (
    <div className={clsx(
      'mt-8 p-6 bg-neutral-20 rounded-lg flex justify-between',
      'lg:gap-x-4 xl:gap-x-0'
    )}>
      <div className="flex flex-col gap-y-6 w-full">
        <GroupItem
          leftItem={
            <Item position="left" icon={SectorIcon} title="Sector" text={category?.name || '-'}/>
          } rightItem={
            <Item position="right" icon={JobTypeIcon} title="Job type" text={jobType?.name || '-'}/>
          }
        />

        <GroupItem
          leftItem={
            <Item position="left" icon={ContactIcon} title="Contact" text={ phone || '-'}/>
          } rightItem={
            <Item position="right" icon={EmailIcon} title="Email" text={ email || '-'}/>
          }
        />

        <GroupItem
          leftItem={
            <Item position="left" icon={LocationIcon} title="Location" text={city}/>
          } rightItem={
            <Item position="right" icon={SalaryIcon} title="Salary" text={salary || '-'}/>
          }
        />

        <GroupItem
          leftItem={
            <Item position="right" icon={ReferenceIcon} title="Reference" text="-"/>
          } rightItem={
            <></>
          }
        />

      </div>
    </div>
  )
}

const GroupItem = ({ leftItem, rightItem }) => {
  return (
    <div className={clsx(
      'grid',
      'w-full',
      'justify-between',
      `grid-cols-[107px_107px]
      sm:grid-cols-2
      lg:grid-cols-[234px_234px]
      xl:grid-cols-[250px_370px]
      `
    )}>
      {leftItem}
      {rightItem}
    </div>
  )
}

const Item = ({ icon, title, text, position }) => {
  return (
    <div className={clsx(
      'flex justify-start gap-y-2',
      'items-start xl:items-center',
      'flex-col xl:flex-row',
      {
        'gap-x-4': position === 'left',
        'gap-x-2': position === 'right'
      }
    )}>
      <div className={clsx(
        'flex items-center gap-x-2',
        {
          'w-[95px]': position === 'left',
          'w-[99px]': position === 'right'
        }
      )}>
        <img src={icon} alt=""/>
        <h1 className="text-s-medium text-neutral-100">{title}</h1>
      </div>

      <p className="text-s-medium text-neutral-60" style={{ wordBreak: 'break-word' }}>{text}</p>
    </div>
  )
}

GroupItem.propTypes = {
  leftItem: PropTypes.node,
  rightItem: PropTypes.node
}

Item.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  position: PropTypes.string
}

Detail.propTypes = {
  data: PropTypes.object
}

export default Detail
