import clsx from 'clsx'
import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { NavigationAnimation } from 'animation/header'

const Navigation = () => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  // store a reference to the box div
  const navRef = useRef()

  // wait until DOM has been rendered
  NavigationAnimation(navRef)

  return (
    <nav ref={navRef} className="relative hidden lg:flex gap-x-10">
      <Link
        to="start-hiring"
        className={clsx(
          'button-l-medium',
          pathname === 'about-us' || pathname.includes('job/') ? 'text-neutral-10' : 'text-neutral-100'
        )}
      >Start Hiring</Link>
      <Link
        to="jobs"
        className={clsx(
          'button-l-medium',
          pathname === 'about-us' || pathname.includes('job/') ? 'text-neutral-10' : 'text-neutral-100'
        )}
      >Current Jobs</Link>
      <Link
        to="about-us"
        className={clsx(
          'button-l-medium',
          pathname === 'about-us' || pathname.includes('job/') ? 'text-neutral-10' : 'text-neutral-100'
        )}
      >About Us</Link>
      <Link
        to="contact-us"
        className={clsx(
          'button-l-medium',
          pathname === 'about-us' || pathname.includes('job/') ? 'text-neutral-10' : 'text-neutral-100'
        )}
      >Contact</Link>
    </nav>
  )
}

export default Navigation
