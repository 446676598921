import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import Img1 from 'assets/about-us/hero/img-1.png'
import Img2 from 'assets/about-us/hero/img-2.png'
import Img3 from 'assets/about-us/hero/img-3.png'
import Img4 from 'assets/about-us/hero/img-4.png'

import { HeroAnimation } from 'animation/about-us/Hero/HeroAnimation'

import Illustration from 'assets/about-us/hero/Illustration'
import IllustrationLeft from 'assets/about-us/hero/IllustrationLeft'
import IllustrationRight from 'assets/about-us/hero/IllustrationRight'

import RightIllustrationAnimation from 'animation/about-us/Hero/RightIllustrationAnimation'
import LeftIllustrationAnimation from 'animation/about-us/Hero/LeftIllustrationAnimation'

const Hero = () => {
  const imageRef = useRef([])
  const titleAnimation = useRef()
  const descriptionAnimation = useRef()
  const leftIllustrationRef = useRef([])
  const rightIllustrationRef = useRef([])
  const bottomIllustrationRef = useRef()

  HeroAnimation(
    imageRef,
    bottomIllustrationRef,
    titleAnimation, descriptionAnimation
  )

  LeftIllustrationAnimation(leftIllustrationRef)
  RightIllustrationAnimation(rightIllustrationRef)

  return (
    <section id="about-us-hero" className="relative bg-primary-main pt-[120px] pb-[195px] lg:pt-[160px] lg:pb-[237px] xl:pt-[192px] xl:pb-[381px] overflow-x-hidden">
      <div className="lg:w-[606px] m-auto left-0 right-0 text-center px-6 lg:px-0">
        <h1 ref={titleAnimation} className="relative h2-medium lg:display2-medium text-neutral-10">Vendito is where Talent Hides.</h1>
        <p ref={descriptionAnimation} className="relative text-l-reguler text-neutral-10 mt-6">
          Vendito is on a mission to remove the obstacles of hiring great talent. We provide the platform that allows our clients to consistently secure the industries best candidates.
        </p>
      </div>

      <GroupImage childRef={imageRef}/>

      <IllustrationLeft childRef={leftIllustrationRef}/>
      <IllustrationRight childRef={rightIllustrationRef}/>

      <Illustration childRef={bottomIllustrationRef}/>
    </section>
  )
}

const GroupImage = ({ childRef }) => {
  return (
    <>
      <img ref={element => { childRef.current[0] = element }} className={`absolute
      bottom-[125px] left-[66px] w-[26px] h-[26px]
      lg:top-[243px] lg:left-[182px] lg:w-[71px] lg:h-[71px]
      xl:top-[252px] xl:left-[257px] xl:w-[100px] xl:h-[100px]`} src={Img1} alt=""/>

      <img ref={element => { childRef.current[1] = element }} className={`absolute
      bottom-[115px] right-[47px] w-[26px] h-[26px]
      lg:top-[268px] lg:right-[130px] lg:w-[71px] lg:h-[71px]
      xl:top-[288px] xl:right-[184px] xl:w-[100px] xl:h-[100px]`} src={Img2} alt=""/>

      <img ref={element => { childRef.current[2] = element }} className={`absolute
      bottom-[68px] right-[123px] w-[20px] h-[20px]
      lg:bottom-[185px] lg:right-[338px] lg:w-[54px] lg:h-[54px]
      xl:bottom-[260px] xl:right-[476px] xl:w-[77px] xl:h-[77px]`} src={Img3} alt=""/>

      <img ref={element => { childRef.current[3] = element }} className={`absolute
      bottom-[52px] left-[133px] w-[20px] h-[20px]
      lg:bottom-[144px] lg:left-[363px] lg:w-[54px] lg:h-[54px]
      xl:bottom-[202px] xl:left-[511px] xl:w-[77px] xl:h-[77px]`} src={Img4} alt=""/>
    </>
  )
}

GroupImage.propTypes = {
  childRef: PropTypes.node
}

export default Hero
