import React from 'react'

import Facebook from 'assets/job-detail/social-media/facebook.svg'
import Twitter from 'assets/job-detail/social-media/twitter.svg'
import Linkedin from 'assets/job-detail/social-media/linkedin.svg'
import Whatsapp from 'assets/job-detail/social-media/whatsapp.svg'
import Gmail from 'assets/job-detail/social-media/gmail.svg'
import clsx from 'clsx'

const Share = () => {
  return (
    <div className={clsx(
      'bg-neutral-10 rounded-2xl mt-8',
      'py-6 lg:py-8',
      'px-6'
    )}>
      <h1 className="text-neutral-100 text-2xl font-normal leading-[150%]">Share this job</h1>

      <div className="flex justify-between items-center pt-6 mt-6 border-t border-t-neutral-30">
        <a href="#">
          <img className={clsx(
            'w-8 lg:w-7 xl:w-10'
          )} src={Facebook} alt=""/>
        </a>
        <a href="#">
          <img className={clsx(
            'w-8 lg:w-7 xl:w-10'
          )} src={Twitter} alt=""/>
        </a>
        <a href="#">
          <img className={clsx(
            'w-8 lg:w-7 xl:w-10'
          )} src={Linkedin} alt=""/>
        </a>
        <a href="#">
          <img className={clsx(
            'w-8 lg:w-7 xl:w-10'
          )} src={Whatsapp} alt=""/>
        </a>
        <a href="#">
          <img className={clsx(
            'w-8 lg:w-7 xl:w-10'
          )} src={Gmail} alt=""/>
        </a>
      </div>
    </div>
  )
}

export default Share
