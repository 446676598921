import React from 'react'
import PropTypes from 'prop-types'

import Australia from 'assets/svg/Australia'

const InputPhone = ({ value, onChange }) => {
  return (
    <div>
      <label className="block text-xs-medium text-neutral-80">
        Phone
        <span className="text-danger-main">*</span>
      </label>
      <div className="flex w-full items-center space-x-3 px-5 bg-neutral-20 rounded-2xl mt-2">
        <SelectNation/>
        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="0.5" x2="0.499999" y2="23.5" stroke="#E2E8F0" strokeLinecap="round"/>
        </svg>
        <input type="number" name="phone" className="py-[14.5px] w-full button-m-reguler bg-neutral-20 focus:outline-none" placeholder="Phone number" value={value} onChange={onChange}/>
      </div>
    </div>
  )
}

const SelectNation = () => {
  return (
    <div className="flex items-center gap-x-2 cursor-pointer">
      <Australia/>

      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="#1E2432"/>
      </svg>
    </div>
  )
}

InputPhone.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default InputPhone
