import React from 'react'
import PropTypes from 'prop-types'

import ChecklistIcon from './ChecklistIcon'
import { ListAnimation } from 'animation/start-hiring/hero/LeftAnimation'

const ListItem = ({ text, listRef, delayAnimation }) => {
  // const listRef1 = useRef()
  ListAnimation(listRef, delayAnimation)

  return (
    <li ref={listRef} className="relative flex items-center sm:items-start gap-x-3">
      <div className="w-5 h-5">
        <ChecklistIcon/>
      </div>
      {text}
    </li>
  )
}

ListItem.propTypes = {
  text: PropTypes.string,
  listRef: PropTypes.node,
  delayAnimation: PropTypes.number
}

export default ListItem
