import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const ContainerAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    gsap.fromTo(ref.current,
      {
        left: '-100%',
        opacity: 1
      },
      {
        left: '0%',
        opacity: 1,
        delay: 0,
        duration: 2,
        ease: 'Power3.easeInOut',
        scrollTrigger: {
          trigger: '#container-form-image',
          start: '-=650'
        }
      }
    )
  }, [])
}

export { ContainerAnimation }
