import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const RefineSearch = () => {
  const [isVisible, setIsVisible] = useState(false)

  const listenToScroll = () => {
    const heightToHideFrom = 750
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop

    if (winScroll < heightToHideFrom) {
      // isVisible && // to limit setting state only the first time
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  const scrolToTop = () => {
    // return window.scrollTo(0, 0)
    return window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () =>
      window.removeEventListener('scroll', listenToScroll)
  }, [])

  return (
    <button
      className={clsx(
        isVisible ? 'flex sm:hidden' : 'hidden',
        'fixed gap-x-2 button-l-medium rounded-full',
        'bg-primary-main text-neutral-10',
        'justify-center items-center',
        'w-auto h-14',
        'mx-6 mb-8',
        'bottom-0 left-0 right-0'
      )}
      onClick={() => scrolToTop()}
    >
      <Icon/>
      Refine Search
    </button>
  )
}

const Icon = ({ className }) => {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2326 2.73336V4.20002C14.2326 4.73336 13.8993 5.40002 13.5659 5.73336L10.6993 8.26669C10.2993 8.60002 10.0326 9.26669 10.0326 9.80002V12.6667C10.0326 13.0667 9.76594 13.6 9.43261 13.8L8.49927 14.4C7.63261 14.9334 6.43261 14.3334 6.43261 13.2667V9.73336C6.43261 9.26669 6.16594 8.66669 5.89927 8.33336L5.58594 8.00669C5.37927 7.78669 5.33927 7.45336 5.50594 7.19336L8.91927 1.71336C9.03927 1.52002 9.25261 1.40002 9.48594 1.40002H12.8993C13.6326 1.40002 14.2326 2.00002 14.2326 2.73336Z" fill="white"/>
      <path d="M7.39896 2.42002L5.03229 6.21336C4.80563 6.58002 4.28562 6.63336 3.98563 6.32002L3.36563 5.66669C3.03229 5.33336 2.76562 4.73336 2.76562 4.33336V2.80002C2.76562 2.00002 3.36563 1.40002 4.09896 1.40002H6.83229C7.35229 1.40002 7.67229 1.97336 7.39896 2.42002Z" fill="white"/>
    </svg>
  )
}

Icon.propTypes = {
  className: PropTypes.string
}

export default RefineSearch
