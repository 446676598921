import React, { Suspense, lazy } from "react";
import Helmet from "react-helmet";
import Hero from "parts/Home/Hero";
import StartHiring from "parts/Home/StartHiring";

const Advantage = lazy(() => import("parts/Home/Advantage"));
const PerfectMatch = lazy(() => import("parts/Home/PerfectMatch"));
const WhyVendito = lazy(() => import("parts/Home/WhyVendito"));
const CurrentJob = lazy(() => import("parts/Home/CurrentJob"));
const Testimoni = lazy(() => import("parts/Home/Testimoni"));
const Trusted = lazy(() => import("parts/Home/TrustedBy"));

const Home = () => (
  <>
    <Helmet>
      <title>Vendito Consulting</title>
      <meta
        name="description"
        content="A highly renowned recruitment agency known for identifying, attracting, and securing the best talent for Australian Business. Better quality staff equals higher retention, increased performance, more market share, and a healthier bottom line."
      />
      <meta
        name="keywords"
        content="talent agency, top talent agencies australia, biggest talent agencies in australia"
      />
      <link rel="canonical" href="https://venditoconsulting.com.au" />
      <meta property="og:url" content="http://venditoconsulting.com.au" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Vendito Consulting" />
      <meta
        property="og:description"
        content="A highly renowned recruitment agency known for identifying, attracting, and securing the best talent for Australian Business. Better quality staff equals higher retention, increased performance, more market share, and a healthier bottom line."
      />
      <meta
        property="og:image"
        content="https://venditoconsulting.com.au/mstile-310x310.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Vendito Consulting" />
      <meta
        name="twitter:description"
        content="A highly renowned recruitment agency known for identifying, attracting, and securing the best talent for Australian Business. Better quality staff equals higher retention, increased performance, more market share, and a healthier bottom line."
      />
      <meta
        name="twitter:image"
        content="https://venditoconsulting.com.au/mstile-310x310.png"
      />
    </Helmet>
    <Hero />
    <Suspense fallback={<div>Loading...</div>}>
      <Advantage />
      <PerfectMatch />
      <WhyVendito />
      <CurrentJob />
      <Testimoni />
      <Trusted />
    </Suspense>
    <StartHiring />
  </>
);

export default Home;
