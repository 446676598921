import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import toast, { Toaster } from 'react-hot-toast'

import Australia from 'assets/svg/Australia'

// import FormCard from 'assets/start-hiring/form/form-card.png'
import { ContainerAnimation } from 'animation/start-hiring/form/FormImageAnimation'

const FormImage = () => {
  const containerRef = useRef()
  ContainerAnimation(containerRef)

  const formRef = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm('service_7pm0m5g', 'template_12so4ei', formRef.current, 'PBNnz4Wy3i1rCqub9')
      .then((result) => {
        toast.success('Form sent successfully')
      }, () => {
        toast.error('Form failed to send.')
      })
  }

  return (
    <div
      ref={containerRef}
      id="container-form-image"
      className={clsx(
        'relative',
        'bg-primary-main',
        'py-12 lg:py-[72px] xl:py-[80px]',
        'w-full sm:w-[417px] lg:w-[575px] xl:w-[755px]',
        'px-6 sm:px-0'
      )}

    >
      <form ref={formRef} onSubmit={sendEmail} className={clsx(
        'bg-neutral-10 rounded-[20px]',
        'p-6 lg:p-8',
        'm-auto left-0 right-0',
        'sm:w-[398px] lg:w-[472px] xl:w-[584px]'
      )}>
        <div>
          <h5 className="h5-bold text-neutral-100">Contact Details</h5>
          <div className="flex flex-col gap-y-3 mt-4">
            <Input name="full_name" placeholder="Full name"/>
            <Input name="email" type="email" placeholder="Work email"/>
            <InputPhone name="phone" className="flex items-center gap-x-3" type="number" placeholder="Phone number"/>
            <div className="flex flex-col lg:flex-row gap-y-3 gap-x-3 justify-between">
              <Input name="company_name" placeholder="Company name"/>
              <Input name="position_title" placeholder="Position title"/>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <h5 className="h5-bold text-neutral-100">Job Vacancy</h5>
          <div className="flex flex-col gap-y-3 mt-4">
            <Input name="position_title" placeholder="Position title"/>
            <Input name="location" placeholder="Location"/>
            <Input name="salary" placeholder="Salary"/>
            <textarea
              name="comment"
              className={clsx(
                'bg-neutral-20',
                'rounded-xl',
                'p-4',
                'focus:border focus:border-primary-main focus:outline-none',
                'text-s-reguler'
              )}
              rows={4}
              placeholder="Comment"
              required
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          className={
            clsx(
              'w-full',
              'mt-6',
              'button-l-medium',
              'bg-primary-main',
              'text-neutral-10',
              'h-12',
              'rounded-full'
            )
          }
        >Submit</button>
      </form>
      <Toaster />
      {/* <img className="w-[263px] lg:w-[265px] xl:w-[480px] m-auto left-0 right-0" src={FormCard} alt=""/> */}
    </div>
  )
}

const SelectNation = () => {
  return (
    <div className="flex items-center gap-x-2 cursor-pointer">
      <Australia/>

      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="#1E2432"/>
      </svg>
    </div>
  )
}

const Input = ({ className, name, type = 'text', placeholder }) => {
  return (
    <input
      name={name}
      className={
        clsx(
          'w-full',
          'h-12',
          'focus:border focus:border-primary-main focus:outline-none',
          'focus:outline-none',
          'rounded-xl',
          'px-4',
          'bg-neutral-20',
          'text-s-reguler',
          className
        )
      }
      type={type} placeholder={placeholder}
      required
    />
  )
}

const InputPhone = ({ className, name, type = 'text', placeholder }) => {
  return (
    <div className={clsx(
      'w-full',
      'px-4',
      'rounded-xl',
      'bg-neutral-20',
      className
    )}>
      <SelectNation/>
      <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="0.5" x2="0.499999" y2="23.5" stroke="#E2E8F0" strokeLinecap="round"/>
      </svg>

      <input
        name={name}
        className={
          clsx(
            'w-full',
            'h-12',
            // 'focus:border focus:border-primary-main focus:outline-none',
            'focus:outline-none',
            'rounded-xl',
            // 'px-4',
            'bg-neutral-20',
            'text-s-reguler'
          )
        }
        type={type} placeholder={placeholder}
        required
      />
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string
}

InputPhone.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string
}

export default FormImage
