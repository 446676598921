import clsx from 'clsx'
import React, { useRef } from 'react'

import SerchForm from './SearchForm'

import Illustration from 'assets/jobs/hero/Illustration'

import BoxAnimation from 'animation/job/BoxAnimation'
import TextAnimation from 'animation/job/TextAnimation'
import SearchFormAnimation from 'animation/job/SearchFormAnimation'

const Hero = () => {
  const boxRef = useRef()
  const textRef = useRef([])
  const formRef = useRef([])
  const illustrationRef = useRef()

  BoxAnimation(boxRef)
  TextAnimation(textRef)
  SearchFormAnimation(formRef)

  return (
    <section className={clsx(
      'relative flex justify-between',
      'flex-col lg:flex-row',
      'lg:pl-14 xl:pl-[120px]',
      'm-auto left-0 right-0',
      'lg:max-w-[1024px] xl:max-w-[1920px]',
      'overflow-x-hidden'
    )}>
      <div className="px-6 lg:px-0 pt-[120px] lg:py-[160px] xl:pt-[200px] xl:pb-[158px] lg:w-[440px] xl:w-[634px]">

        <div className="lg:w-[422px]">
          <h1 ref={element => { textRef.current.title = element }} className="h2-medium lg:display1-medium text-neutral-100">
            Job Search
          </h1>
          <p ref={element => { textRef.current.description = element }} className="text-m-reguler lg:text-l-reguler text-neutral-60 mt-4">
            Most of our jobs are not advertised. Register your interest to access unseen opportunities.
          </p>
        </div>

        <SerchForm childRef={formRef}/>
      </div>

      <div ref={boxRef} className="mt-12 lg:mt-0 lg:w-[411px] xl:w-[518px] bg-primary-main"></div>
      <Illustration refChild={illustrationRef} className={clsx(
        'self-end',
        'lg:absolute bottom-0 right-0',
        'w-full lg:w-[496px] xl:w-[601px]',
        'max-w-[601px]',
        'h-auto max-h-[457px]'
      )}/>
    </section>
  )
}

export default Hero
