import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'

import Role from './Role'
import Location from './Location'
import Description from './Description'
import JobCardSkeleton from '../Skeleton/JobCardSkeleton'

import ApplyJobModal from '../ModalApplyJob'

import { clientRoot } from 'api'

const JobCard = ({ index, data }) => {
  const { id, title, city, category, published_at: datePublished } = data

  const [isLoading, setIsLoading] = useState(true)
  const [description, setDescription] = useState()

  const childRef = useRef(null)

  useEffect(() => {
    clientRoot.get(`jobs/${data.id}`).then((response) => {
      setDescription(response.data.description)
      setIsLoading(false)
    })
  }, [data])

  return isLoading
    ? <JobCardSkeleton/>
    : (
      <div className="p-6 bg-neutral-10 rounded-2xl">
        <div className="flex gap-x-4 justify-between items-start">
          <div>
            <h1 className="text-neutral-100 text-[24px] leading-[150%] font-normal">{title}</h1>
            <div className="flex flex-wrap flex-row gap-x-4 items-start lg:items-center mt-3 gap-y-4">
              <Role text={category === null ? '-' : category.name}/>
              <Location text={city}/>
            </div>
          </div>
        </div>

        <Description text={description}/>

        <ApplyJobModal jobId={id} childRef={childRef}/>

        <div className="flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center mt-6 gap-y-6">
          <div className="flex gap-x-6 items-center">
            <button
              className={clsx(
                'button-l-medium text-neutral-10  rounded-full w-[127px] h-12',
                'bg-primary-main hover:bg-primary-hover',
                'transition-all duration-300'
              )}
              onClick={() => childRef.current.openModal()}
            >Apply Now</button>
            <Link to={`/job/${id}`} className={clsx(
              'button-l-medium w-[127px] text-center lg:text-left',
              'text-primary-main hover:text-primary-hover',
              'transition-all duration-300'
            )}>Read More</Link>
          </div>
          <p className="button-l-medium text-neutral-60">{moment(datePublished).format('MMM DD, YYYY')}</p>
        </div>
      </div>
    )
}

JobCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object
}

export default JobCard
