import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const TextAnimation = (titleRef, descriptionRef) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const tl1 = gsap.timeline({
      delay: 1,
      scrollTrigger: {
        trigger: '#container-text-experience',
        start: 'top center'
      }
    })

    tl1.fromTo(titleRef.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 0.9,
        ease: 'Power3.easeOut'
      }
    )
    tl1.fromTo(descriptionRef.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.25,
        ease: 'Power3.easeOut'
      }
    )
  }, [])
}

export default TextAnimation
