import clsx from 'clsx'
import React from 'react'

const StartHiring = () => {
  return (
    <section id="start-hiring" className="hidden xl:block bg-neutral-20 py-[120px] px-[120px]">
      <div className={clsx(
        'flex items-center justify-between max-w-[1440px]',
        'm-auto left-0 right-0'
      )}>
        <h2 className="h2-medium text-neutral-100">Start Hiring Now!</h2>
        <form className="flex gap-x-4 h-14">
          <input className="rounded-full text-neutral-50 button-l-reguler w-[420px] px-5 py-4" type="email" placeholder="Enter your email..." />
          <button className="button-l-medium text-white bg-primary-main w-[148px] rounded-full">Start Hiring</button>
        </form>
      </div>
    </section>
  )
}

export default StartHiring
