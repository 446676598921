import React from 'react'
import PropTypes from 'prop-types'

const ProfessionalDevelopment = ({ childRef, className }) => {
  return (
    <svg className={className} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <mask id="mask0_420_5020" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
        <rect width="64" height="64" fill="white"/>
      </mask> */}
      <g mask="url(#mask0_420_5020)">
        <rect ref={element => { childRef.current.profesional1 = element }} x="3" y="3" width="36.25" height="36.25" fill="#0099FF"/>
        <rect ref={element => { childRef.current.profesional2 = element }} x="39.25" y="9.04199" width="21.75" height="21.75" fill="#FFAD42"/>
        <path ref={element => { childRef.current.profesional3 = element }} d="M3 39H25V43C25 48.5228 20.5228 53 15 53H3V39Z" fill="#FFAD42"/>
        <rect ref={element => { childRef.current.profesional4 = element }} x="39.25" y="39.25" width="21.75" height="21.75" fill="#0099FF"/>
      </g>
    </svg>
  )
}

ProfessionalDevelopment.propTypes = {
  className: PropTypes.string,
  childRef: PropTypes.node
}

export default ProfessionalDevelopment
