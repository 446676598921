import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import ScaleIconAnimation from 'animation/start-hiring/advantage/ScaleIconAnimation'

const ScaleTeam = ({ linkIsHover, setLinkIsHover }) => {
  const isHover = linkIsHover === 'scale'

  return (
    <Link
      to="#"
      onMouseOver={() => setLinkIsHover('scale')}
      onMouseLeave={() => setLinkIsHover('')}
    >
      <div className="relative">
        {/* Box Shadow */}
        <div className={clsx(
          'absolute bg-neutral-100 bg-opacity-[0.26] blur-[80px] top-6 left-4',
          'transition-all duration-700',
          isHover ? 'w-[236px] h-[272px]' : 'w-[0px] h-[0px]'
        )}></div>

        {/* Background */}
        <div
          className={clsx(
            'absolute top-0 left-0 w-full h-full bg-neutral-10 overflow-hidden'
          )}
        >
          <div className={clsx(
            'relative bg-primary-main w-[200%] h-[200%]',
            'transform origin-center duration-[350ms] rounded-[50%] left-[-50%]',
            'transition-all ease-out',
            isHover ? 'scale-100 translate-y-[-7.5%]' : 'scale-0 translate-y-0'
          )}>
          </div>
        </div>

        {/* Content */}
        <div className={clsx(
          'relative flex flex-col justify-between py-6 px-6 min-h-[280px]',
          'transform duration-500',
          isHover ? 'translate-y-[-2.5%]' : 'translate-y-0'
        )}>
          <div>
            <ScaleIcon/>
            <h1 className={clsx(
              'w-[136px] lg:w-full mt-6 h5-medium',
              isHover ? 'text-neutral-10' : 'text-neutral-100'
            )}>
              Scale your team quickly
            </h1>
            <p className={clsx(
              'mt-1 text-s-reguler text-neutral-10',
              'transform duration-500',
              isHover ? 'opacity-[0.7]' : 'opacity-0'
            )}>
              With a team of recruiters working with your business it’s easy to rapidly scale your team
            </p>
          </div>

          <button
            className={clsx(
              'flex items-center link-medium self-end',
              isHover ? 'text-neutral-10' : 'text-neutral-90'
            )}
          >
            View More
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.59766 7.30688L15.3205 7.30688L15.3205 13.0297" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.30664 15.3206L15.2404 7.38682" stroke={isHover ? 'white' : '#1E293B'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </Link>
  )
}

const ScaleIcon = ({ className }) => {
  const pathRef = useRef()
  const rect1Ref = useRef()
  const rect2Ref = useRef()

  ScaleIconAnimation(pathRef, rect1Ref, rect2Ref)

  return (
    <div
      className={clsx(
        className,
        'relative h-10 w-10 overflow-hidden'
      )}
    >
      <svg ref={pathRef} className="absolute" width="100%" height="100%">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8335 1.6665H1.66699V38.3332H38.3337V19.1666H20.8335V1.6665Z" fill="#9F78F8"/>
      </svg>
      <svg ref={rect1Ref} className="absolute" width="100%" height="100%">
        <rect x="2.5" y="21.6667" width="15.8333" height="15.8333" fill="#1A49BA"/>
      </svg>

      <svg ref={rect2Ref} className="absolute" width="100%" height="100%">
        <rect x="22.5" y="1.66675" width="15.8333" height="15.8333" fill="#1A49BA"/>
      </svg>
    </div>
  )
}

ScaleTeam.propTypes = {
  linkIsHover: PropTypes.bool,
  setLinkIsHover: PropTypes.func
}

ScaleIcon.propTypes = {
  className: PropTypes.string
}

export default ScaleTeam
