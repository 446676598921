import gsap from 'gsap'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const VacancyAnimation = (imgRef, activeIndex) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(imgRef.current,
      { left: '-161px', opacity: 0 },
      { left: '-57px', opacity: 1, delay: 0.35, duration: 1.75, ease: 'Power3.easeInOut' }
    )
  }, [pathname, activeIndex])
}

const NetworkAnimation = (imgRef, activeIndex) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(imgRef.current,
      { left: '-161px', opacity: 0 },
      { left: '-83px', opacity: 1, delay: 0.6, duration: 1.75, ease: 'Power3.easeInOut' }
    )
  }, [pathname, activeIndex])
}

const ReliabilityAnimation = (imgRef, activeIndex) => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  useEffect(() => {
    gsap.fromTo(imgRef.current,
      { left: '-161px', opacity: 0 },
      { left: '-57px', opacity: 1, delay: 0.85, duration: 1.75, ease: 'Power3.easeInOut' }
    )
  }, [pathname, activeIndex])
}

export { NetworkAnimation, ReliabilityAnimation, VacancyAnimation }
