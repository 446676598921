import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import React, { useState, useRef } from 'react'

import { ButtonAnimation } from 'animation/header'

import NavMobile from '../NavMobile'
import Logo from './Logo'
import Navigation from './Navigation'
import clsx from 'clsx'

const Header = () => {
  const location = useLocation()
  const pathname = location.pathname.slice(1)

  const [isOpenNavMobile, setIsOpenNavMobile] = useState(false)

  // store a reference to the box div
  const buttonRef = useRef()

  // wait until DOM has been rendered
  ButtonAnimation(buttonRef)

  return (
    <header className={clsx(
      'flex justify-between items-center px-6 lg:px-14 xl:px-[120px] py-6 absolute z-[20] m-auto left-0 right-0',
      'lg:max-w-[1024px] xl:max-w-[1920px]'
    )}>
      <NavMobile isOpenNavMobile={isOpenNavMobile} setIsOpenNavMobile={setIsOpenNavMobile}/>

      <div className="flex items-center gap-x-[113px] xl:gap-x-14">
        <Logo/>

        <Navigation/>
      </div>

      <a href="https://calendly.com/venditoconsulting" target="_blank" rel="noreferrer">
        <div
          ref={buttonRef}
          className="relative hidden lg:flex items-center justify-center w-[124px] h-12 bg-neutral-100 text-white button-l-medium rounded-[100px]"
        >book a call</div>
      </a>

      <button className="block lg:hidden" onClick={() => setIsOpenNavMobile(true)}>
        <HumbergerIcon color={pathname === '' || pathname === 'about-us' || pathname.includes('job/') ? 'white' : 'black'}/>
      </button>
    </header>
  )
}

const HumbergerIcon = ({ className, color }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.59961 5.9998C3.59961 5.33706 4.13687 4.7998 4.79961 4.7998H19.1996C19.8624 4.7998 20.3996 5.33706 20.3996 5.9998C20.3996 6.66255 19.8624 7.1998 19.1996 7.1998H4.79961C4.13687 7.1998 3.59961 6.66255 3.59961 5.9998Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.59961 11.9998C3.59961 11.3371 4.13687 10.7998 4.79961 10.7998H11.9996C12.6624 10.7998 13.1996 11.3371 13.1996 11.9998C13.1996 12.6625 12.6624 13.1998 11.9996 13.1998H4.79961C4.13687 13.1998 3.59961 12.6625 3.59961 11.9998Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.59961 17.9998C3.59961 17.3371 4.13687 16.7998 4.79961 16.7998H19.1996C19.8624 16.7998 20.3996 17.3371 20.3996 17.9998C20.3996 18.6625 19.8624 19.1998 19.1996 19.1998H4.79961C4.13687 19.1998 3.59961 18.6625 3.59961 17.9998Z" fill={color}/>
    </svg>
  )
}

HumbergerIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
}

export default Header
