import React from 'react'
import PropTypes from 'prop-types'

const Interview = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#7B61FF"/>
      <path d="M19.1271 10.373V9.33301C19.1271 9.05967 18.9004 8.83301 18.6271 8.83301C18.3538 8.83301 18.1271 9.05967 18.1271 9.33301V10.333H13.7938V9.33301C13.7938 9.05967 13.5671 8.83301 13.2938 8.83301C13.0204 8.83301 12.7938 9.05967 12.7938 9.33301V10.373C10.9938 10.5397 10.1204 11.613 9.98711 13.2063C9.97377 13.3997 10.1338 13.5597 10.3204 13.5597H21.6004C21.7938 13.5597 21.9538 13.393 21.9338 13.2063C21.8004 11.613 20.9271 10.5397 19.1271 10.373Z" fill="white"/>
      <path opacity="0.4" d="M21.2933 14.5601C21.66 14.5601 21.96 14.8601 21.96 15.2267V19.3334C21.96 21.3334 20.96 22.6667 18.6266 22.6667H13.2933C10.96 22.6667 9.95996 21.3334 9.95996 19.3334V15.2267C9.95996 14.8601 10.26 14.5601 10.6266 14.5601H21.2933Z" fill="white"/>
      <path d="M13.6257 18.0001C13.4523 18.0001 13.279 17.9267 13.1523 17.8067C13.0323 17.6801 12.959 17.5067 12.959 17.3334C12.959 17.1601 13.0323 16.9868 13.1523 16.8601C13.339 16.6734 13.6323 16.6134 13.879 16.7201C13.9657 16.7534 14.039 16.8001 14.099 16.8601C14.219 16.9868 14.2923 17.1601 14.2923 17.3334C14.2923 17.5067 14.219 17.6801 14.099 17.8067C13.9723 17.9267 13.799 18.0001 13.6257 18.0001Z" fill="white"/>
      <path d="M15.9596 18.0001C15.7863 18.0001 15.613 17.9267 15.4863 17.8067C15.3663 17.6801 15.293 17.5067 15.293 17.3334C15.293 17.1601 15.3663 16.9868 15.4863 16.8601C15.5463 16.8001 15.6196 16.7534 15.7063 16.7201C15.953 16.6134 16.2463 16.6734 16.433 16.8601C16.553 16.9868 16.6263 17.1601 16.6263 17.3334C16.6263 17.5067 16.553 17.6801 16.433 17.8067C16.3996 17.8334 16.3663 17.8601 16.333 17.8867C16.293 17.9134 16.253 17.9334 16.213 17.9468C16.173 17.9668 16.133 17.9801 16.093 17.9868C16.0463 17.9934 16.0063 18.0001 15.9596 18.0001Z" fill="white"/>
      <path d="M18.2926 17.9998C18.1193 17.9998 17.946 17.9265 17.8193 17.8065C17.6993 17.6798 17.626 17.5065 17.626 17.3332C17.626 17.1598 17.6993 16.9865 17.8193 16.8599C17.886 16.7999 17.9526 16.7532 18.0393 16.7198C18.1593 16.6665 18.2926 16.6532 18.426 16.6798C18.466 16.6865 18.506 16.6998 18.546 16.7198C18.586 16.7332 18.626 16.7532 18.666 16.7799C18.6993 16.8065 18.7326 16.8332 18.766 16.8599C18.886 16.9865 18.9593 17.1598 18.9593 17.3332C18.9593 17.5065 18.886 17.6798 18.766 17.8065C18.7326 17.8332 18.6993 17.8598 18.666 17.8865C18.626 17.9132 18.586 17.9332 18.546 17.9465C18.506 17.9665 18.466 17.9799 18.426 17.9865C18.3793 17.9932 18.3326 17.9998 18.2926 17.9998Z" fill="white"/>
      <path d="M13.6257 20.3331C13.539 20.3331 13.4523 20.3131 13.3723 20.2798C13.2857 20.2464 13.219 20.1998 13.1523 20.1398C13.0323 20.0131 12.959 19.8398 12.959 19.6664C12.959 19.4931 13.0323 19.3198 13.1523 19.1931C13.219 19.1331 13.2857 19.0864 13.3723 19.0531C13.4923 18.9998 13.6257 18.9864 13.759 19.0131C13.799 19.0198 13.839 19.0331 13.879 19.0531C13.919 19.0664 13.959 19.0864 13.999 19.1131C14.0323 19.1398 14.0657 19.1664 14.099 19.1931C14.219 19.3198 14.2923 19.4931 14.2923 19.6664C14.2923 19.8398 14.219 20.0131 14.099 20.1398C14.0657 20.1664 14.0323 20.1998 13.999 20.2198C13.959 20.2464 13.919 20.2664 13.879 20.2798C13.839 20.2998 13.799 20.3131 13.759 20.3198C13.7123 20.3264 13.6723 20.3331 13.6257 20.3331Z" fill="white"/>
      <path d="M15.9596 20.3335C15.7863 20.3335 15.613 20.2602 15.4863 20.1402C15.3663 20.0135 15.293 19.8402 15.293 19.6669C15.293 19.4935 15.3663 19.3202 15.4863 19.1935C15.733 18.9469 16.1863 18.9469 16.433 19.1935C16.553 19.3202 16.6263 19.4935 16.6263 19.6669C16.6263 19.8402 16.553 20.0135 16.433 20.1402C16.3063 20.2602 16.133 20.3335 15.9596 20.3335Z" fill="white"/>
      <path d="M18.2926 20.3335C18.1193 20.3335 17.946 20.2602 17.8193 20.1402C17.6993 20.0135 17.626 19.8402 17.626 19.6669C17.626 19.4935 17.6993 19.3202 17.8193 19.1935C18.066 18.9469 18.5193 18.9469 18.766 19.1935C18.886 19.3202 18.9593 19.4935 18.9593 19.6669C18.9593 19.8402 18.886 20.0135 18.766 20.1402C18.6393 20.2602 18.466 20.3335 18.2926 20.3335Z" fill="white"/>
    </svg>

  )
}

Interview.propTypes = {
  className: PropTypes.string
}

export default Interview
