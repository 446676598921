import AmyThomas from "assets/hero/compressed/amy-thomas-new.png";
import LexieAhern from "assets/hero/lexie-ahern.png";
import BlakeThompson from "assets/hero/compressed/blake-thompson.png";
import BryanChung from "assets/hero/bryan-chung.webp";
import MeganWarry from "assets/hero/megan-warry.png";

import AmyThomesMedium from "assets/hero/amy-thomas-medium.webp";
import LexieAhernMedium from "assets/hero/lexie-ahern-medium.png";
import BlakeThompsonMedium from "assets/hero/blake-thompson-medium.webp";
import BryanChungMedium from "assets/hero/bryan-chung-medium.webp";
import MeganWarryMedium from "assets/hero/megan-warry-medium.png";

import AmyThomesSmall from "assets/hero/amy-thomas-new-small.png";
import LexieAhernSmall from "assets/hero/lexie-ahern-small.png";
import BlakeThompsonSmall from "assets/hero/blake-thompson-small.webp";
import BryanChungSmall from "assets/hero/bryan-chung-small.webp";
import MeganWarrySmall from "assets/hero/megan-warry-small.png";

export const arrTeam = [
  {
    rating: {
      vacancy: 96,
      network: 10,
    },
    photo: {
      large: AmyThomas,
      medium: AmyThomesMedium,
      small: AmyThomesSmall,
    },
    name: "Amy Thomas",
    specialist:
      "Specializing in: Constructions, Retail, Building Products, Sales",
    width: "w-[204px]",
  },
  {
    rating: {
      vacancy: 95,
      network: 9.5,
    },
    photo: {
      large: LexieAhern,
      medium: LexieAhernMedium,
      small: LexieAhernSmall,
    },
    name: "Lexie Ahern",
    specialist:
      "Specializing in: Healthcare, Logistics & Transports, Manufacturing",
    width: "w-[230px]",
  },
  {
    rating: {
      vacancy: 98,
      network: 10,
    },
    photo: {
      large: BlakeThompson,
      medium: BlakeThompsonMedium,
      small: BlakeThompsonSmall,
    },
    name: "Blake Thompson",
    specialist:
      "Specializing in: Healthcare, Construction, Manufacturing, Information Technology",
    width: "w-[270px]",
  },
  {
    rating: {
      vacancy: 94,
      network: 9.2,
    },
    photo: {
      large: BryanChung,
      medium: BryanChungMedium,
      small: BryanChungSmall,
    },
    name: "Bryan Chung",
    specialist:
      "Specializing in: Healthcare, Construction, Manufacturing, Information Technology",
    width: "w-[254px]",
  },
  {
    rating: {
      vacancy: 96,
      network: 9,
    },
    photo: {
      large: MeganWarry,
      medium: MeganWarryMedium,
      small: MeganWarrySmall,
    },
    name: "Megan Warry",
    specialist:
      "Specializing in: Construction, Retail, Building Products, Sales",
    width: "w-[254px]",
  },
];
