import React from 'react'
import PropTypes from 'prop-types'

const JobTitle = ({ text }) => {
  return (
    <div className="w-[275px] sm:w-full border-b border-b-neutral-30 pb-8">
      <h1 className="h4-bold text-neutral-100">{text}</h1>
    </div>
  )
}

JobTitle.propTypes = {
  text: PropTypes.string
}

export default JobTitle
