import React from 'react'
import PropTypes from 'prop-types'

const Role = ({ text }) => {
  return (
    <div className="flex gap-x-2 items-center text-m-medium text-neutral-60">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.91964 2.93256C8.36103 2.93256 7.88579 3.32075 7.75239 3.84395H12.238C12.1046 3.32075 11.6293 2.93256 11.0707 2.93256H8.91964ZM13.5053 3.84395H15.1561C16.907 3.84395 18.3327 5.28698 18.3327 7.05913C18.3327 7.05913 18.2827 7.80933 18.266 8.85405C18.2643 8.93675 18.2243 9.01777 18.1584 9.06671C17.7574 9.36291 17.3905 9.60764 17.3572 9.62451C15.9732 10.5528 14.3649 11.2059 12.6515 11.5308C12.5398 11.5528 12.4297 11.4945 12.373 11.395C11.8928 10.5629 10.9957 10.0211 9.99518 10.0211C9.00135 10.0211 8.0959 10.557 7.60148 11.3899C7.54395 11.4878 7.43557 11.5443 7.32468 11.5232C5.6255 11.1975 4.01719 10.5452 2.6415 9.63295L1.8411 9.07599C1.7744 9.0338 1.73272 8.95785 1.73272 8.87346C1.7077 8.44308 1.66602 7.05913 1.66602 7.05913C1.66602 5.28698 3.09173 3.84395 4.8426 3.84395H6.48509C6.6435 2.62033 7.66902 1.66675 8.91964 1.66675H11.0707C12.3213 1.66675 13.3469 2.62033 13.5053 3.84395ZM18.0492 10.6794L18.0159 10.6963C16.3317 11.8271 14.3057 12.5782 12.1796 12.8904C11.8795 12.9326 11.5793 12.7385 11.4959 12.4347C11.3125 11.7427 10.7205 11.287 10.0119 11.287H10.0035H9.98684C9.27815 11.287 8.68619 11.7427 8.50277 12.4347C8.41939 12.7385 8.11924 12.9326 7.81909 12.8904C5.69303 12.5782 3.66702 11.8271 1.98284 10.6963C1.9745 10.6879 1.89113 10.6372 1.82443 10.6794C1.74939 10.7216 1.74939 10.8229 1.74939 10.8229L1.80775 15.1267C1.80775 16.8988 3.22513 18.3334 4.976 18.3334H15.0144C16.7652 18.3334 18.1826 16.8988 18.1826 15.1267L18.2493 10.8229C18.2493 10.8229 18.2493 10.7216 18.1743 10.6794C18.1326 10.6541 18.0826 10.6626 18.0492 10.6794ZM10.6205 14.2153C10.6205 14.5697 10.3454 14.8482 9.99518 14.8482C9.65334 14.8482 9.36987 14.5697 9.36987 14.2153V13.1267C9.36987 12.7807 9.65334 12.4938 9.99518 12.4938C10.3454 12.4938 10.6205 12.7807 10.6205 13.1267V14.2153Z" fill="#6B7C94"/>
      </svg>

      {text}
    </div>
  )
}

Role.propTypes = {
  text: PropTypes.string
}

export default Role
