import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const TitleAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    let currentScroll = 0
    let isScrollingDown = true

    const tween = gsap.to(
      '.title-join-team',
      {
        xPercent: -125,
        repeat: -1,
        duration: 30,
        ease: 'linear',
        scrollTrigger: {
          trigger: '#container-title-join-team'
        }
      }
    ).totalProgress(0.5)

    gsap.to(ref.current, {
      xPercent: -60,
      ease: 'linear',
      scrollTrigger: {
        trigger: '#container-title-join-team',
        scrub: 1,
        end: () => '+=' + ref.current.offsetWidth
      }
    })

    window.addEventListener('scroll', function () {
      if (window.pageYOffset > currentScroll) {
        isScrollingDown = true
      } else {
        isScrollingDown = false
      }

      gsap.to(tween, {
        timeScale: isScrollingDown ? 1 : -1
      })

      currentScroll = window.pageYOffset
    })
  })
}

export default TitleAnimation
