import React from "react";
import PropTypes from "prop-types";

import ArrowLeft from "assets/home/hero/arrow-left.svg";
import ArrowRight from "assets/home/hero/arrow-right.svg";
import clsx from "clsx";

const SwiperNavigation = ({ prevSwiper, nextSwiper }) => {
  return (
    <div className="bg-[#7B61FF] max-w-[208px] xl:max-w-[223px] w-full flex items-end justify-end absolute right-0 top-0 h-full">
      <div className="flex">
        <Button
          direction="left"
          icon={ArrowLeft}
          onClick={prevSwiper}
          ariaLabel="Previous slide"
        />
        <Button
          direction="right"
          icon={ArrowRight}
          onClick={nextSwiper}
          ariaLabel="Next slide"
        />
      </div>
    </div>
  );
};

const Button = ({ icon, direction, onClick, ariaLabel }) => {
  return (
    <button
      className={clsx(
        "hover:bg-[#6E52FF] flex items-center justify-center",
        "w-[72px] lg:w-[88px] xl:w-[100px]",
        "h-[72px] lg:h-[88px]",
        {
          "prev-people": direction === "left",
          "next-people": direction === "right",
        }
      )}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <img src={icon} width={24} height={24} alt="icon" />{" "}
    </button>
  );
};

SwiperNavigation.propTypes = {
  prevSwiper: PropTypes.func.isRequired,
  nextSwiper: PropTypes.func.isRequired,
};

Button.propTypes = {
  icon: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["left", "right"]).isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default SwiperNavigation;
