import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { VacancyAnimation } from 'animation/home/StickerAnimation'
import clsx from 'clsx'

const Vacancy = ({ value, activeIndex }) => {
  const imgRef = useRef()

  VacancyAnimation(imgRef, activeIndex)

  return (
    <div
      ref={imgRef}
      className={clsx(
        'flex justify-between items-center',
        'bg-neutral-10 p-2 rounded-full',
        'relative h-12 w-[226px] left-[-126px] xl:left-[-68px]'
      )}
      style={{
        boxShadow: '0px 16px 50px rgba(30, 36, 50, 0.1)'
      }}
    >
      <div className="flex gap-x-3 items-center">
        <Icon/>
        <p className="text-neutral-50 button-s-medium">Vacancy Fill Rate</p>
      </div>
      <p className="text-neutral-100 button-s-bold">{value}%</p>
    </div>
  )
}

const Icon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#EBFAF5"/>
      <path d="M22.0602 12.6533C21.4935 12.0266 20.5469 11.7133 19.1735 11.7133H19.0135V11.6866C19.0135 10.5666 19.0135 9.17993 16.5069 9.17993H15.4935C12.9869 9.17993 12.9869 10.5733 12.9869 11.6866V11.7199H12.8269C11.4469 11.7199 10.5069 12.0333 9.94019 12.6599C9.28019 13.3933 9.30019 14.3799 9.36685 15.0533L9.37352 15.0999L9.41764 15.5632C9.43191 15.713 9.51266 15.8484 9.63881 15.9304C9.79917 16.0346 10.0204 16.1756 10.1602 16.2533C10.2535 16.3133 10.3535 16.3666 10.4535 16.4199C11.5935 17.0466 12.8469 17.4666 14.1202 17.6733C14.1802 18.2999 14.4535 19.0333 15.9135 19.0333C17.3735 19.0333 17.6602 18.3066 17.7069 17.6599C19.0669 17.4399 20.3802 16.9666 21.5669 16.2733C21.6069 16.2533 21.6335 16.2333 21.6669 16.2133C21.9106 16.0755 22.1628 15.9082 22.3966 15.7416C22.5101 15.6608 22.5823 15.5345 22.5977 15.396L22.6002 15.3733L22.6335 15.0599C22.6402 15.0199 22.6402 14.9866 22.6469 14.9399C22.7002 14.2666 22.6869 13.3466 22.0602 12.6533ZM16.7269 17.2199C16.7269 17.9266 16.7269 18.0333 15.9069 18.0333C15.0869 18.0333 15.0869 17.9066 15.0869 17.2266V16.3866H16.7269V17.2199ZM13.9402 11.7133V11.6866C13.9402 10.5533 13.9402 10.1333 15.4935 10.1333H16.5069C18.0602 10.1333 18.0602 10.5599 18.0602 11.6866V11.7199H13.9402V11.7133Z" fill="#37C99E"/>
      <path d="M21.6456 17.2834C22.0003 17.1175 22.4077 17.3984 22.3723 17.7884L22.1601 20.1266C22.0201 21.4599 21.4734 22.8199 18.5401 22.8199H13.4601C10.5267 22.8199 9.98006 21.4599 9.84006 20.1332L9.63935 17.9254C9.6043 17.5399 10.0022 17.2594 10.3559 17.4167C11.1156 17.7548 12.2561 18.2377 13.0298 18.4538C13.1933 18.4995 13.3259 18.618 13.404 18.7688C13.8248 19.5816 14.6865 20.0132 15.9134 20.0132C17.1282 20.0132 18 19.5649 18.4226 18.749C18.5008 18.5981 18.6338 18.4796 18.7975 18.4335C19.6223 18.2012 20.8451 17.6579 21.6456 17.2834Z" fill="#37C99E"/>
    </svg>
  )
}

Vacancy.propTypes = {
  value: PropTypes.number,
  activeIndex: PropTypes.number
}

export default Vacancy
