import axios from 'axios'

const clientRoot = axios.create({
  baseURL: 'https://app.loxo.co/api/vendito-consulting-group/',
  auth: {
    username: 'vendito-consulting-group_api',
    password: 'udx*knk_xhd4QNV6pkv'
  }
})

export { clientRoot }
