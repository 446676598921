import gsap from 'gsap'
import { useEffect } from 'react'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const IconAnimation = (ref) => {
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    // Icon Rest
    gsap.fromTo(ref.current.rest1,
      { scale: 0, transformOrigin: 'bottom center' },
      {
        scale: 1,
        transformOrigin: 'bottom center',
        duration: 0.75,
        scrollTrigger: {
          trigger: ref.current.rest1
        }
      }
    )
    gsap.fromTo(ref.current.rest2,
      { scale: 0, transformOrigin: 'center' },
      {
        scale: 1,
        transformOrigin: 'center',
        duration: 0.75,
        delay: 0.75,
        scrollTrigger: {
          trigger: ref.current.rest2
        }
      }
    )

    // Icon Health
    gsap.fromTo(ref.current.health,
      { scale: 0, transformOrigin: 'bottom center' },
      {
        scale: 1,
        transformOrigin: 'bottom center',
        duration: 0.75,
        scrollTrigger: {
          trigger: ref.current.health
        }
      }
    )

    // Icon Professional
    gsap.fromTo(ref.current.profesional1,
      { scale: 0, transformOrigin: 'bottom right' },
      {
        scale: 1,
        transformOrigin: 'bottom right',
        duration: 0.75,
        scrollTrigger: {
          trigger: ref.current.profesional1
        }
      }
    )
    gsap.fromTo(ref.current.profesional2,
      { scale: 0, transformOrigin: 'left' },
      {
        scale: 1,
        transformOrigin: 'left',
        duration: 0.5,
        delay: 1,
        scrollTrigger: {
          trigger: ref.current.profesional2
        }
      }
    )
    gsap.fromTo(ref.current.profesional3,
      { scale: 0, transformOrigin: 'top left' },
      {
        scale: 1,
        transformOrigin: 'top left',
        duration: 0.5,
        delay: 1,
        scrollTrigger: {
          trigger: ref.current.profesional3
        }
      }
    )
    gsap.fromTo(ref.current.profesional4,
      { scale: 0, transformOrigin: 'top left' },
      {
        scale: 1,
        transformOrigin: 'top left',
        duration: 0.75,
        scrollTrigger: {
          trigger: ref.current.profesional4
        }
      }
    )

    // Icon Planning
    gsap.fromTo(ref.current.planning1,
      { scale: 0, transformOrigin: 'bottom right' },
      {
        scale: 1,
        transformOrigin: 'bottom right',
        duration: 0.75,
        delay: 0.75,
        scrollTrigger: {
          trigger: ref.current.planning1
        }
      }
    )
    gsap.fromTo(ref.current.planning2,
      { scale: 0, transformOrigin: 'bottom left' },
      {
        scale: 1,
        transformOrigin: 'bottom left',
        duration: 0.75,
        delay: 0.75,
        scrollTrigger: {
          trigger: ref.current.planning2
        }
      }
    )
    gsap.fromTo(ref.current.planning3,
      { scale: 0, transformOrigin: 'center' },
      {
        scale: 1,
        transformOrigin: 'center',
        duration: 0.75,
        scrollTrigger: {
          trigger: ref.current.planning3
        }
      }
    )
  }, [])
}

export default IconAnimation
