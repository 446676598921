import gsap from 'gsap'
import { useEffect } from 'react'

const ImageAnimation = (imageRef) => {
  useEffect(() => {
    gsap.fromTo(imageRef.current,
      { opacity: 0, yPercent: 100 },
      { opacity: 1, yPercent: 0, duration: 1, delay: 1 }
    )
  })
}

export default ImageAnimation
