import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const ShowResult = ({ showResult, setShowResult }) => {
  const [dropdowOpen, setDropdownOpen] = useState(false)

  const toogleDropdown = () => {
    return dropdowOpen ? setDropdownOpen(false) : setDropdownOpen(true)
  }

  return (
    <div className="hidden lg:flex items-center gap-x-4">
      <p className="text-m-medium text-neutral-60">Show result</p>
      <div className="relative">
        <button
          className="flex items-center gap-x-6 bg-neutral-10 px-4 py-3 rounded-full text-m-bold text-neutral-100"
          onClick={() => toogleDropdown()}
        >
          {showResult} <ArrowIcon/>
        </button>
        <div
          className={clsx(
            'flex-col absolute bg-neutral-10 rounded-2xl w-full mt-2 py-1',
            dropdowOpen ? 'flex' : 'hidden'
          )}
        >
          <button
            className="px-4 py-1 text-m-bold text-neutral-100 hover:bg-neutral-20"
            onClick={() => {
              setShowResult(5)
              setDropdownOpen(false)
            }}
          >5</button>
          <button
            className="px-4 py-1 text-m-bold text-neutral-100 hover:bg-neutral-20"
            onClick={() => {
              setShowResult(10)
              setDropdownOpen(false)
            }}
          >10</button>
          <button
            className="px-4 py-1 text-m-bold text-neutral-100 hover:bg-neutral-20"
            onClick={() => {
              setShowResult(15)
              setDropdownOpen(false)
            }}
          >15</button>
        </div>
      </div>
    </div>
  )
}

const ArrowIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 6.125L7.5 9.875L11.25 6.125" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

ShowResult.propTypes = {
  showResult: PropTypes.number,
  setShowResult: PropTypes.func
}

export default ShowResult
