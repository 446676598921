import clsx from 'clsx'
import React from 'react'

const LinkedinIcon = () => {
  return (
    <svg
      className={clsx(
        'w-4 lg:w-6',
        'w-4 lg:h-6',
        'fill-[#94A3B8]',
        'hover:fill-primary-main',
        'transition-all duration-500'
      )}
      viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.5 2.838C1.5 2.35053 1.69365 1.88303 2.03834 1.53834C2.38303 1.19365 2.85053 1 3.338 1H21.66C21.9016 0.999608 22.1409 1.04687 22.3641 1.13907C22.5874 1.23127 22.7903 1.36661 22.9612 1.53734C23.1322 1.70807 23.2677 1.91083 23.3602 2.13401C23.4526 2.3572 23.5001 2.59643 23.5 2.838V21.16C23.5003 21.4016 23.4529 21.6409 23.3606 21.8642C23.2683 22.0875 23.1328 22.2904 22.962 22.4613C22.7912 22.6322 22.5884 22.7678 22.3651 22.8602C22.1419 22.9526 21.9026 23.0001 21.661 23H3.338C3.09655 23 2.85746 22.9524 2.6344 22.86C2.41134 22.7676 2.20867 22.6321 2.03798 22.4613C1.8673 22.2905 1.73193 22.0878 1.63962 21.8647C1.54731 21.6416 1.49987 21.4025 1.5 21.161V2.838ZM10.208 9.388H13.187V10.884C13.617 10.024 14.717 9.25 16.37 9.25C19.539 9.25 20.29 10.963 20.29 14.106V19.928H17.083V14.822C17.083 13.032 16.653 12.022 15.561 12.022C14.046 12.022 13.416 13.111 13.416 14.822V19.928H10.208V9.388ZM4.708 19.791H7.916V9.25H4.708V19.79V19.791ZM8.375 5.812C8.38105 6.08668 8.33217 6.35979 8.23124 6.61532C8.13031 6.87084 7.97935 7.10364 7.78723 7.30003C7.59511 7.49643 7.3657 7.65248 7.11246 7.75901C6.85921 7.86554 6.58724 7.92042 6.3125 7.92042C6.03776 7.92042 5.76579 7.86554 5.51255 7.75901C5.2593 7.65248 5.02989 7.49643 4.83777 7.30003C4.64565 7.10364 4.49469 6.87084 4.39376 6.61532C4.29283 6.35979 4.24395 6.08668 4.25 5.812C4.26187 5.27286 4.48439 4.75979 4.86989 4.38269C5.25539 4.00558 5.77322 3.79442 6.3125 3.79442C6.85178 3.79442 7.36961 4.00558 7.75512 4.38269C8.14062 4.75979 8.36313 5.27286 8.375 5.812Z" />
    </svg>
  )
}

export default LinkedinIcon
