import gsap from 'gsap'
import { useEffect } from 'react'

const TextAnimation = (ref) => {
  useEffect(() => {
    gsap.fromTo(ref.current.title,
      { yPercent: 50, opacity: 0 },
      { yPercent: 0, opacity: 1, duration: 1, delay: 0.5 }
    )
    gsap.fromTo(ref.current.description,
      { yPercent: 50, opacity: 0 },
      { yPercent: 0, opacity: 1, duration: 1, delay: 0.6 }
    )
  }, [])
}

export default TextAnimation
