import React from 'react'
import PropTypes from 'prop-types'

const Illustration = ({ childRef }) => {
  return (
    <svg ref={childRef} className="absolute z-[1] bottom-0 left-0 w-full h-[161px] lg:h-[438px] xl:h-[615px]" width="1440" height="615" viewBox="0 0 1440 615" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <path opacity="0.18" d="M1391.53 169.799C1430.52 161.984 1477.39 99.439 1503 62L1519 662H-33.679C-37.6064 522.821 -52.5299 194.161 -33.6785 138.045C-16.2615 178.128 51.3221 175.649 91.3133 195.705C135.304 204.897 166.617 236.531 182.497 282.878C198.378 329.224 260.874 342.168 339.251 340.497C417.627 338.827 396.112 394.777 411.992 449.474C427.873 504.171 481.056 501.49 520.5 506.5C559.944 511.51 612.068 598.117 651 570.977C689.932 543.837 716 582.5 763.407 570.977C796.704 595.194 800.803 567.637 877.13 546.76C953.458 525.883 959.605 507.094 1012.88 459.078C1066.16 411.061 1057.96 442.794 1114.82 449.474C1171.68 456.155 1198.22 413.984 1192.07 340.497C1185.92 267.011 1308.05 283.448 1349.04 230.384C1367.66 206.278 1360.28 176.062 1391.53 169.799Z" fill="#486DC8"/>
      <circle opacity="0.3" cx="595" cy="464" r="6" fill="#153A95"/>
      <circle opacity="0.3" cx="806.5" cy="364.5" r="2.5" fill="#153A95"/>
      <circle opacity="0.3" cx="781.5" cy="563.5" r="2.5" fill="#153A95"/>
      <path opacity="0.3" d="M227.16 217.722C230.65 219.737 234.798 220.283 238.691 219.24C242.584 218.197 245.903 215.65 247.918 212.16C249.933 208.67 250.48 204.522 249.436 200.629C248.393 196.736 245.846 193.417 242.356 191.402L234.758 204.562L227.16 217.722Z" fill="#153A95"/>
      <path opacity="0.3" d="M1083.16 13.9183C1084.89 14.9161 1086.8 15.5637 1088.77 15.8242C1090.75 16.0847 1092.76 15.9529 1094.69 15.4364C1096.62 14.9199 1098.43 14.0288 1100.01 12.814C1101.59 11.5992 1102.92 10.0844 1103.92 8.3562L1090.76 0.758178L1083.16 13.9183Z" fill="#153A95"/>
      <path opacity="0.3" d="M1238.36 241.918C1234.87 243.933 1230.72 244.48 1226.82 243.436C1222.93 242.393 1219.61 239.846 1217.6 236.356C1215.58 232.866 1215.04 228.718 1216.08 224.825C1217.12 220.932 1219.67 217.613 1223.16 215.598L1230.76 228.758L1238.36 241.918Z" fill="#153A95"/>
      <path opacity="0.3" d="M580.727 283.616C578.915 284.662 576.76 284.946 574.739 284.404C572.717 283.862 570.993 282.54 569.947 280.727C568.9 278.914 568.616 276.76 569.158 274.738C569.7 272.716 571.023 270.993 572.835 269.946L576.781 276.781L580.727 283.616Z" fill="#153A95"/>
      <path opacity="0.3" d="M869.835 495.616C871.648 496.662 873.802 496.946 875.824 496.404C877.846 495.862 879.569 494.54 880.616 492.727C881.663 490.914 881.946 488.76 881.404 486.738C880.863 484.716 879.54 482.993 877.727 481.946L873.781 488.781L869.835 495.616Z" fill="#153A95"/>
    </svg>
  )
}

Illustration.propTypes = {
  childRef: PropTypes.node
}

export default Illustration
