import Experience from 'parts/AboutUs/Experience'
import Hero from 'parts/AboutUs/Hero'
import Join from 'parts/AboutUs/JoinTeam'
import Team from 'parts/AboutUs/Team'
import TestimonialMember from 'parts/AboutUs/TestimonialMember'
import Trusted from 'parts/AboutUs/Trusted'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

const AboutUs = () => {
  useEffect(() => {
    const metaDescription = document.querySelectorAll('[name="description"]')[0]
    metaDescription.content = 'Meet our team and discover why Vendito out performs in the world of work.'

    const linkCanonical = document.querySelectorAll('[rel="canonical"]')[0]
    linkCanonical.href = 'https://venditoconsulting.com.au/about-us'
  }, [])

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name='description' content='Meet our team and discover why Vendito out performs in the world of work.'/>
        <meta name='keywords' content='top talent agencies australia, talent agency melbourne, talent agents jobs,talent agency near me, jobs in melbourne, talent agency, freelance australia, acting agency, how to become a talent agent australia, jobs in brisbane, talent agency australia, freelance in australia, industrial designers melbourne, industrial designers australia, talent agency sydney, talent agent, modeling agency near me, jobs online,model agency, biggest talent agencies in australia'/>
        <link rel='canonical' href='https://venditoconsulting.com.au/about-us'/>
      </Helmet>
      <Hero/>
      <Team/>
      <Experience/>
      <Join/>
      <TestimonialMember/>
      <Trusted/>
    </>
  )
}

export default AboutUs
