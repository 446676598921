import React from 'react'
import PropTypes from 'prop-types'

const Next = ({ onClick }) => {
  return (
    <button onClick={onClick} className="flex items-center gap-x-1 button-l-medium text-neutral-60">
      <p className="hidden lg:block">Next</p>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6L15 12L9 18" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </button>
  )
}

Next.propTypes = {
  onClick: PropTypes.func
}

export default Next
