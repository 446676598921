import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import ApplyJobModal from 'parts/Jobs/Job/ModalApplyJob'

const Description = ({ jobId, data }) => {
  const childRef = useRef(null)

  return (
    <div className="mt-8">
      <h1 className="text-neutral-100 text-2xl font-medium leading-[150%]">Job Description</h1>

      <div className="job-description" dangerouslySetInnerHTML={{ __html: data.description }} />

      <button
        className="w-full h-14 bg-primary-main mt-8 button-l-medium text-neutral-10 rounded-full"
        onClick={() => childRef.current.openModal()}
      >
        Apply Now
      </button>

      <ApplyJobModal jobId={jobId} childRef={childRef}/>
    </div>
  )
}

Description.propTypes = {
  jobId: PropTypes.number,
  data: PropTypes.object
}

export default Description
