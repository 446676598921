import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper";

import User1 from "assets/about-us/testimonial/user-1.png";
import User1Tablet from "assets/about-us/testimonial/user-1-tablet.png";

import User2 from "assets/about-us/testimonial/amy-new.png";
import User2Tablet from "assets/about-us/testimonial/amy-tablet.png";

import clsx from "clsx";
import useResponsive from "utils/useResponsive";

const TestimonialMember = () => {
  const { xl } = useResponsive();
  const [navigationHover, setNavigationHover] = useState("");

  const arrTestimonial = [
    {
      img: {
        large: User1,
        medium: User1Tablet,
      },
      text: "“Vendito is a place where you are constantly surrounded by driven, positive, uplifting, likeminded individuals that provides a sense of belonging and instills a growth mindset that really allows you to be your greater self.”",
      profile: {
        name: "Bryan Chung",
        role: "Candidate Manager",
      },
    },
    {
      img: {
        large: User2,
        medium: User2Tablet,
      },
      text: "“Working for Vendito has the enjoyment and fulfilment of working with a leading business where everyone genuinely cares for each other and wants to create a collaborative culture of success.”",
      profile: {
        name: "Amy Thomas",
        role: "Principal Consultant",
      },
    },
  ];

  return (
    <section
      className={clsx(
        "py-12",
        "lg:pt-0 xl:pt-[65px]",
        "lg:pb-20 xl:pb-24",
        "px-6 lg:px-14 xl:px-[120px]",
        "m-auto left-0 right-0",
        "max-w-[375px] lg:max-w-[1024px] xl:max-w-[1440px]",
        "overflow-x-hidden"
      )}
    >
      <div
        className={clsx(
          "flex items-end",
          "w-full",
          "h-[780px] lg:h-[436px] xl:h-[581px]"
        )}
      >
        <div
          className={clsx(
            "relative w-full bg-primary-main rounded-2xl",
            "h-[732px] lg:h-[356px] xl:h-[491px]"
          )}
        >
          <SwiperNavigation
            navigationHover={navigationHover}
            setNavigationHover={setNavigationHover}
          />

          <Swiper
            id="testimonials-swiper"
            className="h-[780px] top-[-48px] lg:h-[436px] lg:top-[-80px] xl:h-[581px] xl:top-[-90px]"
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              prevEl: ".prev-testimoni",
              nextEl: ".next-testimoni",
              clickable: true,
            }}
            keyboard={true}
            loop={true}
            modules={[Navigation, Keyboard]}
            speed={1000}
          >
            {arrTestimonial.map((item, index) => {
              const { img, text, profile } = item;
              return (
                <SwiperSlide key={index} className="">
                  <div
                    className={clsx(
                      "relative w-full h-full flex gap-y-12",
                      "gap-x-8 xl:gap-x-[27px]",
                      "flex-col lg:flex-row"
                    )}
                  >
                    <div
                      className={clsx(
                        "relative",
                        "w-full lg:w-[361px] xl:w-[506px]",
                        "max-h-[378px] lg:max-h-full"
                      )}
                    >
                      <img
                        className="lg:absolute bottom-0"
                        src={xl ? img.large : img.medium}
                        alt=""
                      />
                    </div>

                    <div
                      className={clsx(
                        "lg:w-[471px] xl:w-[560px]",
                        "px-6 lg:px-0",
                        "lg:pt-[128px] xl:pt-[154px]"
                      )}
                    >
                      <h1 className="h5-reguler xl:h4-reguler text-neutral-10">
                        {text}
                      </h1>
                      <div className="mt-6 xl:mt-7">
                        <h1 className="h5-medium text-neutral-10">
                          {profile.name}
                        </h1>
                        <p className="text-m-reguler text-neutral-10 text-opacity-[0.6] mt-2">
                          {profile.role}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

const SwiperNavigation = ({ navigationHover, setNavigationHover }) => {
  return (
    <div
      className={clsx(
        "absolute w-[96px] z-[100]",
        "bottom-6 lg:bottom-11 xl:bottom-[52px]",
        "right-[205px] lg:right-[423px] xl:right-[569px]"
      )}
    >
      <button
        className="prev-testimoni w-[40px] h-[40px] rounded-full mr-4"
        onMouseEnter={() => setNavigationHover("left")}
        onMouseLeave={() => setNavigationHover("")}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity={navigationHover === "left" ? "1" : "0.6"}>
            <path
              d="M5 20.0002H35M11.6667 26.6668L5 20.0002L11.6667 26.6668ZM5 20.0002L11.6667 13.3335L5 20.0002Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </button>
      <button
        className="next-testimoni w-[40px] h-[40px] rounded-full"
        onMouseEnter={() => setNavigationHover("right")}
        onMouseLeave={() => setNavigationHover("")}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity={navigationHover === "right" ? "1" : "0.6"}>
            <path
              d="M35 20.0002H5M28.3333 26.6668L35 20.0002L28.3333 26.6668ZM35 20.0002L28.3333 13.3335L35 20.0002Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

SwiperNavigation.propTypes = {
  navigationHover: PropTypes.string,
  setNavigationHover: PropTypes.func,
};

export default TestimonialMember;
